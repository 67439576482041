
import React from 'react'
import { Chart } from '@antv/g2';

// import * as G2 from '@antv/g2';
import './index.less'
import { connect } from "react-redux"
import * as CONFIG from '../../config/config';
import * as FN  from '../../utils/index'
import {
    Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
  } from 'antd';

import * as LANGUAGE from "../../utils/language"
import {set_language_status} from '../../redux/action'
class Setting extends React.Component {
   
    state = {  
        language_status:"zh_cn",
        start_mode_status:1,
        close_mode_status:1,
        pxn_pc_version:"1.0.1",
        pxn_pc_update_status:0
    };
    
 
    componentDidMount() {
        let that=this;
        // 版本检测
        that.setState({
          language_status:that.props.language_status
        })

    }

    componentDidUpdate() {

    }

    //监听语言切换
    change_language_status=(cur)=>{
      let that=this

      that.setState({
          language_status:cur
      })

      const { dispatch } = this.props;
        dispatch(set_language_status(cur))
    }
    //监听启动模式
    change_start_mode_status=(cur)=>{
      let that=this
      that.setState({
          start_mode_status:cur
      })
    }
    //监听关闭模式
    change_close_mode_status=(cur)=>{
      let  that=this
      that.setState({
        close_mode_status:cur
      })
    }


    render() {
        let that=this;
        const lgg_data=LANGUAGE.LGG_DATA[that.props.language_status];
        return (
            <div  className="jkw-jizuo-view">
                <div className='jkw-setting-main-view'>

                    {/* 语言设置 */}
                    <div className='jkw-setting-form-row-view margin-bottom-35'>
                      <div className='jkw-form-label-view'><text className='jkw-setting-font-1'  >{lgg_data.setting_label_1}</text></div>
              
                        <div className='jkw-setting-control-view'>
                            <div className={that.state.language_status=="zh_cn"?"jkw-setting-bt-active-view":"jkw-setting-bt-view"} onClick={that.change_language_status.bind(that,"zh_cn")}>{lgg_data.setting_label_8}</div>
                            <div className={that.state.language_status=="en_us"?"jkw-setting-bt-active-view":"jkw-setting-bt-view"}   onClick={that.change_language_status.bind(that,"en_us")}>{lgg_data.setting_label_9}</div>
                            <div className={that.state.language_status=="jp"?"jkw-setting-bt-active-view":"jkw-setting-bt-view"}   onClick={that.change_language_status.bind(that,"jp")}>{lgg_data.setting_label_10}</div>
                        </div> 
       
                    </div>
                    {/* 开机自动启动 */}
                    <div className='jkw-setting-form-row-view margin-bottom-35'>
                      <div className='jkw-form-label-view'><text className='jkw-setting-font-1' >{lgg_data.setting_label_2}</text></div>
                
                        <div className='jkw-setting-control-view'>
                            <div className={that.state.start_mode_status==1?"jkw-setting-bt-active-view":"jkw-setting-bt-view"} onClick={that.change_start_mode_status.bind(that,1)}>{lgg_data.setting_label_11}</div>
                            <div className={that.state.start_mode_status==2?"jkw-setting-bt-active-view":"jkw-setting-bt-view"}   onClick={that.change_start_mode_status.bind(that,2)}>{lgg_data.setting_label_12}</div>
                        </div> 
                
                    </div>

                    {/* 关闭主面板 */}
                    <div className='jkw-setting-form-row-view margin-bottom-35'>
                      <div className='jkw-form-label-view'><text className='jkw-setting-font-1' >{lgg_data.setting_label_3}</text></div>
                
                        <div className='jkw-setting-control-view'>
                            <div className={that.state.close_mode_status==1?"jkw-setting-bt-active-view2":"jkw-setting-bt-view2"} onClick={that.change_close_mode_status.bind(that,1)}>{lgg_data.setting_label_13}</div>
                            <div className={that.state.close_mode_status==2?"jkw-setting-bt-active-view":"jkw-setting-bt-view"}   onClick={that.change_close_mode_status.bind(that,2)}>{lgg_data.setting_label_14}</div>
                        </div> 
                      
                    </div>

                    {/* 当前版本 */}
                    <div className='jkw-setting-form-row-view margin-bottom-35'>
                      <div className='jkw-form-label-view'><text className='jkw-setting-font-1' >{lgg_data.setting_label_4}</text></div>
                      
                      <div className='jkw-form-content-view'>
                        <text className='jkw-setting-font-2'>{that.state.pxn_pc_version}</text>
                      </div>
                    </div>

                    {/* 版本检测 */}
                    <div className='jkw-setting-form-row-view margin-bottom-35'>
                      <div className='jkw-form-label-view'><text className='jkw-setting-font-1' >{lgg_data.setting_label_5}</text></div>
                      {that.state.pxn_pc_update_status==0?<div className='jkw-setting-btn-view-2'>{lgg_data.setting_label_15}</div>:<div className='jkw-setting-btn-view'>{lgg_data.setting_label_16}</div>}
                    </div>
                    {/* 问题反馈 */}
                    <div className='jkw-setting-form-row-view margin-bottom-35'>
                      <div className='jkw-form-label-view'><text className='jkw-setting-font-1' >{lgg_data.setting_label_6}</text></div>
                      <div className='jkw-setting-btn-view'>{lgg_data.setting_label_17}</div>
                    </div>


                    {/* 关于我们 */}
                    <div className='jkw-setting-form-row-view '>
                      <div className='jkw-form-label-view'><text className='jkw-setting-font-1' >{lgg_data.setting_label_7}</text></div>
                      <div className='jkw-form-content-view'>
                        <text className='jkw-setting-font-1'>{lgg_data.setting_label_18}</text>
                      </div>
                    </div>
                    
                </div>

            </div>
        );
  
    }
}


const mapStateToProps = state => {

    return {
      language_status:state.language_status
    }
};
export default connect(mapStateToProps)(Setting)



