
import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as CONFIG from '../../config/config';

import {
    Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
  } from 'antd';
class Loading extends React.Component {
   
    state = {  
        text:'',
        address:''
    };


    render() {
        let that=this;

        return (
            <div  className="jkw-jizuo-view">
           
                <text>拼命加载中...</text>
            </div>
        );
          
return (

    <div  className="jkw-home-view">
        <div className='lv1-left-view'>

            <div className='lv1-left-top-view'>
                <text className='jkw-font-2'>banner</text>
            </div>
            
            <div className='lv2-main-view'>
                <div className='lv2-left-view'>
                    <div className='lv2-left-top-view'>
                        <text className='jkw-font-1'>方向盘</text>
                        <div className='jkw-btn-1'>更多设置</div>
                    </div>
                    <img src="/assets/device_pic_1.png" className="jkw-device-pic-1 "/>
                    <div className='lv2-left-bottom-view'></div>
                </div>
                <div className='lv2-right-view'>
                    <div className='lv2-right-top-view'>
                        <div className='lv2-right-top-top-view'>
                            <text className='jkw-font-1'>基座</text>
                            <div className='jkw-btn-1'>更多设置</div>
                        </div>
                        <img src="/assets/device_pic_2.png" className="jkw-device-pic-2 "/>
                    </div>
                    <div className='lv2-right-dowm-view'>
                        <div className='lv2-right-dowm-top-view'>
                            <text className='jkw-font-1'>踏板</text>
                            <div className='jkw-btn-1'>更多设置</div>
                        </div>
                        <img src="/assets/device_pic_3.png" className="jkw-device-pic-3 "/>
                    </div>
                </div>
            </div>

        </div>


{/* 可选游戏 */}
        <div className='lv1-right-view'>
            <div className='lv1-right-top-view'>
                <text className='jkw-font-1'>可选游戏</text>
                <div className='jkw-btn-1'>更新</div>
            </div>      
            <div className='jkw-game-pic-list-view'>
                <img src="/assets/game_pic_1.png" className="jkw-game-pic-1 "/>
                <img src="/assets/game_pic_2.png" className="jkw-game-pic-1 "/>
                <img src="/assets/game_pic_2.png" className="jkw-game-pic-1 "/>
                <img src="/assets/game_pic_2.png" className="jkw-game-pic-1 "/>
              

            </div>
            
        
        </div>
        
    </div>
);
  
    }
}


const mapStateToProps = state => {

    return {
  
    }
};
export default connect(mapStateToProps)(Loading)



