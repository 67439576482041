
import React from 'react'
import './index.less'
import { connect } from "react-redux"
import { Chart } from '@antv/g2';
import * as CONFIG from '../../config/config';
import * as FN  from '../../utils/index'
import * as PY from '../../utils/pywebview'
import * as FX from '../../redux/action'
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
    Form, Input, Icon, Radio, Select, Slider,InputNumber,Modal, message, Popover,Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
  } from 'antd';
  import * as LANGUAGE from "../../utils/language"
  const { confirm } = Modal;
class Jizuo extends React.Component {
   
    state = {  
        jizuo_connect_status:false,
        tool_icon_tool_status:[false,false,false,false,false],
        wheel_two_pick_status:0, //方向盘双离合开关 0-关闭 1-开启
        wheel_jiaozhun_status:0,//当前校准状态
        jizuo_heat_status:20, //当前基座温度
        jizuo_chart:undefined, //基座图表对象
        wind_speed_switch_status:1, //风速状态调节  0-2
        wind_speed_switch_status_old:2, //风速状态调节 老的 0-2


        /********************一键设置相关  begin********************** */
        map_setting_gov_data:[],
        map_setting_custom_data:[],
        map_setting_custom_index:-1,
        map_setting_gov_index:-1,
        map_setting_gov_select_index:-1,
        map_setting_custom_select_index:-1,
        setting_mode:1,  //1-官方推荐 ,2-自定义
        custom_rename_modal_visible:false,
        custom_rename_key:undefined, //自定义名称输入值
        custom_rename_modal_flag:1, //1-新增,2-修改
        /********************一键设置相关  end********************** */
        jizuo_param_tag_arr:["方向盘角度","力回馈","弹力性","阻尼力","正弦力","惯性力","最大扭矩","最大转速","机械归中力","机械阻尼力","路感感知",],
        jizuo_param_data:[0,150,50,50,50,50,50,50,50,50,50,50], //从1开始计数
        preview_jizuo_param_data:[0,150,50,50,50,50,50,50,50,50,50,50],

        

    };
    // 组件卸载
    componentWillUnmount(){
        let that=this;
        if(Window.KEYBOARD_TIMER){
            clearInterval(Window.KEYBOARD_TIMER)
        }


    }
      //设备数据同步上位机
      pc_data_sync_from_device(p12_lite_data){
        let that=this;
        
        // console.log(rst)
        that.setState({
            jizuo_param_data:p12_lite_data.jizuo_param_data,
            jizuo_connect_status:p12_lite_data.jizuo_connect_status,
            jizuo_heat_status:p12_lite_data.pxn_jizuo_heat_status,  //基座温度
            wheel_two_pick_status:p12_lite_data.wheel_two_pick_status,  //基座温度
            wind_speed_switch_status:p12_lite_data.wind_speed_switch_status,  //基座温度
        })
    }

 
    // 接受path变化
    componentWillReceiveProps(nextprops,nextcontent){
        let that=this;
        let p12_lite_data=nextprops.p12_lite_data
        // 防止数据刷新过快
        if(window.JK_ANTI_SHAKE_TIMER_3000){
            return;
        }
        that.pc_data_sync_from_device(p12_lite_data)
 
        

      }
    componentDidMount() {
        let that=this
        that.pc_data_sync_from_device(that.props.p12_lite_data)
        that.init_data();

        // 获取缓存数据  断开后首次连接成功
        let handle=PY.pxn_user_config_get("jizuo")
        if(handle){
            handle.then((rst_data)=>{
                that.setState({
                    map_setting_custom_data:rst_data.data
                })
    
            })
        }
        
    }

    // 初始化数据
    init_data(){
        let that=this;
        FN.ajax({
            url: 'https://api.e-pxn.com.cn/mobile/v1/keyboard/getKeyboard?pageNum=1&pageSize=200&status=1&type=1&driveType=19',
            data: {}
        }).
            then((res) => {
                let rst_data=res.content.list
                let goal_data=[]
                for(let i=0;i<rst_data.length;i++){
                    if(rst_data[i].keboardValue.length==24){
                        goal_data.push(rst_data[i])
                    }
                    
                }
                // goal_data
                that.setState({
                    map_setting_gov_data:goal_data
                })
  
            })
    }
  

    //监听图标5鼠标进入
    tool_icon_mouse_in=(index)=>{
        let that=this
        let data=that.state.tool_icon_tool_status;
        
        if(data[index]==false){
            data[index]=true;
            that.setState({
                tool_icon_tool_status:data
            },()=>{
                    if(index==3&&that.state.tool_icon_tool_status[index]){
                    setTimeout(() => {
                        // 更新基座温度
                        that.update_jizuo_heat();
                    }, 300);
                    
                    }
            }) 
        }
        
    }
    //监听图标5鼠标进入
    tool_icon_mouse_out=(index)=>{
        let that=this
        let data=that.state.tool_icon_tool_status;
        data[index]=false;
        that.setState({
            tool_icon_tool_status:data
        })
        // console.log(index)
    }
    
   /********************一键设置相关  begin********************** */

   // 16进制转字符串
    pxn_hex_to_str(str,mode=1){
        let that=this
        let goal_data=[]
        for(let i=1;i<12;i++){
            goal_data.push(parseInt(str[i*2]+""+str[i*2+1],16))
        }
        goal_data[1]=goal_data[1]*256+goal_data[0]
        goal_data.push(0)
        that.setState({
            preview_jizuo_param_data:goal_data,
        })
        if(mode==2){
            message.success("操作成功!")
            // 触发应用到设备
            that.setState({
                jizuo_param_data:goal_data
            })
            
            PY.pxn_jizuo_param_set(goal_data)
            // 更新本地数据
            let p12_lite_data=that.props.p12_lite_data
            p12_lite_data.jizuo_param_data=goal_data
            const { dispatch } = that.props;
            dispatch(FX.set_p12_lite_data(p12_lite_data))
            
        }


    }
    // 监听模式切换
   change_setting_map=(cur)=>{
    let that=this;
    that.setState({
        setting_mode:cur,
        map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1
    })
    }
    // 监听更新一键设置组件下标
    change_map_setting_index=(index,mode,status)=>{
        let that=this
        //已选择条件下,忽略监听
        if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_custom_select_index!=-1) return ;
        if(mode==1){
            // 官方
                if(status==1){
                
                
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,

                    map_setting_gov_index:index
                })
                that.pxn_hex_to_str(that.state.map_setting_gov_data[index].keboardValue)
                }else{
                that.setState({
                    map_setting_gov_index:-1
                })
                }
            
        }else if(mode==2){
            //自定义
            if(status==1){
                let map_setting_custom_data=that.state.map_setting_custom_data
                // let data=[]
                // for (let i=0;i<)
                let preview_jizuo_param_data=[].concat(map_setting_custom_data[index].hex)
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_custom_index:index,
                    preview_jizuo_param_data:preview_jizuo_param_data,

                })
                
                }else{
                that.setState({
                    map_setting_custom_index:-1
                })
                }
        }
    }

    //获取设置模式选中状态
    change_map_setting_select=(mode,index)=>{
            let that=this;
            if(mode==1){
            if(that.state.map_setting_gov_select_index!=index){

                that.setState({
                    map_setting_gov_select_index:index,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1,
                    map_setting_custom_select_index:-1,
                    })

                    that.pxn_hex_to_str(that.state.map_setting_gov_data[index].keboardValue)
                }else{
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1

                })
                }
            }else if(mode==2){
            if(that.state.map_setting_custom_select_index!=index){
                let map_setting_custom_data=that.state.map_setting_custom_data
                let preview_jizuo_param_data=[].concat(map_setting_custom_data[index].hex)
                that.setState({
                    map_setting_custom_select_index:index,
                    map_setting_gov_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1,
                    preview_jizuo_param_data:preview_jizuo_param_data,

                    })


                
                }else{
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1
                })
                }
            }
                
    }

    // 应用模式 官方推荐
    map_setting_apply=()=>{
        let that=this
        let map_setting_gov_select_index=that.state.map_setting_gov_select_index
        let map_setting_gov_data=that.state.map_setting_gov_data
        let map_key_str=map_setting_gov_data[map_setting_gov_select_index].keboardValue
        confirm({
            title: '确定应用该模式?',
            icon: <ExclamationCircleFilled />,
            content: null,
            okText:'确认',
            cancelText:'取消',
            onOk() {
                that.pxn_hex_to_str(map_key_str,2)
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1
                })
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
            });
    }
        
    // 应用自定义模式
    map_setting_custom_apply=()=>{
    let that=this
    let map_setting_custom_data=that.state.map_setting_custom_data
    let index=that.state.map_setting_custom_select_index
    
    confirm({
        title: '确定应用该模式?',
        icon: <ExclamationCircleFilled />,
        content: null,
        okText:'确认',
        cancelText:'取消',
        onOk() {
            that.setState({
                wheel_map_hex_pre:map_setting_custom_data[index].hex,
                wheel_map_key:map_setting_custom_data[index].str,
                led_color_data:map_setting_custom_data[index].led,
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1
            })
            // PY.pxn_wheel_set(map_setting_custom_data[index].led,map_setting_custom_data[index].hex)
            message.success("操作成功!")
        },
        onCancel() {
            console.log('Cancel');
        },
        });
    
    }

    // 重命名自定义模式
    map_setting_custom_rename=()=>{
    let that=this
    if(!that.state.custom_rename_key){
        message.error("输入不能为空!")
        return;
    }
    let map_setting_custom_data=that.state.map_setting_custom_data
    let index=that.state.map_setting_custom_select_index
    map_setting_custom_data[index].name=that.state.custom_rename_key
    that.setState({
        map_setting_custom_data:map_setting_custom_data,
        map_setting_gov_select_index:-1,
        map_setting_custom_select_index:-1,
        map_setting_gov_index:-1,
        map_setting_custom_index:-1
    })
    // 更新数据到设备
    PY.pxn_user_config_set("jizuo",map_setting_custom_data);

    // 关闭窗口
    that.custom_rename_modal_hidden();
    message.success("操作成功!")
    }

    // 保存自定义模式
    map_setting_custom_save=()=>{
    let that=this
    let map_setting_custom_data=that.state.map_setting_custom_data
    let index=that.state.map_setting_custom_select_index



    map_setting_custom_data[index].hex=that.state.preview_jizuo_param_data


    confirm({
        title: '确定保存该模式?',
        icon: <ExclamationCircleFilled />,
        content: null,
        okText:'确认',
        cancelText:'取消',
        onOk() {
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1
            })
            // 更新数据到设备
            PY.pxn_user_config_set("jizuo",map_setting_custom_data);
            // 关闭窗口
            that.custom_rename_modal_hidden();
            message.success("操作成功!")
        },
        onCancel() {
            console.log('Cancel');
        },
        });
    }

    // 删除自定义模式
    map_setting_custom_del=(index,e)=>{
        let that=this
        // console.log(index)
        e.stopPropagation();
        that.setState({
            map_setting_gov_select_index:-1,
            map_setting_custom_select_index:index,
            map_setting_gov_index:-1,
            map_setting_custom_index:-1
        })
        let map_setting_custom_data=that.state.map_setting_custom_data
        let map_key_str=map_setting_custom_data[index]
        confirm({
            title: '确定删除该模式?',
            icon: <ExclamationCircleFilled />,
            content: null,
            okText:'确认',
            cancelText:'取消',
            onOk() {
                map_setting_custom_data.splice(index,1)
                console.log(map_setting_custom_data)
                that.setState({
                    map_setting_custom_data:map_setting_custom_data,
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1
                })
                // 更新数据
                PY.pxn_user_config_set("jizuo",map_setting_custom_data)
                message.success("操作成功!")
            },
            onCancel() {
            },
            });
    }

    // 新增自定义模式
    map_setting_custom_add=()=>{
        let that=this;
        if(!that.state.custom_rename_key){
            message.error("输入不能为空!")
            return;
        }
        let data={}
        data.name=that.state.custom_rename_key

        // 获取当前hex
        data.hex=that.state.jizuo_param_data

        let map_setting_custom_data=that.state.map_setting_custom_data
        map_setting_custom_data.unshift(data)
        that.setState({
            map_setting_custom_data:map_setting_custom_data
        })

        // 更新数据到设备
        let tmp_handle= PY.pxn_user_config_set("jizuo",map_setting_custom_data);
        if(tmp_handle){
            tmp_handle.then((rst)=>{
                // message.success("操作成功!")
            })
        }
        // 关闭窗口
        that.custom_rename_modal_hidden();
        message.success("操作成功!")
        // console.log(data)
    }

    //隐藏修改名称窗口 
    custom_rename_modal_hidden=()=>{
    let that=this
    that.setState({
        custom_rename_modal_visible:false,
        custom_rename_key:undefined
    })
    }
    //显示修改名称窗口 
    custom_rename_modal_show=(flag)=>{
    let that=this
    if(flag==1){
        // 新增
        that.setState({
            custom_rename_modal_visible:true,
            custom_rename_modal_flag:flag,
            custom_rename_key:undefined
        })
    }else{
        let map_setting_custom_data=that.state.map_setting_custom_data
        let index=that.state.map_setting_custom_select_index
        let custom_rename_key=map_setting_custom_data[index].name
        // 更新
        that.setState({
            custom_rename_modal_visible:true,
            custom_rename_modal_flag:flag,
            custom_rename_key:custom_rename_key
        })
    }
    
    }
    //监听值变化 
    change_custom_rename_key(e){
    const { value } = e.target;
    this.setState({
        custom_rename_key: value
    })
    }

   /********************一键设置相关  end********************** */

// 监听方向盘双离合开关变化
change_wheel_two_pick_status=(status)=>{
    let that=this;
    that.setState({
        wheel_two_pick_status:status
    })
    PY.pxn_wheel_two_pick_set(status)
  }

  // 监听风速变化
  change_wind_speed_switch_status=(status)=>{
    let that=this;
    let wind_speed_switch_status_old=that.state.wind_speed_switch_status
    that.setState({
        wind_speed_switch_status_old:wind_speed_switch_status_old,
        wind_speed_switch_status:status
    })
    PY.pxn_wind_speed_switch_set(status)
  }
// 设备重置
  wheel_reset=()=>{
    let that=this;
    confirm({
        title: '确定进行设备重置?',
        icon: <ExclamationCircleFilled />,
        content: null,
        okText:'确认',
        cancelText:'取消',
        onOk() {
            // 设备重置相关操作
            message.success("操作成功!")
        },
        onCancel() {
            console.log('Cancel');
        },
      });
  }

//   监听校准状态
  change_wheel_jiaozhun_status=()=>{
     let that=this;
     let wheel_jiaozhun_status=1-that.state.wheel_jiaozhun_status
     that.setState({
        wheel_jiaozhun_status:wheel_jiaozhun_status
     })
     if(wheel_jiaozhun_status==0){
        PY.pxn_wheel_jiaozhun_stop();
     }else{
        PY.pxn_wheel_jiaozhun_start();
     }
  }

// 更新基座温度
  update_jizuo_heat(value=this.state.jizuo_heat_status){
    let that=this  
    let chart;

    if(that.state.jizuo_chart){
        that.state.jizuo_chart.clear()
        chart=that.state.jizuo_chart
    }else{
        chart = new Chart({
            container: 'jizuo-heat-canvas',
            autoFit: true,
          });
    }
     // 构造数据
     const data1 = [];
     for (let i = 0; i <=40; i++) {
       data1.push({
         type: i + '',
         value: 10,
       });
     }
     let goal_value=parseInt(value/2)
     
     const data2 = [];
     for (let i = 0; i <= 40; i++) {
       let item = {};
       item.type = i + '';
       item.value = 10;
       if (i ==goal_value) {
         item.value = 14;
       }
       if (i > goal_value) {
         item.value = 0;
       }
       data2.push(item);
     }
     
     
     chart.scale({
       type: {
         range: [0, 1],
       },
       value: {
         sync: true,
       },
     });
     chart.legend(false);
     chart.tooltip(false);
     
     const view1 = chart.createView();
     view1.data(data1);
     view1.axis(false);
     view1.coordinate('polar', {
       startAngle:(-10/8)* Math.PI,
       endAngle: 2/8 * Math.PI,
       innerRadius: 0.75,
       radius: 1,
     });
     view1
       .interval()
       .position('type*value')
       .color('#cccccc')
       .size(3);
     
     const view2 = chart.createView();
     view2.data(data1);
     view2.axis('value', false);
     view2.axis('type', {
       grid: null,
       line: null,
       tickLine: null,
       label: {
         offset: -12,
         style: {
           textAlign: 'center',
           fill: '#8F8F8F',
           fontSize: 8,
         },
         formatter: (val) => {
           if (val % 10 !== 0) {
             return '';
           }
     
           return val*2;
         },
       },
     });
     view2.coordinate('polar', {
       startAngle:(-10/8)* Math.PI,
       endAngle: 2/8 * Math.PI,
       innerRadius: 0.95,
       radius: 0.8,
     });
     view2
       .interval()
       .position('type*value')
       .color('#CBCBCB')
       .size(3);
     
     const view3 = chart.createView();
     view3.data(data2);
     view3.axis(false);
     view3.coordinate('polar', {
       startAngle:(-10/8)* Math.PI,
       endAngle: 2/8 * Math.PI,
       innerRadius: 0.75,
       radius: 1,
     });
     view3
       .interval()
       .position('type*value')
       .color('value', '#FFD056-#F65C00')
       .size(3);
       
     view3.annotation().text({
       position: ['50%', '58%'],
       content: value+'°',
       style: {
         fill: '#1A1A1A',
         fontSize: 28,
         textAlign: 'center',
         textBaseline: 'middle',
       },
     });
     
     chart.render();
     that.setState({
        jizuo_chart:chart
     })
 
 }
//  监听基座参数变化
 change_jizuo_param_data=(index,num)=>{
    let that=this


    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){
        let  jizuo_param_data=that.state.preview_jizuo_param_data
        let preview_jizuo_param_data=that.state.preview_jizuo_param_data
        preview_jizuo_param_data[index+1]=num
        that.setState({

            preview_jizuo_param_data:preview_jizuo_param_data
        })
    }else{
        let jizuo_param_data=that.state.jizuo_param_data
        jizuo_param_data[index+1]=num
        that.setState({
            jizuo_param_data:jizuo_param_data,

        })
        FN.anti_shake().then(()=>{
            PY.pxn_jizuo_param_set(jizuo_param_data)
            // 更新本地数据
            let p12_lite_data=that.props.p12_lite_data
            p12_lite_data.jizuo_param_data=jizuo_param_data
            const { dispatch } = that.props;
            dispatch(FX.set_p12_lite_data(p12_lite_data))
        })

    }
    
    

 }

// 显示基座参数设置
 jizuo_param_setting_display_view=(index)=>{
    let that=this
    let jizuo_param_tag_arr=that.state.jizuo_param_tag_arr

    let jizuo_param_data=that.state.jizuo_param_data
    let tag_name="";
    let min_num=0;
    let max_num=100;
    let step_num=1;
    const lgg_data=LANGUAGE.LGG_DATA[that.props.language_status];

    //判断当前状态是否为预览
    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){
            jizuo_param_data=that.state.preview_jizuo_param_data
    }


    if(index==6){
        min_num=30

    }else if(index==7){
        min_num=30;
        max_num=200;
    }else if(index==0){
        min_num=90;
        max_num=1600;
        step_num=10;
    }
    let hint_info;
    if(index==0){
        hint_info=lgg_data.v12_params_angle_hint
    }else if(index==1){
        hint_info=lgg_data.v12_params_force_feedback_hint
    }else if(index==2){
        hint_info=lgg_data.v12_params_game_spring_force_hint
    }else if(index==3){
        hint_info=lgg_data.v12_params_game_damping_force_hint
    }else if(index==4){
        hint_info=lgg_data.v12_params_game_friction_force_hint
    }else if(index==5){
        hint_info=lgg_data.v12_params_game_inertia_force_hint
    }else if(index==6){
        hint_info=lgg_data.v12_params_max_torque_hint
    }else if(index==7){
        hint_info=lgg_data.v12_params_max_rpm_hint
    }else if(index==8){
        hint_info=lgg_data.v12_params_machine_spring_force_hint
    }else if(index==9){
        hint_info=lgg_data.v12_params_machine_damping_force_hint
    }else if(index==10){
        hint_info=lgg_data.v12_params_road_sensitivity_hint
    }
    let pop_over_view=<div className='jkw-pop-over-view'>{hint_info}</div>
    return <div className={index==10?'pxn-slide-row-view':' pxn-slide-row-view margin-bottom-50'}>
    <div className='pxn-slide-row-top-view'>
        <div className='pxn-slide-row-top-left-view'>
            <text className='jizuo-font-1 margin-right-6'>{lgg_data["jizuo_label_"+index]}</text>
            <Popover content={pop_over_view} >
            <img  src="/assets/question_icon.png" className='jkw-icon-14 click-style'/>
            </Popover>
        </div>
        <div className='pxn-slide-row-top-right-view'>
            {jizuo_param_data[index+1]}    
        </div>
    </div>
    <div className='pxn-slide-row-center-view'>
        <div className='pxn-slide-row-center-wrap-view '>{min_num}</div>
        <Slider
            className='pxn-slider-view  '
            min={min_num}
            max={max_num}
            onChange={that.change_jizuo_param_data.bind(that,index)}
            // defaultValue={min_num+20}
            value={jizuo_param_data[index+1]}
            step={step_num}
            />
        <div className='pxn-slide-row-center-wrap-view'>{max_num}</div>
    </div>

</div>
 }
    render() {
        let that=this;
        const lgg_data=LANGUAGE.LGG_DATA[that.props.language_status];

// 右侧相关冒泡设置
        // 双离合开关
        let tool_popover_0 = (
            <div className='tool-popover-view-0'>
                <text className='tool-font-1'>方向盘双离合开关</text>
                <text className='tool-font-2 margin-top-5'>打开时下拨片映射功能将失效</text>
                <div className='tool-popover-switch-view'>
                    <div className={that.state.wheel_two_pick_status==1?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wheel_two_pick_status.bind(that,1)}>打开</div>
                    <div className={that.state.wheel_two_pick_status==0?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wheel_two_pick_status.bind(that,0)}>关闭</div>
                </div>
            </div>
          );
      
        // 盘面校准
        let tool_popover_2 = (
        <div className='tool-popover-view-2'>
            {/* <img  src/> */}
            <div className='tool-wheel-jiaozhun-pic-view'>
                <img src="/assets/wheel_jiaozhun_preview_bg.png" className='wheel-jiaozhun-bg-view'/>
                <img src="/assets/wheel_jiaozhun_preview.png" className= {that.state.wheel_jiaozhun_status==0?"wheel-jiaozhun-view":"wheel-jiaozhun-animation wheel-jiaozhun-view"}/>
            </div>
            <div className='tool-btn-1' onClick={that.change_wheel_jiaozhun_status.bind(that)}>{that.state.wheel_jiaozhun_status==0?"开始校准方向盘":"停止校准方向盘"}</div>
        </div>
        );
        // 温度监控
        let tool_popover_3 = (
        <div  className='tool-popover-view-3'>
            <text className='tool-font-3'>方向盘双离合开关</text>
            <div id="jizuo-heat-canvas"></div>
        </div>
        );
        // 调节风速
        let tool_popover_4 = (
        <div className='tool-popover-view-0'>
            <text className='tool-font-1'>请调节风速</text>
                <text className='tool-font-2 margin-top-5'>上一次风速是{that.state.wind_speed_switch_status_old+1}档</text>
                <div className='tool-popover-switch-view'>
                    <div className={that.state.wind_speed_switch_status==0?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wind_speed_switch_status.bind(that,0)}>1档</div>
                    <div className={that.state.wind_speed_switch_status==1?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wind_speed_switch_status.bind(that,1)}>2档</div>
                    <div className={that.state.wind_speed_switch_status==2?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wind_speed_switch_status.bind(that,2)}>3档</div>
                </div>
        </div>
        );

        // 右侧设置工具
        let tool_view=that.state.tool_icon_tool_status.map((item,index)=>{
            let cur=index+1;
            let content;
            let flag=false;
            if(index==0){

            }
            switch(index){
                case 0:content=tool_popover_0; break;
                case 2:content=tool_popover_2; break;
                case 3:content=tool_popover_3; break;
                case 4:content=tool_popover_4; break;
            }
            // 针对方向盘双离合开关进行状态处理
            if(index==0&&that.state.wheel_two_pick_status==1){
                flag=true;
            }

           if(item||flag){
               if(index==1){
                return <img src={"/assets/wheel_module/icon_"+cur+"_active.png"} onMouseEnter={that.tool_icon_mouse_in.bind(that,index)} onMouseLeave={that.tool_icon_mouse_out.bind(that,index)} onClick={that.wheel_reset.bind(that)}  className={index!=0?"jkw-icon-40 jkw-point-view margin-top-40":"jkw-icon-40 jkw-point-view"}/>
               }
               return <Popover content={content} placement="left" title={null}><img src={"/assets/wheel_module/icon_"+cur+"_active.png"} onMouseEnter={that.tool_icon_mouse_in.bind(that,index)} onMouseLeave={that.tool_icon_mouse_out.bind(that,index)} className={index!=0?"jkw-icon-40 jkw-point-view margin-top-40":"jkw-icon-40 jkw-point-view"}/></Popover>
           }else{
                if(index==1){
                    return <img src={"/assets/wheel_module/icon_"+cur+".png"} onClick={that.wheel_reset.bind(that)} onMouseEnter={that.tool_icon_mouse_in.bind(that,index)}  onMouseLeave={that.tool_icon_mouse_out.bind(that,index)} className={index!=0?"jkw-icon-40 jkw-point-view margin-top-40":"jkw-icon-40 jkw-point-view"}  />
               }else if(index==3){
                    return <Popover content={content} placement="left"  title={null}><div  onMouseEnter={that.tool_icon_mouse_in.bind(that,index)}  onMouseLeave={that.tool_icon_mouse_out.bind(that,index)} className='jizuo-heat-icon-view  margin-top-40 jkw-point-view'>{that.state.jizuo_heat_status}°</div></Popover>
                }
               return <Popover content={content} placement="left"  title={null}><img src={"/assets/wheel_module/icon_"+cur+".png"}  className={index!=0?"jkw-icon-40 jkw-point-view margin-top-40":"jkw-icon-40 jkw-point-view"}  /></Popover>
           }
         })




        //  获取官方推荐
        let map_setting_gov_view=that.state.map_setting_gov_data.map((item,index)=>{
            if(that.state.map_setting_gov_index==index||that.state.map_setting_gov_select_index==index){
                
                return <div className='jkw-quick_setting-row-active-view' onMouseEnter={that.change_map_setting_index.bind(that,index,1,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,1,0)} onClick={that.change_map_setting_select.bind(that,1,index)} >
                <div className='jkw-quick_setting-row-active-wrap-view'>{item.keyboardName}</div>
            </div>
            }
            return <div className='jkw-quick_setting-row-view jkw-font-2' onMouseEnter={that.change_map_setting_index.bind(that,index,1,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,1,0)} onClick={that.change_map_setting_select.bind(that,1,index)} >{item.keyboardName}</div>
        })

        // 获取一键设置模式
        let map_setting_custom_view=that.state.map_setting_custom_data.map((item,index)=>{
            if(that.state.map_setting_custom_index==index||that.state.map_setting_custom_select_index==index){
                return <div className='jkw-quick_setting-row-active-view' onMouseEnter={that.change_map_setting_index.bind(that,index,2,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,2,0)}  onClick={that.change_map_setting_select.bind(that,2,index)}>
                <div className='jkw-quick_setting-row-active-wrap-view'>{item.name}</div>
                <img src="/assets/map-setting-del-icon.png" className='jkw-map-del-icon-view'  onClick={that.map_setting_custom_del.bind(that,index)}/>
            </div>
            }
            
            return <div className='jkw-quick_setting-row-view jkw-font-2' onMouseEnter={that.change_map_setting_index.bind(that,index,2,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,2,0)}  onClick={that.change_map_setting_select.bind(that,2,index)}>{item.name}</div>

        })
        let map_setting_center_view=null;
        //  一键设置  缺省处理
        if((that.state.map_setting_gov_data.length==0&&that.state.setting_mode==1)||(that.state.map_setting_custom_data.length==0&&that.state.setting_mode==2)){
            map_setting_center_view=<div className='jkw-quick_setting-center-view flex-center'>
                <img src="/assets/display_none.png" className='jkw-display-none-icon-view' />
            </div>
        }else if(that.state.setting_mode==2){
            map_setting_center_view=<div className='jkw-quick_setting-center-view'>
            {map_setting_custom_view}
            </div>
        }else{
            map_setting_center_view=<div className='jkw-quick_setting-center-view'>
            {map_setting_gov_view}
            </div>
        }



        let map_setting_btn_view=null;
        if(that.state.setting_mode==1){
            if(that.state.map_setting_gov_select_index==-1&&that.state.map_setting_gov_index==-1){
                map_setting_btn_view=<div className="jkw-quick_setting-down-view opacity-5"   >
                <div className='jkw-quick_setting-down-mode2-view' >
                    应用模式
                </div>
            </div>
            }else{
                map_setting_btn_view=<div className="jkw-quick_setting-down-view click-style"   >
                <div className='jkw-quick_setting-down-mode2-view' onClick={that.map_setting_apply.bind(that)}>
                    应用模式
                </div>
            </div>
            }
            
        }else if(that.state.setting_mode==2){
            //自定义模式
            if(that.state.map_setting_custom_index==-1&&that.state.map_setting_custom_select_index==-1){
                map_setting_btn_view=<div className='jkw-quick_setting-down-view'>
                    <div className='jkw-quick_setting-down-mode2-view click-style' onClick={that.custom_rename_modal_show.bind(that,1)}>
                        新增一键设置
                    </div>
                </div> 
                
            }else{
                map_setting_btn_view=<div className='jkw-quick_setting-down-view'>
                <div className='jkw-quick_setting-down-mode3-view' onClick={that.map_setting_custom_apply.bind(that)}>应用模式</div>
                <div className='jkw-quick_setting-down-mode3-view' onClick={that.custom_rename_modal_show.bind(that,2)}>重新命名</div>
                <div className='jkw-quick_setting-down-mode4-view' onClick={that.map_setting_custom_save.bind(that)}>保存设置</div>
            </div> 
            }
            
        }

return (
    <div  className="jkw-wheel-view">
   
        {/* 设备未连接 */}
        <div className='jkw-wheel-none-view' hidden={that.state.jizuo_connect_status}></div>
        <div className='jkw-wheel-none-info-view' hidden={that.state.jizuo_connect_status}>
            <div className='jkw-wheel-none-hint-view'>
                <img src={"/assets/warn_icon.png"}   className="jkw-icon-16 margin-right-6"/>
                <text className='jkw-wheel-font-1'>基座未连接</text>
            </div>
        </div>
        <div  className='jkw-left-view '>
            {/* 一键设置模式 */}
            <div className='jkw-quick_setting-view  jkw-quick_setting-view-jizuo'>
                <div className='jkw-quick_setting-top-view'>
                    <div  className='jkw-quick_setting-top-left-view'>
                        <text className='jkw-font-1'>一键设置模式</text>
                        <img src="/assets/hint_icon.png" className="jkw-icon-13 jkw-point-view margin-left-6"/>
                    </div>
                    <div  className={that.state.setting_mode==1?'jkw-quick_setting-top-right-view':'jkw-quick_setting-top-right2-view'}>
                        <div className= {that.state.setting_mode==1?'jkw-quick_setting-top-row-view':'jkw-quick_setting-top-row-active-view'} onClick={that.change_setting_map.bind(that,2)}>自定义</div>
                        <div className={that.state.setting_mode==2?'jkw-quick_setting-top-row-view':'jkw-quick_setting-top-row-active-view'}   onClick={that.change_setting_map.bind(that,1)} >官方推荐</div>
                    </div>
                </div>
                {map_setting_center_view}
                {map_setting_btn_view}
            </div>
                   {/* 按键设置 */}
            <div className='jkw-mapping-view jkw-mapping-view-jizuo'>
                <div className='jkw-jizuo-param-list-view'>
                {that.jizuo_param_setting_display_view(0)}
                {that.jizuo_param_setting_display_view(1)}
                {that.jizuo_param_setting_display_view(2)}
                {that.jizuo_param_setting_display_view(3)}
                {that.jizuo_param_setting_display_view(4)}
                {that.jizuo_param_setting_display_view(5)}
                {that.jizuo_param_setting_display_view(6)}
                {that.jizuo_param_setting_display_view(7)}
                {that.jizuo_param_setting_display_view(8)}
                {that.jizuo_param_setting_display_view(9)}
                {that.jizuo_param_setting_display_view(10)}
                </div>
                
            </div>
        </div>
 

          {/* 工具栏 */}
        <div  className='jkw-right-view'>
            {tool_view}
        </div>
      


           {/*修改自定义模式名称  */}
      <Modal
          title={that.state.custom_rename_modal_flag==1?"新增自定义模式":"模式命名"}
          visible={that.state.custom_rename_modal_visible}
          onOk={that.state.custom_rename_modal_flag==1?that.map_setting_custom_add:that.map_setting_custom_rename}
          onCancel={that.custom_rename_modal_hidden}
          centered={true}
          okText="提交"
          cancelText="取消"
        //   maskClosable={false}
          width={400}
        >
            <Input placeholder="请输入" value={this.state.custom_rename_key} onChange={this.change_custom_rename_key.bind(that)}  style={{  width: '100%' }} />
        
       </Modal>
    </div>
);
  
    }
}


const mapStateToProps = state => {

    return {
        p12_lite_data:state.p12_lite_data,
        language_status:state.language_status
    }
};
export default connect(mapStateToProps)(Jizuo)



