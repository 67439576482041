/*
 * action 类型
 */

export const type = {
    SET_ROUTER_PATH:"SET_ROUTER_PATH",
    SET_P12_LITE_DATA:"SET_P12_LITE_DATA",
    language_status:"language_status",
}


// 设置路由路径
export function set_router_path(router_path){
    return {
        type:type.SET_ROUTER_PATH,
        router_path:router_path
    }
}

// 设置p12-lite数据
export function set_p12_lite_data(p12_lite_data){

    return {
        type:type.SET_P12_LITE_DATA,
        p12_lite_data:p12_lite_data
    }
}



// 设置语言状态
export function set_language_status(language_status){

    return {
        type:type.language_status,
        language_status:language_status
    }
}

