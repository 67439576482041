
import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as CONFIG from '../../config/config';
import { NavLink } from 'react-router-dom'
import { Chart } from '@antv/g2';
import {set_router_path} from '../../redux/action'
import * as PY from '../../utils/pywebview'
import * as FN  from '../../utils/index'
import {
    Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
  } from 'antd';
class Home extends React.Component {
   
    state = {  
        jizuo_heat_status:-300, //当前基座温度
        jizuo_chart:undefined, //基座图表对象
        map_active_cur:-1,  //当前触发按键的下标
        jizuo_param_data:[0,150,50,50,50,50,50,50,50,50,50,50],//基座参数数据
        device_trigger_status:0,
        wheel_connect_status:0,
        footrest_connect_status:0,
        shift_connect_status:0,
        jizuo_connect_status:0,
        youmen_value:80,
        youmen_value_old:-1,
        shache_value:80,
        shache_value_old:-1,
        lihe_value:80,
        lihe_value_old:-1,
        wheel_menu_value:0,
        wheel_angle_value:0,
    };
    
    // 组件卸载
    componentWillUnmount(){
      let that=this;
      if(Window.KEYBOARD_TIMER){
          clearInterval(Window.KEYBOARD_TIMER)
      }
      window.HOME_CHART=undefined
  }
    componentDidMount() {
        let that=this;
        // that.init_data()
        // 监听键盘按键
        that.keyboard_monitor_start();
        that.pc_data_sync_from_device(that.props.p12_lite_data)
    }
    // 接受path变化
    componentWillReceiveProps(nextprops,nextcontent){
      let that=this;
      let p12_lite_data=nextprops.p12_lite_data
      that.pc_data_sync_from_device(p12_lite_data)
      
    }
    
    //设备数据同步上位机
    pc_data_sync_from_device(p12_lite_data){
      let that=this;
  
      that.setState({
        jizuo_param_data:p12_lite_data.jizuo_param_data,
        wheel_connect_status:p12_lite_data.wheel_connect_status,
        jizuo_connect_status:p12_lite_data.jizuo_connect_status,
        shift_connect_status:p12_lite_data.gear_lever_state,
        footrest_connect_status:p12_lite_data.foot_pedal_state,
        jizuo_heat_status:p12_lite_data.pxn_jizuo_heat_status,
        lihe_value:p12_lite_data.lihe_value,
        shache_value:p12_lite_data.shache_value,
        youmen_value:p12_lite_data.youmen_value,


        wheel_menu_value:p12_lite_data.wheel_menu_value,
        wheel_angle_value:p12_lite_data.wheel_angle_value
      })
      
      // 避免重复更新
      if(that.state.jizuo_heat_status!=p12_lite_data.pxn_jizuo_heat_status){
        that.update_jizuo_heat(p12_lite_data.pxn_jizuo_heat_status)
      }


  }


  
    // 初始化数据
    init_data(){
      let that=this;
      FN.ajax({
          url: '/content/v1/columnContent/getEffectiveContentByCode?pageNum=1&pageSize=10&code=web_banner',
          data: {}
      }).
          then((res) => {
            console.log(res)
            // let rst_data=res.content.list
              // let goal_data=[]
              // for(let i=0;i<rst_data.length;i++){
              //     // if(rst_data[i].keboardValue.length==118){
              //         goal_data.push(rst_data[i])
              //     // }
                  
              // }
              // // goal_data
              // that.setState({
              //     map_setting_gov_data:goal_data
              // })

          })
  }
    componentDidUpdate() {
      
          
    }

    // 开始监听
    keyboard_monitor_start(){
      let that=this
      if(Window.KEYBOARD_TIMER){
        clearInterval(Window.KEYBOARD_TIMER)
      }
      // 初始化键盘值

      Window.KEYBOARD_TIMER=setInterval(()=>{
          let handle=PY.keyboard_get()
          if(handle==false) return;
          handle.then((rst)=>{
             let goal_hex=rst.device_hex
            if(goal_hex==-1){
              if(that.state.device_trigger_status==1){
                that.setState({
                  map_active_cur:-1,
                  device_trigger_status:0
                })
              }
              
            }else{
              // console.log("test1",that.state.map_active_cur)
              // 清除画图
              if(that.state.map_active_cur!=-1&&that.state.map_active_cur>=25&&that.state.map_active_cur<=33){
                // console.log("test2",that.state.map_active_cur)
                let canvas = document.getElementById('home_pxn_map');
                let ctx = canvas.getContext('2d');
                FN.canvas_clear(ctx,canvas.width,canvas.height)
                canvas.width=0;
                canvas.height=0;
             
              }else{
                // 进行绘图处理
                 that.change_map_active_cur(goal_hex,1)
              }

              that.setState({
                map_active_cur:goal_hex,
                device_trigger_status:1
              })
            }
                  
          }).catch(()=>{

          })

      },100)

        
  }
  // 停止监听
  keyboard_monitor_stop(){
      let that=this;
      if(Window.KEYBOARD_TIMER){
          clearInterval(Window.KEYBOARD_TIMER)
      }
      PY.keyboard_init();
      
  }

    // 切换路由
    change_path=( index,e)=>{
        
        let that=this
        //  console.log(index)
        const { dispatch } = this.props;
        dispatch(set_router_path(index))
    }
// 更新基座温度
update_jizuo_heat(value=this.state.jizuo_heat_status){
    let that=this  
    let chart;

    if(window.HOME_CHART){
        window.HOME_CHART.clear()
        chart=window.HOME_CHART
    }else{
        chart = new Chart({
            container: 'home-jizuo-heat-canvas',
            autoFit: true,
          });
    }
     // 构造数据
     const data1 = [];
     for (let i = 0; i <=40; i++) {
       data1.push({
         type: i + '',
         value: 10,
       });
     }
     let goal_value=parseInt(value/2)
     
     const data2 = [];
     for (let i = 0; i <= 40; i++) {
       let item = {};
       item.type = i + '';
       item.value = 10;
       if (i ==goal_value) {
         item.value = 14;
       }
       if (i > goal_value) {
         item.value = 0;
       }
       data2.push(item);
     }
     
     
     chart.scale({
       type: {
         range: [0, 1],
       },
       value: {
         sync: true,
       },
     });
     chart.legend(false);
     chart.tooltip(false);
     
     const view1 = chart.createView();
     view1.data(data1);
     view1.axis(false);
     view1.coordinate('polar', {
       startAngle:(-10/8)* Math.PI,
       endAngle: 2/8 * Math.PI,
       innerRadius: 0.75,
       radius: 1,
     });
     view1
       .interval()
       .position('type*value')
       .color('#cccccc')
       .size(3);
     
     const view2 = chart.createView();
     view2.data(data1);
     view2.axis('value', false);
     view2.axis('type', {
       grid: null,
       line: null,
       tickLine: null,
       label: {
         offset: -12,
         style: {
           textAlign: 'center',
           fill: '#8F8F8F',
           fontSize: 8,
         },
         formatter: (val) => {
           if (val % 10 !== 0) {
             return '';
           }
     
           return val*2;
         },
       },
     });
     view2.coordinate('polar', {
       startAngle:(-10/8)* Math.PI,
       endAngle: 2/8 * Math.PI,
       innerRadius: 0.95,
       radius: 0.8,
     });
     view2
       .interval()
       .position('type*value')
       .color('#CBCBCB')
       .size(3);
     
     const view3 = chart.createView();
     view3.data(data2);
     view3.axis(false);
     view3.coordinate('polar', {
       startAngle:(-10/8)* Math.PI,
       endAngle: 2/8 * Math.PI,
       innerRadius: 0.75,
       radius: 1,
     });
     view3
       .interval()
       .position('type*value')
       .color('value', '#FFD056-#F65C00')
       .size(3);
       
     view3.annotation().text({
       position: ['50%', '58%'],
       content: value+'°',
       style: {
         fill: '#1A1A1A',
         fontSize: 28,
         textAlign: 'center',
         textBaseline: 'middle',
       },
     });
     
     chart.render();
     window.HOME_CHART=chart
    
 
 }

 //监听设置激活按钮的下标
 change_map_active_cur=(cur,status)=>{

   let that=this
  //  当物理按键触发时，鼠标触发无效
   if(that.state.device_trigger_status==1){
    return;
   }


   if(cur>=25&&cur<=33){
    if(status==0){
      //鼠标离开
      that.setState({

        map_active_cur:-1
      })   
    }else{
      //鼠标进入
      that.setState({

        map_active_cur:cur
      })     
    }
    return;
   }


  let canvas = document.getElementById('home_pxn_map');
  let ctx = canvas.getContext('2d');
  let current_dom=document.getElementById('home-wheel-map-'+cur);

  if(status==0){

      
      //清除画图
      FN.canvas_clear(ctx,canvas.width,canvas.height)
      canvas.width=0;
      canvas.height=0;

      //鼠标离开
      that.setState({

          map_active_cur:-1
      })            
  }else{
    let root_dom=document.getElementsByClassName("home-wheel-view")[0]
            //     // 更新canvas大小
        canvas.width=root_dom.clientWidth
        canvas.height=root_dom.clientHeight

      //计算连线相关参数
          // 获取当前按键位置
          let c_x=0
          let c_y=0

          //获取设备图片对应位置
          let g_dom,g_x,g_y;
          let g_dom_p1,g_dom_p2,g_dom_p3;
        
          if(cur==17){
            g_dom=document.getElementById('jkw-map-pos-2');
            c_x=current_dom.offsetLeft+current_dom.clientWidth
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          }else if(cur==3){
            g_dom=document.getElementById('jkw-map-pos-6');
            c_x=current_dom.offsetLeft+current_dom.clientWidth
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==16){
            g_dom=document.getElementById('jkw-map-pos-7');
            c_x=current_dom.offsetLeft+current_dom.clientWidth
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==0x24){
            g_dom=document.getElementById('jkw-map-pos-11');
            c_x=current_dom.offsetLeft+current_dom.clientWidth
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==0x26){
            g_dom=document.getElementById('jkw-map-pos-12');
            c_x=current_dom.offsetLeft+current_dom.clientWidth
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==12){
            g_dom=document.getElementById('jkw-map-pos-21');
            c_x=current_dom.offsetLeft+current_dom.clientWidth
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==14){
            g_dom=document.getElementById('jkw-map-pos-25');
            c_x=current_dom.offsetLeft+current_dom.clientWidth
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==1){
            g_dom=document.getElementById('jkw-map-pos-26');
            c_x=current_dom.offsetLeft+current_dom.clientWidth
            c_y=current_dom.offsetTop+current_dom.clientHeight/2

          } else if(cur==0){
            g_dom=document.getElementById('jkw-map-pos-3');
            c_x=current_dom.offsetLeft+current_dom.clientWidth/2
            c_y=current_dom.offsetTop+current_dom.clientHeight
          } else if(cur==8){
            g_dom=document.getElementById('jkw-map-pos-4');
            c_x=current_dom.offsetLeft+current_dom.clientWidth/2
            c_y=current_dom.offsetTop+current_dom.clientHeight
          } else if(cur==0x28){
            g_dom=document.getElementById('jkw-map-pos-17');
            c_x=current_dom.offsetLeft+current_dom.clientWidth/2
              c_y=current_dom.offsetTop
          } else if(cur==0x2B){
            g_dom=document.getElementById('jkw-map-pos-19');
            c_x=current_dom.offsetLeft+current_dom.clientWidth/2
            c_y=current_dom.offsetTop
          } else if(cur==11){
            g_dom=document.getElementById('jkw-map-pos-8');
            c_x=current_dom.offsetLeft
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==9){
            g_dom=document.getElementById('jkw-map-pos-13');
            c_x=current_dom.offsetLeft
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==10){
            g_dom=document.getElementById('jkw-map-pos-14');
            c_x=current_dom.offsetLeft
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==0x25){
            g_dom=document.getElementById('jkw-map-pos-15');
            c_x=current_dom.offsetLeft
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==0x27){
            g_dom=document.getElementById('jkw-map-pos-16');
            c_x=current_dom.offsetLeft
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==13){
            g_dom=document.getElementById('jkw-map-pos-24');
            c_x=current_dom.offsetLeft
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==15){
            g_dom=document.getElementById('jkw-map-pos-28');
            c_x=current_dom.offsetLeft
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } else if(cur==2){
            g_dom=document.getElementById('jkw-map-pos-27');
            c_x=current_dom.offsetLeft
            c_y=current_dom.offsetTop+current_dom.clientHeight/2
          } 
       
          g_x=g_dom.offsetLeft+g_dom.clientWidth/2
          g_y=g_dom.offsetTop+g_dom.clientHeight/2
          
          g_dom_p1=g_dom.offsetParent;
          g_dom_p2=g_dom_p1.offsetParent;
          g_dom_p3=g_dom_p2.offsetParent;
          g_x=g_x+g_dom_p1.offsetLeft+g_dom_p2.offsetLeft
          g_y=g_y+g_dom_p1.offsetTop+g_dom_p2.offsetTop
          // console.log(g_x,g_y,c_x,c_y)
          FN.pxn_map_line(ctx,g_x,g_y,c_x,c_y)
      //鼠标进入
      that.setState({

        map_active_cur:cur
      })     
  }
}
wheel_menu_pos_display(){
    let that=this;
    let wheel_menu_value=that.state.wheel_menu_value
    if(wheel_menu_value==0){
      return <div className="home-menu-pos-view" ></div>
    }else if(wheel_menu_value==1){
      return <div className="home-menu-pos-view-1" ></div>
    }else if(wheel_menu_value==2){
      return <div className="home-menu-pos-view-2" ></div>
    }else if(wheel_menu_value==3){
      return <div className="home-menu-pos-view-3" ></div>
    }else if(wheel_menu_value==4){
      return <div className="home-menu-pos-view-4" ></div>
    }else if(wheel_menu_value==5){
      return <div className="home-menu-pos-view-5" ></div>
    }else if(wheel_menu_value==6){
      return <div className="home-menu-pos-view-6" ></div>
    }else if(wheel_menu_value==7){
      return <div className="home-menu-pos-view-7" ></div>
    }else if(wheel_menu_value==8){
      return <div className="home-menu-pos-view-8" ></div>
    }

}

//  重置设备
reset=()=>{
  let that=this;
  

}
    render() {
        let that=this;
        let map_active_cur=that.state.map_active_cur;
        let wheel_angle_max=that.state.jizuo_param_data[1];
        let wheel_angle_value=that.state.wheel_angle_value
    
        let wheel_angle_rate_100=Math.round(wheel_angle_value*100)
        let wheel_angle_rate_360=Math.round((wheel_angle_value)*wheel_angle_max-wheel_angle_max/2)
        console.log(wheel_angle_rate_100 ," ", wheel_angle_rate_360," ", wheel_angle_value)
        

    return (
        <div  className="jkw-main-info-view ">
    

            <div className='lv1-left-top-view'>
                <img src="/assets/banner.png" className="jkw-banner-icon "/>
            </div>
            
            <div className='lv2-main-view'>
                <div className='lv2-left-view'>
                     {/* 设备未连接 */}
                    <div className='jkw-wheel-none-view' hidden={that.state.wheel_connect_status}></div>
                    <div className='jkw-wheel-none-info-view' hidden={that.state.wheel_connect_status}>
                        <div className='jkw-wheel-none-hint-view'>
                            <img src={"/assets/warn_icon.png"}   className="jkw-icon-16 margin-right-6"/>
                            <text className='jkw-wheel-font-1'>方向盘未连接</text>
                        </div>
                    </div>


                    {/* 方向盘 */}
                    <div className='lv2-right-top-top-view'>
                          <text className='jkw-font-1'>方向盘</text>
                        <NavLink to="/wheel"  onClick={that.change_path.bind(that,1)} className='jkw-btn-1'>更多设置</NavLink>
                    </div>
                    <div className='home-wheel-view'>
                      <canvas id="home_pxn_map" hidden={that.state.map_active_cur==-1} />
                      <div className='home-wheel-row-view-1'>
                          <div id="home-wheel-map-17" className={map_active_cur==21-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2 margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,21-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,21-4,0)} ><div className='home-map-content-view-1'>Share</div></div>
                          <div id="home-wheel-map-3" className={map_active_cur==7-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,7-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,7-4,0)}><div className='home-map-content-view-1'>Menu</div></div>
                         
                          <div id="home-wheel-map-16" className={map_active_cur==20-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,20-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,20-4,0)}><div className='home-map-content-view-1'><img src={map_active_cur==20-4?"/assets/wheel_map_icon/home_map_icon_1_active.png":"/assets/wheel_map_icon/home_map_icon_1.png"} className='jkw-icon-3546-13'/></div></div>
                          <div id="home-wheel-map-36" className={map_active_cur==0x24?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,0x24,1)} onMouseLeave={that.change_map_active_cur.bind(that,0x24,0)}> <div className='home-map-content-view-1'>左上拨片</div></div>
                          <div id="home-wheel-map-38" className={map_active_cur==0x26?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,0x26,1)} onMouseLeave={that.change_map_active_cur.bind(that,0x26,0)}><div className='home-map-content-view-1'>左下拨片</div></div>
                          <div id="home-wheel-map-12" className={map_active_cur==16-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,16-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,16-4,0)}><div className='home-map-content-view-1'>L1</div></div>
                          <div id="home-wheel-map-14" className={map_active_cur==18-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,18-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,18-4,0)}><div className='home-map-content-view-1'>L2</div></div>
                          <div id="home-wheel-map-1" className={map_active_cur==5-4?"home-map-view-2 home-map-active-view ":"home-map-view-2 "} onMouseEnter={that.change_map_active_cur.bind(that,5-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,5-4,0)}><div className='home-map-content-view-1'>L3</div></div>
                      </div>
                      <div className='home-wheel-row-view-2'>
                          <div className={map_active_cur==0||map_active_cur==8?"home-wheel-center-wrap-view z-index-99":"home-wheel-center-wrap-view"}>
                            <div id="home-wheel-map-0" className={map_active_cur==4-4?"home-map-view-2 home-map-active-view  margin-right-45":"home-map-view-2 margin-right-45"} onMouseEnter={that.change_map_active_cur.bind(that,4-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,4-4,0)}><div className='home-map-content-view-1'>View</div></div>
                            <div id="home-wheel-map-8" className={map_active_cur==12-4?"home-map-view-2 home-map-active-view ":"home-map-view-2 "} onMouseEnter={that.change_map_active_cur.bind(that,12-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,12-4,0)}><div className='home-map-content-view-1'>Y</div></div>
                          </div>
                          {/* 画图页面 */}
                          <div className='jkw-home-wheel-map-view' style={{transform:"rotate("+(map_active_cur!=-1?0:wheel_angle_rate_360)+"deg)"}}>
                       
                              <img src={map_active_cur!=-1&&(map_active_cur<=0x27&&map_active_cur>=0x24)?"/assets/device_wheel_icon_rotate_active.png":"/assets/device_wheel_icon_rotate.png"} className='jkw-home-device-pic-1'  />
                              <div className='jkw-home-wheel-map-list-window-view'>

                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-2' id="jkw-map-pos-2"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-3' id="jkw-map-pos-3"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-4' id="jkw-map-pos-4"></div>
                                
                                {/* 上下左右 menu*/}
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-6' id="jkw-map-pos-6"></div>
                                
                                <div className='jkw-wheel-map-icon-view-4 jkw-map-pos-7'  id="jkw-map-pos-7"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-8' id="jkw-map-pos-8"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-11' id="jkw-map-pos-11"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-12'  id="jkw-map-pos-12"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-13' id="jkw-map-pos-13"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-14'  id="jkw-map-pos-14"></div>

                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-15' id="jkw-map-pos-15"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-16' id="jkw-map-pos-16"></div>

                                {/* 17,18,22 */}
                                <div className='jkw-wheel-map-icon-view-3 jkw-map-pos-17' id="jkw-map-pos-17"></div>
                                {/* 19,20,23 */}
                                <div className='jkw-wheel-map-icon-view-3 jkw-map-pos-19' id="jkw-map-pos-19"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-21' id="jkw-map-pos-21"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-24' id="jkw-map-pos-24"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-25' id="jkw-map-pos-25"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-26' id="jkw-map-pos-26"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-27' id="jkw-map-pos-27"></div>
                                <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-28' id="jkw-map-pos-28"></div>
                            </div> 
                          </div>
                          


                          <div className= {map_active_cur==0x28||map_active_cur==0x2B?"home-wheel-bottom-wrap-view z-index-99":"home-wheel-bottom-wrap-view"}>

                              <div className='home-wheel-rotate-view'>
                                 <div className='wheel-rotate-left-view'>{wheel_angle_max/2*-1}</div>
                                 <div className='wheel-rotate-center-view'>
                                      <div className='wheel-rotate-slider-view'></div>
                                      <div className='wheel-rotate-pos-view' style={{left:(wheel_angle_rate_100)+"%"}}></div>
                                 </div>
                                 <div className='wheel-rotate-right-view'>{wheel_angle_max/2}</div>
                              </div>
                              
                              <div className='home-wheel-bottom-wrap-view-2 margin-top-12'>                                
                                <img id="home-wheel-map-40" onMouseEnter={that.change_map_active_cur.bind(that,0x28,1)} onMouseLeave={that.change_map_active_cur.bind(that,0x28,0)} src={map_active_cur==0x28?"/assets/map-break-icon-active.png":"/assets/map-break-icon.png"} className='jkw-home-tc-icon'/>
                                <div className='home-wheel-bottom-wrap-view-1'>
                                  <img src={"/assets/home-map-menu-icon.png"} className='jkw-home-menu-icon-1'/>
                                  <div className="home-menu-pos-parent-view">
                                    {that.wheel_menu_pos_display()}
                                  </div>
                                </div>
                                <img id="home-wheel-map-43" onMouseEnter={that.change_map_active_cur.bind(that,0x2B,1)} onMouseLeave={that.change_map_active_cur.bind(that,0x2B,0)} src={map_active_cur==0x2B?"/assets/map-tc-icon-active.png":"/assets/map-tc-icon.png"} className='jkw-home-tc-icon'/>
                              </div>
                          </div>
                      </div>
                      <div className='home-wheel-row-view-1'>
                          <div id="home-wheel-map-11" className={map_active_cur==15-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2 margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,15-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,15-4,0)}><div className='home-map-content-view-1'>X</div></div>
                          <div id="home-wheel-map-9" className={map_active_cur==13-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,13-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,13-4,0)}><div className='home-map-content-view-1'>B</div></div>
                          <div id="home-wheel-map-10" className={map_active_cur==14-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,14-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,14-4,0)}><div className='home-map-content-view-1'>A</div></div>
                          <div id="home-wheel-map-37" className={map_active_cur==0x25?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,0x25,1)} onMouseLeave={that.change_map_active_cur.bind(that,0x25,0)}><div className='home-map-content-view-1'>右上拨片</div></div>
                          <div id="home-wheel-map-39" className={map_active_cur==0x27?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,0x27,1)} onMouseLeave={that.change_map_active_cur.bind(that,0x27,0)}><div className='home-map-content-view-1'>右下拨片</div></div>
                          <div id="home-wheel-map-13" className={map_active_cur==17-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,17-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,17-4,0)}><div className='home-map-content-view-1'>R1</div></div>
                          <div id="home-wheel-map-15" className={map_active_cur==19-4?"home-map-view-2 home-map-active-view  margin-bottom-32-5":"home-map-view-2  margin-bottom-32-5"} onMouseEnter={that.change_map_active_cur.bind(that,19-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,19-4,0)}><div className='home-map-content-view-1'>R2</div></div>
                          <div id="home-wheel-map-2" className={map_active_cur==6-4?"home-map-view-2 home-map-active-view ":"home-map-view-2 "} onMouseEnter={that.change_map_active_cur.bind(that,6-4,1)} onMouseLeave={that.change_map_active_cur.bind(that,6-4,0)}><div className='home-map-content-view-1'>R3</div></div>
                      </div>
                    </div>
                    
           
                </div>
                <div className='lv2-right-view'>
                    <div className='lv3-right-top-view'>
                        {/* 基座 */}
                        <div className='lv2-right-top-view'>
                                {/* 设备未连接 */}
                            <div className='jkw-wheel-none-view' hidden={that.state.jizuo_connect_status}></div>
                            <div className='jkw-wheel-none-info-view' hidden={that.state.jizuo_connect_status}>
                                <div className='jkw-wheel-none-hint-view'>
                                    <img src={"/assets/warn_icon.png"}   className="jkw-icon-16 margin-right-6"/>
                                    <text className='jkw-wheel-font-1'>基座未连接</text>
                                </div>
                            </div>

                            <div className='lv2-right-top-top-view'>
                                <text className='jkw-font-1'>基座</text>
                                <NavLink to="/jizuo"  onClick={that.change_path.bind(that,2)} className='jkw-btn-1'>更多设置</NavLink>
                            </div>
                            <div  className='jkw-home-jizuo-wrap-view'>
                              <div id="home-jizuo-heat-canvas"></div>
                              <img src="/assets/device_pic_2.png" className="jkw-home-device-pic-2  margin-top--5"/>
                              <div className='jkw-home-btn-1' onClick={ that.reset.bind(that)}>重置</div>
                            </div>
                            
                        </div>
                        {/* 排档杆 */}
                        <div className='lv2-right-top-view-2'>
                {/* 设备未连接 */}
                  <div className='jkw-wheel-none-view' hidden={that.state.shift_connect_status}></div>
                    <div className='jkw-wheel-none-info-view' hidden={that.state.shift_connect_status}>
                        <div className='jkw-wheel-none-hint-view'>
                            <img src={"/assets/warn_icon.png"}   className="jkw-icon-16 margin-right-6"/>
                            <text className='jkw-wheel-font-1'>排档杆未连接</text>
                        </div>
                    </div>



                            <div className='lv2-right-top-top-view'>
                                <text className='jkw-font-1'>排档杆</text>
                                <NavLink to="/shift_lever"  onClick={that.change_path.bind(that,4)} className='jkw-btn-1'>更多设置</NavLink>
                            </div>
                            <div className='home-gear-row-all-view'>
                              <div className='home-gear-row-view-1'>
                                <div className={map_active_cur==26?"home-map-view-2 home-map-active-view":"home-map-view-2"} onMouseEnter={that.change_map_active_cur.bind(that,26,1)} onMouseLeave={that.change_map_active_cur.bind(that,26,0)}><div className='home-map-content-view-1'><img src={map_active_cur==26?"/assets/shift_lever/gear-map-8-active.png":"/assets/shift_lever/gear-map-8.png"} className='jkw-icon-52-20'/></div></div>
                                <div className={map_active_cur==25?"home-map-view-2 home-map-active-view":"home-map-view-2"} onMouseEnter={that.change_map_active_cur.bind(that,25,1)} onMouseLeave={that.change_map_active_cur.bind(that,25,0)}><div className='home-map-content-view-1'><img src={map_active_cur==25?"/assets/shift_lever/gear-map-9-active.png":"/assets/shift_lever/gear-map-9.png"} className='jkw-icon-24'/></div></div>
                              </div>
                              <div className='home-gear-row-view-2'>
                                <div className={map_active_cur==27?"home-map-view-1 home-map-active-view":"home-map-view-1"} onMouseEnter={that.change_map_active_cur.bind(that,27,1)} onMouseLeave={that.change_map_active_cur.bind(that,27,0)}><div className='home-map-content-view-1'>档 1</div></div>
                                <div className={map_active_cur==29?"home-map-view-1 home-map-active-view":"home-map-view-1"} onMouseEnter={that.change_map_active_cur.bind(that,29,1)} onMouseLeave={that.change_map_active_cur.bind(that,29,0)}><div className='home-map-content-view-1'>档 3</div></div>
                                <div className={map_active_cur==31?"home-map-view-1 home-map-active-view":"home-map-view-1"} onMouseEnter={that.change_map_active_cur.bind(that,31,1)} onMouseLeave={that.change_map_active_cur.bind(that,31,0)}><div className='home-map-content-view-1'>档 5</div></div>
                                <img src="/assets/shift_lever/gear-map-device-1.png" className='jkw-icon-52-57'/>
                              </div>
                              <img src="/assets/shift_lever/shift_lever_device_3.png" className="jkw-device-pic-4 "/>
                              <div className='home-gear-row-view-3 '>
                                <div className={map_active_cur==28?"home-map-view-1 home-map-active-view":"home-map-view-1"} onMouseEnter={that.change_map_active_cur.bind(that,28,1)} onMouseLeave={that.change_map_active_cur.bind(that,28,0)}><div className='home-map-content-view-1'>档 2</div></div>
                                <div className={map_active_cur==30?"home-map-view-1 home-map-active-view":"home-map-view-1"} onMouseEnter={that.change_map_active_cur.bind(that,30,1)} onMouseLeave={that.change_map_active_cur.bind(that,30,0)}><div className='home-map-content-view-1'>档 4</div></div>
                                <div className={map_active_cur==32?"home-map-view-1 home-map-active-view":"home-map-view-1"} onMouseEnter={that.change_map_active_cur.bind(that,32,1)} onMouseLeave={that.change_map_active_cur.bind(that,32,0)}><div className='home-map-content-view-1'>档 6</div></div>
                                <div className={map_active_cur==33?"home-map-view-1 home-map-active-view":"home-map-view-1"} onMouseEnter={that.change_map_active_cur.bind(that,33,1)} onMouseLeave={that.change_map_active_cur.bind(that,33,0)}><div className='home-map-content-view-1'>倒档</div></div>
                              </div>
                            </div>
                        </div>
                    </div>

                    {/* 脚踏板 */}
                    <div className='lv2-right-dowm-view'>

                      {/* 设备未连接 */}
                    <div className='jkw-wheel-none-view' hidden={that.state.footrest_connect_status}></div>
                    <div className='jkw-wheel-none-info-view' hidden={that.state.footrest_connect_status}>
                        <div className='jkw-wheel-none-hint-view'>
                            <img src={"/assets/warn_icon.png"}   className="jkw-icon-16 margin-right-6"/>
                            <text className='jkw-wheel-font-1'>脚踏板未连接</text>
                        </div>
                    </div>


                        <div className='lv2-right-top-top-view'>
                            <text className='jkw-font-1'>脚踏板</text>
                            <NavLink to="/footrest"  onClick={that.change_path.bind(that,3)} className='jkw-btn-1'>更多设置</NavLink>
                        </div>
                        
                        <div className='jkw-home-shift-info-view'>
                            <img src="/assets/device_pic_3.png" className="jkw-home-device-pic-3 "/>
                            <div className='jkw-home-shift-detail-view'>

                                 {/* 离合 */}
                                 <div className='jkw-home-shift-row-view  margin-bottom-30'>
                                    <text className='jkw-home-font-1  margin-right-7'>离合</text>
                                    <div className='pxn-footrest-num-wrap-view margin-right-7 jkw-width-30'>0</div>
                                    <div className='jkw-footrest-row-right-view-1-wrap margin-right-7'>
                                         <div className='jkw-footrest-slider-view  slider-width-231'>
                                            <div className='jkw-footrest-slider-view-1'></div>
                                            <div className='jkw-footrest-slider-view-2' style={{width:that.state.lihe_value+"%"}}></div>
                                        </div>
                                        {that.state.lihe_value_old>0?<div className='jkw-footrest-slider-view-old'>
                                       
                                              <div className='jkw-footrest-slider-view-old-slider' style={{width:that.state.lihe_value_old+"%"}}></div>
                                              <text className='jkw-footrest-font-2'>{that.state.lihe_value_old}</text>

                                        </div>:''}
                                        
                                    </div>
                                    <div className='pxn-footrest-num-wrap-view margin-right-10 jkw-width-30'>100</div>
                                    <div className='pxn-footrest-num-wrap-view-2 jkw-width-30'>{that.state.lihe_value}</div>
                                </div>
                                

                                   {/* 刹车 */}
                                   <div className='jkw-home-shift-row-view margin-bottom-30'>
                                    <text className='jkw-home-font-1  margin-right-7'>刹车</text>
                                    <div className='pxn-footrest-num-wrap-view margin-right-7 jkw-width-30'>0</div>
                                    <div className='jkw-footrest-row-right-view-1-wrap margin-right-7'>
                                         <div className='jkw-footrest-slider-view  slider-width-231'>
                                            <div className='jkw-footrest-slider-view-1'></div>
                                            <div className='jkw-footrest-slider-view-2' style={{width:that.state.shache_value+"%"}}></div>
                                        </div>
                                        {that.state.shache_value_old>0?<div className='jkw-footrest-slider-view-old'>
                                       
                                              <div className='jkw-footrest-slider-view-old-slider' style={{width:that.state.shache_value_old+"%"}}></div>
                                              <text className='jkw-footrest-font-2'>{that.state.shache_value_old}</text>

                                        </div>:''}
                                    </div>
                                    <div className='pxn-footrest-num-wrap-view margin-right-10 jkw-width-30'>100</div>
                                    <div className='pxn-footrest-num-wrap-view-2 jkw-width-30'>{that.state.shache_value}</div>
                                </div>
                       
                        {/* 油门 */}
                        <div className='jkw-home-shift-row-view '>
                                    <text className='jkw-home-font-1  margin-right-7'>油门</text>
                                    <div className='pxn-footrest-num-wrap-view margin-right-7 jkw-width-30'>0</div>
                                    <div className='jkw-footrest-row-right-view-1-wrap margin-right-7'>
                                         <div className='jkw-footrest-slider-view  slider-width-231'>
                                            <div className='jkw-footrest-slider-view-1'></div>
                                            <div className='jkw-footrest-slider-view-2' style={{width:that.state.youmen_value+"%"}}></div>
                                        </div>
                                        {that.state.youmen_value_old>0?<div className='jkw-footrest-slider-view-old'>
                                       
                                              <div className='jkw-footrest-slider-view-old-slider' style={{width:that.state.youmen_value_old+"%"}}></div>
                                              <text className='jkw-footrest-font-2'>{that.state.youmen_value_old}</text>

                                        </div>:''}
                                    </div>
                                    <div className='pxn-footrest-num-wrap-view margin-right-10 jkw-width-30'>100</div>
                                    <div className='pxn-footrest-num-wrap-view-2 jkw-width-30'>{that.state.youmen_value} </div>
                                </div>
                  
                                
                            </div>
                        </div>
                             
                           
                        
                        
                    </div>
                </div>
            </div>

        </div>);
  
    }
}


const mapStateToProps = state => {

    return {
      p12_lite_data:state.p12_lite_data
    }
};
export default connect(mapStateToProps)(Home)



