/**
 * reducer
 */


import { type } from '../action';
import cookie from 'react-cookies'

// 初始化存储数据
const initialState = {
    // 当前菜单项
    language_status:'zh_cn',  //应用默认语言  en_us zh_cn  jp
    router_path: 0,  
    p12_lite_data:{
        "jizuo_upgrade_connect_status":0, //基座ota连接状态
        "jizuo_connect_status":0 , //基座连接状态
        "wheel_connect_status":0,//方向盘连接状态
        "foot_pedal_state":0,//脚踏板连接状态
        "gear_lever_state":0,//排挡杆连接状态
        "pxn_pc_version":"1.0.1", //当前程序版本号
        "jizuo_version":"0.14", //基座版本号
        "wheel_version":"1.0.1",  //方向盘版本号
        "footrest_version":"1.0.1", //脚踏板版本号
        "gear_lever_version":"1.0.1",  //排档板版本号
        "pxn_update_status":0,//是否主动更新
        "p12_lite_led":["#46FCD4","#00EB8C","#D3FEAA","#FBF383","#FEE25E","#FED77C","#FD7A7B","#FD9E86","#FE7575","#FD5690"],   //当前方向盘led
        "p12_lite_map":['-1', 'Up', 'Share', 'View', 'Y', 'Down', 'Menu', 'Home', 'X', 'Left', 'Right', '左上拨片', '左下拨片', 'B', 'A', '右上拨片', '右下拨片', 'Brake编码器左转', 'Brake编码器右转', 'TC编码器左转', 'TC编码器右转', 'L1', 'Brake编码器按键', 'TC编码器按键', 'R1', 'L2', 'L3', 'R3', 'R2'],    //当前方向盘映射字符串
        "p12_lite_map_hex":[-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],  //当前方向盘映射16进制
        "pxn_jizuo_heat_status":20,     //基座温度
        "wheel_two_pick_status":0,    //方向盘双离合开关
        "wind_speed_switch_status":1, //风速等级
        "jizuo_param_data":[0,150,50,50,50,50,50,50,50,50,50,50],//基座参数数据
        "p12_shift_lever_hex":[0,0,0,0,0,0,0,0,0,0],
        //排挡杆键盘映射
        "p12_shift_lever_str":['-1','Gear 1', 'Gear 2', 'Gear 3', 'Gear 4', 'Gear 5', 'Gear 6', 'Gear R', 'Gear Switch' ,'Hand Brake', ], 
        "footrest_map_key":['-1','离合', '刹车', '油门' ],
        "footrest_map_hex":[0, 0x16, 0x15, 0x14],
        "lihe_value":0,
        "shache_value":0,
        "youmen_value":0,
        "youmen_value_old":-1,
        "shache_value_old":-1,
        "lihe_value_old":-1,
        "wheel_menu_value":0,
        "wheel_angle_value":0.5,          //#方向盘角度
        "jizuo_upgrade_rate":0, //基座升级进度
        "jizuo_upgrade_status":0, 

        "lihe_coordinate_hex":[51, 3, 51, 51, 102, 6, 102, 102, 153, 9, 153, 153, 204, 12, 204, 204],
        "shache_coordinate_hex":[51, 3, 51, 51, 102, 6, 102, 102, 153, 9, 153, 153, 204, 12, 204, 204],
        "youmen_coordinate_hex":[51, 3, 51, 51, 102, 6, 102, 102, 153, 9, 153, 153, 204, 12, 204, 204],
        
    },

}

const ebikeData = (state = initialState, action) => {
    switch (action.type) {
        // 设置当前path
        case type.SET_ROUTER_PATH:
            return {
                ...state,
                router_path: action.router_path
            };
        // 设置p12-lite数据
        case type.SET_P12_LITE_DATA:
            return {
                ...state,
                p12_lite_data: action.p12_lite_data
            };
        // 设置语言状态
        case type.language_status:
            return {
                ...state,
                language_status: action.language_status
            };
   
        default:

            return { ...state };
    }
};



export default ebikeData;