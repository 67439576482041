import React from 'react'
import { connect } from "react-redux"
import Admin from './admin'
import Home from './pages/home';
import Loading from './pages/loading';

import jizuo from './pages/jizuo';
import shift_lever from './pages/shift_lever';
import dashboard from './pages/dashboard';
import footrest from './pages/footrest';
import setting from './pages/setting';
import upgrade from './pages/upgrade';
import wheel from './pages/wheel';
import * as PY from './utils/pywebview'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import * as FX from './redux/action'
import Promise from 'promise-polyfill';
import * as FN from './utils/index'
import { login } from './redux/action'
import cookie from 'react-cookies'

import * as FN_UI from "./utils/ui"

import {
message
} from 'antd';

message.config({
  top:100,

});

// To add to window  解决promise 在ie中未定义的问题
if (!window.Promise) {
 window.Promise = Promise;
 console.log(window.Promise)
}
/*兼容处理 低版本IE*/
//
Array.prototype.find || (Array.prototype.find = function (predicate) { 
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length || 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return null;
})

// IE 兼容方法
if (typeof Object.assign != 'function') {
    Object.assign = function(target) {
      'use strict';
      if (target == null) {
        throw new TypeError('Cannot convert undefined or null to object');
      }
  
      target = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var source = arguments[index];
        if (source != null) {
          for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
              target[key] = source[key];
            }
          }
        }
      }
      return target;
    };
  }


if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      var canvas = this;
      setTimeout(function() {
        var binStr = atob( canvas.toDataURL(type, quality).split(',')[1] );
        var len = binStr.length;
        var arr = new Uint8Array(len);
 
        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
 
        callback(new Blob([arr], { type: type || 'image/png' }));
      });
    }
  });
}

class IRouter extends React.Component {
  state={
     update_window_status:0,
     home_first:1
  
  }
  constructor(props){	
		super(props)
        let that=this;
        // 初始化UI适配
        FN_UI.init_ui();

        // //解决windows下 页面刷新问题
        // setInterval(()=>{
        //   if(window.JKW_UPDATE_WINDOW_STATUS==1){
        //     that.setState({
        //       update_window_status:1
        //     })
        //     window.JKW_UPDATE_WINDOW_STATUS=0;
        //     setTimeout(()=>{
        //       that.setState({
        //         update_window_status:0
        //       })
             
        //     },1)
        //   }
        // },200) 
        
	}

  // 组件卸载
  componentWillUnmount(){
    let that=this;
    if(Window.PXN_P12_LITE_DEVICE_INFO_TIMER){
        clearInterval(Window.PXN_P12_LITE_DEVICE_INFO_TIMER)
    }
  }
  
  // 组件安装
  componentDidMount() {

    let that=this;
    // let that=this
    if(Window.PXN_P12_LITE_DEVICE_INFO_TIMER){
        clearInterval(Window.PXN_P12_LITE_DEVICE_INFO_TIMER)
    }
    window.REACT_ROOT_THAT=that;
    window.PC_UPDATE_FN=that.pc_update_fn;

    
    // 定时更新设备状态
    Window.PXN_P12_LITE_DEVICE_INFO_TIMER=setInterval(()=>{
      window.PC_UPDATE_FN();
    },1000)

    // 
    that.setState({
      home_first:0
    })
  }



  // 主动更新数据
  pc_update_fn(){
    let that=window.REACT_ROOT_THAT;
    // FN.reduce(10).then(()=>{
      let p12_lite_data=that.props.p12_lite_data;
      let handle=PY.pxn_device_info()
      if(handle==false) return;
      handle.then((rst)=>{    
        // console.log(rst.jizuo_version)
        const { dispatch } = that.props;
        rst.youmen_value=parseInt(rst.youmen_value*100/65535)
        rst.shache_value=parseInt(rst.shache_value*100/65535)
        rst.lihe_value=parseInt(rst.lihe_value*100/65535)
  
        dispatch(FX.set_p12_lite_data(rst))
  
      }).catch(()=>{})
    // }).catch(()=>{

    // })

    
  }

    render() {
      let that=this;
        // return   <HashRouter>
        //       <Switch>
        //       <Route path='/setting' component={setting} />
        //       <Redirect to='/setting' />
        //       </Switch>
        //   </HashRouter>
     


      if(that.state.update_window_status==1){
        return (
          <HashRouter>
              <Switch>
                  <Route path='/loading' component={Loading} />
           
                  <Redirect to="/loading" />
              </Switch>
          </HashRouter>
      );
      }
      return (
        <HashRouter>
            <Switch>
                    <Route path="/" render={() =>
                        <Admin>
                            <Route path='/home' component={Home} />
                            <Route path='/jizuo' component={jizuo} />
                            <Route path='/shift_lever' component={shift_lever} />
                            <Route path='/footrest' component={footrest} />
                            <Route path='/setting' component={setting} />
                            <Route path='/dashboard' component={dashboard} />
                            <Route path='/upgrade' component={upgrade} />
                            <Route path='/wheel' component={wheel} />
                            {that.state.home_first==1?<Redirect to='/home' /> :""}
                            {/* <Redirect to='/footrest' /> */}
                        </Admin>
                    } />
                </Switch>
        </HashRouter>
    );

        if (this.props.userinfo == null) {
            return (
                <HashRouter>
                    <Switch>
                        <Route path='/login' component={FormLogin} />
                 
                        <Redirect to="/login" />
                    </Switch>
                </HashRouter>
            );
        }
        let router_path='/home'
        if(that.props.userinfo.role_id==9){
          router_path='/statistic/gather'
        }
       
        return (
            <HashRouter>
                <Switch>
                    <Route path="/" render={() =>
                        <Admin>
                            <Route path='/home' component={Home} />
                            <Route path='/reset_pswd' component={Reset_pswd} />
                            <Route path='/role/role_list' component={Role_list} />
                            <Route path='/privilege/admin_list' component={Admin_list} />
                            <Route path='/privilege/admin_detail' component={Admin_detail} />
                            <Route path="/privilege/part_list" component={Part_list}/>
                            <Route path='/order/list' component={Order_list} />
                            <Route path='/good/list' component={Good_list} />
                            <Route path='/device/list' component={Device_list} />
                            <Route path='/scene/list' component={Scene_list} />
                            <Route path='/statistic/gather' component={Statistic_gather} />
                            <Route path='/shop/list' component={Shop_list} />
                            <Route path='/good/add' component={Good_add} />
                            <Route path='/user/list' component={User_list} />
                            <Route path="/good/edit" component={Good_edit}/>
                            <Route path="/scene/add" component={Scene_add}/>
                            <Route path="/scene/edit" component={Scene_edit}/>
                            <Route path="/device/type_list" component={Device_type}/>
                            <Route path="/device/plan_list" component={Device_plan}/>
                            <Route path="/withdraw/list" component={Withdraw_list}/>
                            <Route path="/order/refund_verify" component={Order_refund_verify}/>
                         
                            <Redirect to={router_path} />
                        </Admin>
                    } />
                </Switch>

            </HashRouter>
        );
    }
}
const mapStateToProps = state => {

    return {
      p12_lite_data: state.p12_lite_data
    }
};
export default connect(mapStateToProps)(IRouter)



