export const  LGG_DATA={
    "zh_cn":{
        "v12_params_angle_hint":"根据模拟器，方向盘实际从一侧至另一侧可旋转的最大物理角度。",
        "v12_params_force_feedback_hint":"根据游戏类型和游戏内力反馈设置调整从游戏到设备的整体力反馈输出。该值越高，游戏内力反馈与总力反馈的比率越高。",
        "v12_params_game_spring_force_hint":"指游戏内调整方向盘的回中弹力。",
        "v12_params_game_damping_force_hint":"指游戏内调整方向盘的阻尼，阻尼力阻碍方向盘的旋转。",
        "v12_params_game_friction_force_hint":"指游戏内代表路面，碰撞等细节强度。",
        "v12_params_game_inertia_force_hint":"指游戏内调整方向盘的持续性的力。",
        "v12_params_max_torque_hint":"电机允许的最大输出扭矩。建议手力较弱的用户减少此设置，以避免失谐和过度的力反馈，防止由于过度的力反馈造成的意外。",
        "v12_params_max_rpm_hint":"该值越高，驾驶时方向盘回盘的速度越快。低转速保证了驾驶员的安全。同时，高转速度适合漂移。较慢的转速将使转向感觉更加稳定，并为您提供更多控制。",
        "v12_params_machine_spring_force_hint":"数值越高，盘面归中效果越强。它模拟了始终返回中点的力的效果。这适用于游戏本身没有力反馈的休闲赛车游戏。",
        "v12_params_machine_damping_force_hint":"模拟与车轮速度成比例增加的阻尼力，换言之，转盘速度高而阻尼力大。无论游戏结果如何。该值越高，相同转盘速度下的阻尼越大。更大的阻尼力使转盘更稳定，但会使它变得吃力。减少阻尼转盘变得更加迅速，但难以微操且失去质感。",
        "v12_params_road_sensitivity_hint":"路感调节从0-100,数值越大，路感越强烈，数值越小，路感越轻柔。",
        "setting_label_1":"语言设置",
        "setting_label_2":"开机自动启动",
        "setting_label_3":"关闭主面板",
        "setting_label_4":"当前版本",
        "setting_label_5":"版本检测",
        "setting_label_6":"问题反馈",
        "setting_label_7":"关于我们",
        "setting_label_8":"中文",
        "setting_label_9":"English",
        "setting_label_10":"日文",
        "setting_label_11":"启动",
        "setting_label_12":"禁止",
        "setting_label_13":"最小化到系统托盘",
        "setting_label_14":"退出系统",
        "setting_label_15":"已是最新版本",
        "setting_label_16":"立即更新",
        "setting_label_17":"立即反馈",
        "setting_label_18":"深圳市莱仕达电子科技有限公司",

        "header_label_1":"首页",
        "header_label_2":"方向盘",
        "header_label_3":"基座",
        "header_label_4":"脚踏板",
        "header_label_5":"排挡杆",
        "header_label_6":"仪表盘",
        "header_label_7":"升级",
        "header_label_8":"设置",

        "upgrade_label_1":"方向盘",
        "upgrade_label_2":"未连接",
        "upgrade_label_3":"已连接",
        "upgrade_label_4":"已是最新固件版本",
        "upgrade_label_5":"检测到新固件版本",
        "upgrade_label_6":"暂无新版本",
        "upgrade_label_7":"立即升级",
        "upgrade_label_8":"排挡杆",
        "upgrade_label_9":"脚踏板",
        "upgrade_label_10":"基座",
        "upgrade_label_11":"正在升级",
        "upgrade_label_12":"一键升级所有",

        "jizuo_label_0":"方向盘角度",
        "jizuo_label_1":"力回馈",
        "jizuo_label_2":"弹力性",
        "jizuo_label_3":"阻尼力",
        "jizuo_label_4":"正弦力",
        "jizuo_label_5":"惯性力",
        "jizuo_label_6":"最大扭矩",
        "jizuo_label_7":"最大转速",
        "jizuo_label_8":"机械归中力",
        "jizuo_label_9":"机械阻尼力",
        "jizuo_label_10":"路感感知",
 

    },
    "en_us":{
        "v12_params_angle_hint":"According to the simulator, the maximum physical angle that the steering wheel can actually be rotated from side to side.",
        "v12_params_force_feedback_hint":"Adjust the overall force feedback output from the game to the device based on the type of game and the game internal force feedback Settings. The larger this value, the higher the ratio of game internal force feedback to overall force feedback.",
        "v12_params_game_spring_force_hint":"Refers to the resilience of the steering wheel in the game.",
        "v12_params_game_damping_force_hint":"Refers to the damping of the steering wheel adjusted in the game, which blocks the rotation of the steering wheel.",
        "v12_params_game_friction_force_hint":"The intensity of details in the game that represent road surfaces, collisions, etc.",
        "v12_params_game_inertia_force_hint":"Refers to the continuous force that adjusts the steering wheel in-game.",
        "v12_params_max_torque_hint":"The maximum torque output allowed by the motor. It is recommended that players with weaker hands reduce this setting to avoid detuning and excessive force feedback, preventing accidents due to excessive force feedback.",
        "v12_params_max_rpm_hint":"The larger this value, the faster the steering wheel will be centered. Lower rotation speed provide more accuracy and stable steering, ensure safety for driver. Meanwhile, high rotation speeds are good for drifting.",
        "v12_params_machine_spring_force_hint":"Larger values provide a stronger centering force. It simulates the effect of a force that always returns to the center. This applies to casual racing games where the game itself has no force feedback.",
        "v12_params_machine_damping_force_hint":"Simulate the damping force proportional to the wheel speed, in other words, the rotation speed is high and the damping force is high. No matter how the game turns out. The higher the value, the larger the damping at the same rotary speed. Larger damping forces make the steering more stable, and provide a heavy feeling, while lower damping forces give a light feel with fast steering and low texture.",
        "v12_params_road_sensitivity_hint":"The Road Sensitivity ranges from 0-100. The greater the value, the stronger the road feel. The smaller the value, the softer the road feel.",
        "setting_label_1":"Language settings",
        "setting_label_2":"Automatically start when turned on",
        "setting_label_3":"Close the main panel",
        "setting_label_4":"Current version",
        "setting_label_5":"Version detection",
        "setting_label_6":"Problem feedback",
        "setting_label_7":"About us",
        "setting_label_8":"Chinese",
        "setting_label_9":"English",
        "setting_label_10":"Japanese",
        "setting_label_11":"Start",
        "setting_label_12":"Close",
        "setting_label_13":"Minimize to the system tray",
        "setting_label_14":"Exit",
        "setting_label_15":"Latest version",
        "setting_label_16":"Update Now",
        "setting_label_17":"Immediate feedback",
        "setting_label_18":"Shenzhen PXN Electronic Technology Co., Ltd.",
        "header_label_1":"Home",
        "header_label_2":"Wheel",
        "header_label_3":"Base",
        "header_label_4":"Pedal",
        "header_label_5":"Shifter",
        "header_label_6":"Dashboard",
        "header_label_7":"Upgrade",
        "header_label_8":"Settings",

        "upgrade_label_1":"Wheel",
        "upgrade_label_2":"Not connected",
        "upgrade_label_3":"Connected",
        "upgrade_label_4":"It is already the latest firmware version",
        "upgrade_label_5":"New firmware version detected",
        "upgrade_label_6":"There is no new version",
        "upgrade_label_7":"Update now",
        "upgrade_label_8":"Shifter",
        "upgrade_label_9":"Pedal",
        "upgrade_label_10":"Base",
        "upgrade_label_11":"Upgrading",
        "upgrade_label_12":"Upgrade all in one click",

        "jizuo_label_0":"Angle",
        "jizuo_label_1":"Force feedback",
        "jizuo_label_2":"Elasticity",
        "jizuo_label_3":"Damping force",
        "jizuo_label_4":"Sine force",
        "jizuo_label_5":"Inertial force",
        "jizuo_label_6":"Maximum torque",
        "jizuo_label_7":"Maximum rotational speed",
        "jizuo_label_8":"Mechanical centering force",
        "jizuo_label_9":"Mechanical damping force",
        "jizuo_label_10":"Road sensation perception",
    },
    
    "jp":{
        "v12_params_angle_hint":"シミュレーターによって、ステアリングホイールが実際に左右に回転できる物理的最大角度。",
        "v12_params_force_feedback_hint":"ゲームの種類とゲーム内のフォースフィードバック設定によって、ゲームからデバイスまでのフォースフィードバック出力全体を調整します。この値が高いほど、フォースフィードバック全体に対するゲーム内のフォースフィードバックの比率が高くなります。",
        "v12_params_game_spring_force_hint":"ゲーム内で調整されたステアリングホイールのスプリングバックスプリング。",
        "v12_params_game_damping_force_hint":"ゲーム内で調整されたステアリングホイールのダンピングを指し、ダンピング力はステアリングホイールの回転を妨げます。",
        "v12_params_game_friction_force_hint":"ゲーム内の路面、衝突、その他の詳細強度を表します。",
        "v12_params_game_inertia_force_hint":"ゲーム内でハンドルを調整する連続した力のことを指します。",
        "v12_params_max_torque_hint":"モーターが許容する最大出力トルク。手の力の弱いプレイヤーは、デチューンや過大なフォースフィードバックによる事故を防ぐために、この設定を小さくすることをお勧めします。",
        "v12_params_max_rpm_hint":"この値が高いほど、ステアリングホイールが元の位置に戻る速度が速くなります。低速回転はドライバーの安全性が確保されます。一方、高速回転はドリフトに適しています。回転速度を遅くすることで、ステアリングが安定し、制御しやすくなります。",
        "v12_params_machine_spring_force_hint":"数値が高いほど、中央に戻る効果が強くなります。常に中央に戻る力の効果をシミュレートしています。ゲーム自体にフォースフィードバックがない、カジュアルなレースゲームに適用されます。",
        "v12_params_machine_damping_force_hint":"車輪速に比例した減衰力をシミュレートしています。回転速度が高いほど、減衰力が強くなります。ゲーム結果がどうなろうとも。この値が高いほど、同じ回転速度でも減衰力が強くなります。減衰力が強いと、ステアリングが安定しますが、ステアリングホイールが硬くなります。減衰力が弱いと、ステアリングが速くなりますが、細かい制御ができないため、質感が損なわれます。",
        "v12_params_road_sensitivity_hint":"路面知覚感度は0〜100から、数値が大きいほど、道路感覚が強くなり、数値が小さいほど、道路感覚は柔らかくなります。",
        "setting_label_1":"言語設定",
        "setting_label_2":"自動起動",
        "setting_label_3":"メインパネルを閉じる",
        "setting_label_4":"現在のバージョン",
        "setting_label_5":"バージョン検出",
        "setting_label_6":"問題フィードバック",
        "setting_label_7":"私たちについて",
        "setting_label_8":"中国語",
        "setting_label_9":"English",
        "setting_label_10":"日本語",
        "setting_label_11":"開く",
        "setting_label_12":"閉じる",
        "setting_label_13":"システムトレイに最小化",
        "setting_label_14":"終了",
        "setting_label_15":"最新バージョン",
        "setting_label_16":"今すぐ更新",
        "setting_label_17":"即時フィードバック",
        "setting_label_18":"深セン市雷仕達電子科技有限公司",
        "header_label_1":"家",
        "header_label_2":"ハンドル",
        "header_label_3":"ベース",
        "header_label_4":"ペダル",
        "header_label_5":"シフトレバー",
        "header_label_6":"計器盤",
        "header_label_7":"アップグレード",
        "header_label_8":"設定",

        "upgrade_label_1":"ハンドル",
        "upgrade_label_2":"未接続",
        "upgrade_label_3":"接続",
        "upgrade_label_4":"最新のファームウェアバージョン",
        "upgrade_label_5":"新しいファームウェアバージョンが検出されました",
        "upgrade_label_6":"新しいバージョンはありません",
        "upgrade_label_7":"今すぐアップグレード",
        "upgrade_label_8":"シフトレバー",
        "upgrade_label_9":"ペダル",
        "upgrade_label_10":"ベース",
        "upgrade_label_11":"アップグレード中",
        "upgrade_label_12":"すべてをワンクリックでアップグレード",

        "jizuo_label_0":"角度かくど",
        "jizuo_label_1":"フォースフィードバック",
        "jizuo_label_2":"ばね力",
        "jizuo_label_3":"せいどうりょく",
        "jizuo_label_4":"せいげんりょく",
        "jizuo_label_5":"かんせいりょく",
        "jizuo_label_6":"最大トルク",
        "jizuo_label_7":"最大回転速度",
        "jizuo_label_8":"きかいちゅうりょく",
        "jizuo_label_9":"機械的減衰力",
        "jizuo_label_10":"ろせんかんかく",


    }

}
