import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Redirect, NavLink } from 'react-router-dom'
import './style/common.less';
import './style/loading.less';
import { connect } from "react-redux"
import Header from './components/Header/index'
import NavLeft from './components/NavLeft/index'
import * as PY from './utils/pywebview'
class Admin extends Component {
  state={
 
  }
  // 
  componentWillMount() {

  }


  render() {
    let that=this
    return (
      <div className="jkw-container-window" >
          <div  className="jkw-main-window">
            <Header className="jkw-top-window"   ></Header>
            <div className="jkw-down-window" >
              {this.props.children}
            </div>
          </div>
          
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Admin)


