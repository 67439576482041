import { Modal, message } from 'antd'
import * as fn from './index'
function check_pyweb(){
    // 防止数据刷新过快
    FN.anti_shake_3000().then(()=>{})
    if(window.hasOwnProperty("pywebview")) return true;
    fn.hiddin_my_loading()
    // message.error("非客户端标准环境！！！")
    return false;
}

function check_cefpy(){
    if(window.hasOwnProperty("CEFPY_windows_close")) return true;
    fn.hiddin_my_loading()
    // message.error("非客户端标准环境！！！")
    return false;
}

// 窗口开始移动
export function jkw_window_move_start(){
    if(check_cefpy()==false) return false;
    window.jkw_window_move_start()
}
// 窗口停止移动
export function jkw_window_move_stop(){
    if(check_cefpy()==false) return false;
    window.jkw_window_move_stop()
}


// 程序退出
export  function  window_exit(){
    if(check_cefpy()==false) return false;
    window.CEFPY_windows_close()
}

// 程序缩小
export  function  window_small(){
    if(check_cefpy()==false) return false;
    window.CEFPY_windows_min()
}
// 窗口移动
export function window_move(){
    if(check_cefpy()==false) return false;
    window.CEFPY_move()
}

//程序放大
export  function  window_enlarge(){
    if(check_cefpy()==false) return false;
    window.CEFPY_windows_max()
}


// 键盘值初始化
export  function  keyboard_init(){
    if(check_cefpy()==false) return false;
    window.jkw_keyboard_init()
}
//获取最后一次键盘输入值
export  function  keyboard_get(){
    if(check_cefpy()==false) return false;
    // alert("hah")
    return new Promise((resolve, reject) => {
        window.jkw_keyboard_get((rst)=>{
            if(rst){
                resolve(rst)
            }  else{
                reject()
            }
            
        })
    })
    
}

// 获取设备数据
export function pxn_device_info(){
    if(check_cefpy()==false) return false;
    return new Promise((resolve, reject) => {
        window.pxn_device_info((rst)=>{resolve(rst)})
    })

}

// 设置led数据
export function pxn_led_set(color_data){
    if(check_cefpy()==false) return false;
    // alert("hah")
    window.pxn_led_set(color_data)
}

// 设置map数据
export function pxn_map_set(cur,value){
    if(check_cefpy()==false) return false;
    // alert("hah")
    window.pxn_map_set(cur,value)
}

// 读取用户配置文件
export function pxn_user_config_get(key){
    if(check_cefpy()==false) return false;

    return new Promise((resolve, reject) => {
        window.pxn_user_config_get(key,(rst)=>{resolve(rst)})
    })
}

// 更新用户配置文件
export function pxn_user_config_set(key,data){
    if(check_cefpy()==false) return false;
    window.pxn_user_config_set(key,data)
}

// # 设置方向盘数据
export function pxn_wheel_set(color_data,map_data){
    
    if(check_cefpy()==false) return false;
    window.pxn_wheel_set(color_data,map_data)
}

// # 设置双离合开关
export function pxn_wheel_two_pick_set(status){
    if(check_cefpy()==false) return false;
    window.pxn_wheel_two_pick_set(status)
}


// # 设置风扇等级
export function pxn_wind_speed_switch_set(status){
    if(check_cefpy()==false) return false;
    window.pxn_wind_speed_switch_set(status)
}


// # 方向盘校准开始
export function pxn_wheel_jiaozhun_start(){
    if(check_cefpy()==false) return false;
    window.pxn_wheel_jiaozhun_start()
}

// # 方向盘校准停止
export function pxn_wheel_jiaozhun_stop(){
    if(check_cefpy()==false) return false;
    window.pxn_wheel_jiaozhun_stop()
}

// # 设置基座相关参数
export function pxn_jizuo_param_set(data){
    if(check_cefpy()==false) return false;
    window.pxn_jizuo_param_set(data)
}

//# 设置排挡杆映射单个
export function pxn_shift_lever_map_set_one(cur,value){
    if(check_cefpy()==false) return false;
    window.pxn_shift_lever_map_set_one(cur,value)
}

// # 设置排挡杆映射
export function  pxn_shift_lever_map_set(data){
    if(check_cefpy()==false) return false;
    window.pxn_shift_lever_map_set(data)
}

//# 设置脚踏板映射单个
export function pxn_footrest_map_set_one(cur,value){
    if(check_cefpy()==false) return false;
    window.pxn_footrest_map_set_one(cur,value)
}

// # 设置脚踏板
export function  pxn_footrest_map_set(data){
    if(check_cefpy()==false) return false;
    window.pxn_footrest_map_set(data)
}

//   # 设置脚踏板三个坐标
export function  pxn_footrest_coordinate_set(lihe_coordinate_hex,shache_coordinate_hex,youmen_coordinate_hex){
    if(check_cefpy()==false) return false;
    window.pxn_footrest_coordinate_set(lihe_coordinate_hex,shache_coordinate_hex,youmen_coordinate_hex)
}

// 基座升级 pxn_jizuo_upgrade
export function  pxn_jizuo_upgrade(url){
    if(check_cefpy()==false) return false;
    window.pxn_jizuo_upgrade(url)
}
