

// 346 614
// 706 1130

import { Alert } from "antd";

//更新rem
function set_rem() {

	var docEl = document.documentElement;
	var clientWidth = docEl.clientWidth;
	var clientHeight = docEl.clientHeight;
    var fix_params;
    // if(window.hilink){
    //     alert("设备宽度为="+clientWidth+" ,设备高度为="+clientHeight);
    // }
    console.log("设备宽度为="+clientWidth+" ,设备高度为="+clientHeight);
    
	if (!clientWidth) {
		return;
	}
    let fix_params_width= 10 * (clientWidth / 1440) 
    let fix_params_height=10*clientHeight/840

    fix_params = fix_params_width<fix_params_height?fix_params_width:fix_params_height;
    docEl.style.fontSize = fix_params+ 'px';
	

    // alert(docEl.style.fontSize)
    console.log(docEl.style.fontSize)
}




//初始化UI
export function init_ui(){
    window.JKW_UPDATE_WINDOW_WITH_REM=set_rem;
    //ui适配
    (function(doc, win) {
        set_rem();
        var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
            recalc = function() {
                set_rem();
            };
        if (!doc.addEventListener)
            return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener('DOMContentLoaded', recalc, false);
    })(document, window)

}


