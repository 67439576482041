
import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as CONFIG from '../../config/config';

import {
    Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
  } from 'antd';
  import { Chart,registerInteraction, registerAction, Action } from '@antv/g2';





class Dashboard extends React.Component {
   
    state = {  
        text:'',
        address:'',
        char_1:undefined
    };


       //显示图表数据
   show_footrest_char(cur){
    let that=this;
    let chart;
      let data = [
        // { x: 0, y: 0 },
        // { x: 25, y: 25 },
        // { x: 75, y: 75 },
        // { x: 100, y: 100 },
        {x:819,y: 13107,id:1},
        {x:1638, y:26214,id:2},
        {x:2457, y:39321,id:3},
        {x:3276, y:52428,id:4},
        // {x:0, y:0},
        // {x:65535, y:65535},
      ];
      if(cur==1){
        if(that.state.char_1){
            that.state.char_1.clear()
            chart=that.state.char_1
        }else{
            chart = new Chart({
                container: 'jkw-footrest-char-'+cur,
                autoFit: true,
                padding: [30, 30, 30, 30],
              });
        }
      }else if(cur==2){
        if(that.state.char_2){
            that.state.char_2.clear()
            chart=that.state.char_2
        }else{
            chart = new Chart({
                container: 'jkw-footrest-char-'+cur,
                autoFit: true,
                padding: [30, 30, 30, 30],
              });
        }
      }else if(cur==3){
        if(that.state.char_3){
            that.state.char_3.clear()
            chart=that.state.char_3
        }else{
            chart = new Chart({
                container: 'jkw-footrest-char-'+cur,
                autoFit: true,
                padding: [30, 30, 30, 30],
              });
        }
      } 

      chart.data(data);
      chart.tooltip(false);
      chart.axis(false);
      chart.area().position('x*y').color('#FF721E');
      chart.line().position('x*y').color('#FF721E');
      chart.point().size(7).position('x*y').color('#FF721E').shape('circle')
      chart.interaction('custom-interaction');
      chart.render();
      let newData = data;
                chart.on('dragend', ({ changedDatum }) => {
           newData = newData.map((d) => {
             if (d.id === changedDatum.id) {
                return changedDatum;
              }
              return d;
            });
            console.log(newData)
            chart.data(newData);
            chart.render(true);
          });
      if(cur==1){
        that.setState({
            char_1:chart
         })
      }else if(cur==2){
        that.setState({
            char_2:chart
         })
      }else if(cur==3){
        that.setState({
            char_3:chart
         })
      } 
  }
    componentDidMount(){
        this.show_footrest_char(1)
        return;
// let data=[
//     {
//       "date": "2015-01-04",
//       "nlp": 8,
//       "blockchain": 2
//     },
//     {
//       "date": "2015-01-11",
//       "nlp": 1,
//       "blockchain": 2
//     },
//     {
//       "date": "2015-01-18",
//       "nlp": 3,
//       "blockchain": 3
//     }]
  
let data = [
    // { x: 0, y: 0 },
    // { x: 25, y: 25 },
    // { x: 75, y: 75 },
    // { x: 100, y: 100 },
    {x:819,y: 13107,id:1},
    {x:1638, y:26214,id:2},
    {x:2457, y:39321,id:3},
    {x:3276, y:52428,id:4},
    // {x:0, y:0},
    // {x:65535, y:65535},
  ];
        const chart = new Chart({
            container: 'jkw-footrest-char-1',
            autoFit: true,
            height: 500,
          });
          chart.data(data);
      
          chart.line().position('x*y').color('#1890ff');

          chart.point().position('x*y').color('#2fc25b').size(3).style({ zIndex: 2 });
      
          chart.scale('blockchain', { sync: true });
          chart.scale('y', { sync: 'blockchain' });
          chart.axis('y', false);
          chart.point().position('x*y').color('#FF721E').shape('circle')
        //   chart.option('slider', { start: 0.2, end: 0.4 });
      
          chart.interaction('custom-interaction');
      
          chart.render();
      
          chart.tooltip({ showCrosshairs: true, showMarkers: false });
      
          let newData = data;
          chart.on('dragend', ({ changedDatum }) => {
            newData = newData.map((d) => {
              if (d.id === changedDatum.id) {
                return changedDatum;
              }
              return d;
            });
            console.log(newData)
            chart.data(newData);
            chart.render(true);
          });
//         const data = [
        
//             {x:819,y: 13107},
//             {x:1638, y:26214},
//             {x:2457, y:39321},
//             {x:3276, y:52428},

//           ];

//         const area = new Area('jkw-test', {
//             data,
//             xField: 'x',
//             yField: 'y',
//             tooltip:false,
//             appendPadding:16,
//             // startOnZero:true,
//             // autoFit:true,
//             xAxis:false,  
//             yAxis:false,  
//             meta:{
//                 range: [0, 1],
//             },
            
//             areaStyle:{
//                 fill: 'rgba(255, 183, 125, 0.2)',
//                 fillOpacity: 1,
//                 r:20
//             },
//             line:{
//                 color:"#FF721E",
                
//             },
//             color:"#FF721E",
//             point: { 
//                 color: '#FF721E',
//                 size:7,
//                 style: {
//                     fill: '#FF721E',
//                     lineWidth: 3,
//                     stroke: '#fff',
//                     cursor: 'pointer'
//                   },
//             },
  
//             smooth: true,
            
//           });
      
//           area.render();
//           area.on('element:mousedown', (e) => {
          
//             const { target ,currentTarget
//             } = e;
//             console.log(target)
//             console.log(target.offsetX)
//             console.log(target.attrs.x)
//             console.log(target.attrs.y)
//             // target.attrs.x=target.attrs.x-5

// target.attrs.y=target.attrs.y-5
//           });
        // G2.registerInteraction('conversion-tag-cursor', {
        //     start: [{ trigger: 'conversion-tag-group:mouseenter', action: 'cursor:pointer' }],
        //     end: [{ trigger: 'conversion-tag-group:mouseleave', action: 'cursor:default' }],
        //   });
          
        //   const data = [
        //     { action: '浏览网站', pv: 50000 },
        //     { action: '放入购物车', pv: 35000 },
        //     { action: '生成订单', pv: 25000 },
        //     { action: '支付订单', pv: 15000 },
        //     { action: '完成交易', pv: 8500, link: 'https://github.com/antvis/g2plot' },
        //   ];
          
        //   const plot = new Column('jkw-test', {
        //     data,
        //     xField: 'action',
        //     yField: 'pv',
        //     conversionTag: {},
        //     xAxis: {
        //       label: {
        //         autoHide: true,
        //         autoRotate: false,
        //       },
        //     },
        //     interactions: [
        //       {
        //         type: 'conversion-tag-cursor',
        //         cfg: {
        //           start: [
        //             {
        //               trigger: 'conversion-tag-group:mouseenter',
        //               action: (context) => {
        //                 const { event, view } = context;
        //                 const { nextElement } = event.data;
        //                 const link = nextElement.getModel().data.link;
        //                 // 根据实际情况修改，如果配置了链接跳转 则改变 cursor 样式
        //                 if (link) {
        //                   view.getCanvas().setCursor('pointer');
        //                 }
        //               },
        //             },
        //           ],
        //         },
        //       },
        //     ],
        //   });
          
        //   plot.render();
          
        //   plot.on('conversion-tag-group:mousedown', (evt) => {
        //     console.log(evt)
        //     const { target ,currentTarget
        //     } = evt;
        //     console.log(target)
        //     console.log(currentTarget)
   
        //   });
          
    }

    render() {
        let that=this;

return (
    <div  className="jkw-jizuo-view">
   
        <text>仪表盘</text>
        <div id="jkw-footrest-char-1"></div>
    </div>
);
  
    }
}


const mapStateToProps = state => {

    return {
  
    }
};
export default connect(mapStateToProps)(Dashboard)



