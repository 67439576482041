import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import { Provider } from 'react-redux'
import IRouter from './router';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store/configureStore';
import * as FN_CFG from './utils/config'
import VConsole from 'vconsole';

// 开启调试
const vConsole = new VConsole();

//初始化全局配置文件
FN_CFG.sys_cfg_init();


const store = configureStore();
try {
    // let i=0;
    // console.log(5/i)
    // console.log(i.data.dsf)
    ReactDOM.render(
        <Provider store={store}>
        <IRouter/>
            </Provider>, document.getElementById('root'));
} catch(e) {
    console.log(e);
    alert(e)
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


