import React from 'react'
import { Row, Col, Breadcrumb,Menu, Icon, Button } from 'antd';
import './index.less';
import { quit } from '../../redux/action'
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import cookie from 'react-cookies'
import { menuList } from './../../config/menuConfig'
import * as ACTION from './../../redux/action/index'
import {set_router_path} from '../../redux/action'
import * as PY from '../../utils/pywebview'
import * as LANGUAGE from "../../utils/language"
class Header extends React.Component {
    state = {
        currentKey: [],
        current_menu_index_pre:-1,
        current_menu_index:1,
        current_menu_text:[{'text':'首页','path':'/home'},
        {'text':'方向盘','path':'/wheel'},
        {'text':'基座','path':'/jizuo'},
        {'text':'脚踏板','path':'/footrest'},
        {'text':'排挡杆','path':'/shift_lever'},
        {'text':'仪表盘','path':'/dashboard'},
        {'text':'升级','path':'/upgrade'},
        {'text':'设置','path':'/setting'}]

    }

    componentWillMount(){
        let that=this
        that.setState({
            current_menu_index:that.props.router_path
        })
    }
    // 接受path变化
    componentWillReceiveProps(data){
        let that=this;
        // console.log("header")
        // console.log(data)
        if(data.router_path!=that.statecurrent_menu_index){
            that.setState({
                current_menu_index:data.router_path,
                current_menu_index_pre:-1
            })
        }
        
    }
    // 切换路由
    change_path=( index,e)=>{
        
        let that=this
        if(index==that.state.current_menu_index) return;
        that.setState({
            current_menu_index:index
        })
        const { dispatch } = this.props;
        dispatch(set_router_path(index))
    }
    //窗口缩小
    window_small=(e)=>{
        
        PY.window_small();
    }
    //窗口放大
    window_enlarge=(e)=>{
        
        PY.window_enlarge();
    }
    
    //程序关闭
    window_close=(e)=>{
        
        PY.window_exit();
    }
    
//    
    change_path_pre=(index,status,e)=>{
        let that=this
        
        if(status==1){
            that.setState({
                current_menu_index_pre:index
            })

        }else{
            that.setState({
                current_menu_index_pre:-1
            })
        }

    }
    

    // 开始拖拽
    window_move_start=(e)=>{
        let that=this;
        window.JKW_MOVE_STATU=1
        // PY.jkw_window_move_start()
        // console.log("window_move_start")
    }
// 停止拖拽
    window_move_stop=(e)=>{
        let that=this;
        window.JKW_MOVE_STATU=0
        // PY.jkw_window_move_stop()
        // console.log("window_move_stop")
    }

    window_moving=(e)=>{
        let that=this;
        if(window.JKW_MOVE_STATU==1){
            PY.window_move();
        }
        
    }

    // 阻止时间冒泡
    stopPropagation=(e)=>{
        e.stopPropagation()
    }

    render() {

    let that=this
    const lgg_data=LANGUAGE.LGG_DATA[that.props.language_status];
        
    let current_menu_text=that.state.current_menu_text;
    // console.log(that.props.router_path)
    // console.log("hh")
    let menu_view=current_menu_text.map((item,index)=>{




        let cur=index+1;
       if(index==that.state.current_menu_index){
           
           return <div className='jkw-point-cursor jkw-menu-active-view margin-left-30'  onClick={that.change_path.bind(that,index)} onMouseMove={that.stopPropagation.bind(that)} onMouseDown={that.stopPropagation.bind(that)} onMouseUp={that.stopPropagation.bind(that)} >
               <div className='jkw-menu-active-prefix-view'></div>
               <img src={"/assets/menu/menu_"+cur+"_active.png"} className="jkw-icon-16 margin-right-6"/>
               <text className='pxn-font-1'>{lgg_data["header_label_"+(index+1)]}</text>
           </div>
       }else if(that.state.current_menu_index_pre==index){
            return <NavLink to={item.path}  className='jkw-point-cursor jkw-menu-active-view margin-left-30' onClick={that.change_path.bind(that,index)} onMouseEnter={that.change_path_pre.bind(that,index,1)}  onMouseLeave={that.change_path_pre.bind(that,index,0)} onMouseMove={that.stopPropagation.bind(that)}  onMouseDown={that.stopPropagation.bind(that)} onMouseUp={that.stopPropagation.bind(that)}>
               <div className='jkw-menu-active-prefix-view2'></div>
                <img src={"/assets/menu/menu_"+cur+"_active.png"} className="jkw-icon-16 margin-right-6"/>
                <text className='pxn-font-1'>{lgg_data["header_label_"+(index+1)]}</text>
            </NavLink>
       }else{
           return <NavLink to={item.path}  className='jkw-point-cursor jkw-menu-view margin-left-30 '   onMouseEnter={that.change_path_pre.bind(that,index,1)}  onMouseLeave={that.change_path_pre.bind(that,index,0)}   onClick={that.change_path.bind(that,index)} onMouseMove={that.stopPropagation.bind(that)}  onMouseDown={that.stopPropagation.bind(that)} onMouseUp={that.stopPropagation.bind(that)}>
            <div className='jkw-menu-active-prefix-view2'></div>
                <img src={"/assets/menu/menu_"+cur+".png"} className="jkw-icon-16 margin-right-6"/>
                <text className='pxn-font-2'>{lgg_data["header_label_"+(index+1)]}</text>
           </NavLink>
       }
     })
    //  onMouseMoveCapture
    

        return (
            <div className="jkw-header-view"  onMouseMove={that.window_moving.bind(that)} onMouseLeave={that.window_move_stop.bind(that)}   onMouseDown={that.window_move_start.bind(that)} onMouseUp={that.window_move_stop.bind(that)} >
                {/* logo */}
                <img src="/assets/logo.png" className="jkw-logo-icon  "/>

                {/* 菜单列表 */}
                <div className='jkw-nav-view'>
                    {menu_view}
                    
                </div>
                
                {/* 操作窗口 */}
                <div className='jkw-header-right-view'>
                    <img src="/assets/window_small.png" className="jkw-icon-24 margin-right-28  jkw-point-cursor" onMouseMove={that.stopPropagation.bind(that)}  onClick={that.window_small.bind(that)}  onMouseDown={that.stopPropagation.bind(that)} onMouseUp={that.stopPropagation.bind(that)}/>
                    <img src="/assets/window_enlarge.png" className="jkw-icon-24 margin-right-28 jkw-point-cursor" onMouseMove={that.stopPropagation.bind(that)} onClick={that.window_enlarge.bind(that)}  onMouseDown={that.stopPropagation.bind(that)} onMouseUp={that.stopPropagation.bind(that)}/>
                    <img src="/assets/window_close.png" className="jkw-icon-24 margin-right-10 jkw-point-cursor" onMouseMove={that.stopPropagation.bind(that)} onClick={that.window_close.bind(that)}  onMouseDown={that.stopPropagation.bind(that)} onMouseUp={that.stopPropagation.bind(that)}/>
                </div>
   
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
 
        router_path:state.router_path,
        language_status:state.language_status
    }
};
export default connect(mapStateToProps)(Header)