import React from 'react'
import { connect } from "react-redux"
import { Menu, Icon, Button } from 'antd';
import { menuList,menurootList ,mymenurootList} from './../../config/menuConfig'
import { NavLink,Link } from 'react-router-dom'
import './index.less'
import { set_router_path } from '../../redux/action'
import '../../style/antd_menu.less'
const SubMenu = Menu.SubMenu;
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1535706_t4r6ppd0d.js',
  });
class NavLeft extends React.Component {
    state = {
        menu:[1,2,3,4,5,6,7,8,9],
        current_page:1

    }

    componentWillMount() {



      
    }
 
    // 切换路由
    change_path = (item) => {
        let that=this;
        if(item!=1&&item!=2&&item!=3&&item!=7&&item!=8) return ;
        that.setState({
            current_page:item
        })
        // const { dispatch } = this.props;
        // dispatch(set_router_path(item.key))
    }
    render() {
        let that = this;
        let menu_view=that.state.menu.map((item)=>{
             let menu_img_view=null;
     
            if(item==that.state.current_page){
                menu_img_view=<img src={"/assets/module/module_"+item+"_active.png"} className="jkw-icon-48"/>
                return <div className='jkw-point-cursor jkw-menu-active-view margin-top-28 ' onClick={that.change_path.bind(that,item)}>{menu_img_view}
                    <div className='jkw-menu-active-prefix-view'></div>
                </div>
            }else{
                menu_img_view=<img src={"/assets/module/module_"+item+".png"} className="jkw-icon-48 "/>
                return <div className='jkw-point-cursor jkw-menu-view margin-top-28 ' onClick={that.change_path.bind(that,item)}>{menu_img_view}</div>
            }
        
            
          })

        return (
            <div className="jkw-nav-view">
         
                <img src="/assets/logo.png" className="jkw-logo-icon  "/>
          

                {menu_view}
            </div>
        );
    }

}

const mapStateToProps = state => {

    return {
        userinfo: state.userinfo,
        router_path: state.router_path
    }
};
export default connect(mapStateToProps)(NavLeft)