
import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as CONFIG from '../../config/config';
import * as FN  from '../../utils/index'
import { HexColorPicker } from "react-colorful";
import * as PY from '../../utils/pywebview'
import { Chart } from '@antv/g2';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
    Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs,Popover
  } from 'antd';
  const { confirm } = Modal;
class Wheel extends React.Component {
   
    state = { 
        wheel_connect_status:false, //当前设备状态
        color_picker_visible:false, 
        custom_rename_modal_visible:false,
        custom_rename_key:undefined, //自定义名称输入值
        custom_rename_modal_flag:1, //1-新增,2-修改
        tool_icon_tool_status:[false,false,false,false,false],
        current_color_value:"#b32aa9",
        current_color_cur:0,
        led_color_data:["#46FCD4","#00EB8C","#D3FEAA","#FBF383","#FEE25E","#FED77C","#FD7A7B","#FD9E86","#FE7575","#FD5690"],
        led_current_cur:1,
        map_setting_gov_data:[],
        map_setting_custom_data:[],
        map_setting_custom_index:-1,
        map_setting_gov_index:-1,
        map_setting_gov_select_index:-1,
        map_setting_custom_select_index:-1,
        setting_mode:1,  //1-官方推荐 ,2-自定义
        led_bt_status:1, //led按键状态 1-3
        wheel_active_status:0,  //0-未选中,1-28选中
        wheel_input_refresh_visible:1,//0-隐藏,1-显示
        wheel_input_hex:0x00, //协议16进制值
        wheel_input_key:'', //当前输入值
        wheel_map_hex_pre:[],  //当前选中模式的hex值
        wheel_map_key:['-1', 'Up', 'Share', 'View', 'Y', 'Down', 'Menu', 'Home', 'X', 'Left', 'Right', '左上拨片', '左下拨片', 'B', 'A', '右上拨片', '右下拨片', 'Brake编码器左转', 'Brake编码器右转', 'TC编码器左转', 'TC编码器右转', 'L1', 'Brake编码器按键', 'TC编码器按键', 'R1', 'L2', 'L3', 'R3', 'R2'],
        wheel_map_key_tpl:['-1', 'Up', 'Share', 'View', 'Y', 'Down', 'Menu', 'Home', 'X', 'Left', 'Right', '左上拨片', '左下拨片', 'B', 'A', '右上拨片', '右下拨片', 'Brake编码器左转', 'Brake编码器右转', 'TC编码器左转', 'TC编码器右转', 'L1', 'Brake编码器按键', 'TC编码器按键', 'R1', 'L2', 'L3', 'R3', 'R2'],
        wheel_map_hex_tpl:[-1,4, 17, 0, 8, 6, 3, 16, 11, 7, 5, 36, 38, 9, 10, 37, 39, 41, 42, 44, 45, 12, 40, 43, 13, 14, 1, 2, 15],
        wheel_two_pick_status:0, //方向盘双离合开关 0-关闭 1-开启
        wheel_jiaozhun_status:0,//当前校准状态
        jizuo_heat_status:20, //当前基座温度
        jizuo_chart:undefined, //基座图表对象
        wind_speed_switch_status:1, //风速状态调节  0-2
        wind_speed_switch_status_old:2, //风速状态调节 老的 0-2

        preview_wheel_map_hex_pre:[], //预览专用
        preview_wheel_map_key:[], //预览专用
        preview_led_color_data:[] //预览专用

    };

    // 组件卸载
    componentWillUnmount(){
        let that=this;
        if(Window.KEYBOARD_TIMER){
            clearInterval(Window.KEYBOARD_TIMER)
        }
    }
    
    
    // 16进制转字符串
    pxn_hex_to_str(str,mode=1){
        let that=this
        let goal_data=[]
        for(let i=0;i<59;i++){
            goal_data.push(parseInt(str[i*2]+""+str[i*2+1],16))
        }
        // 获取键盘映射
        let pc_map_firm=[0,0x04,0x11,0x00,0x08,
            0x06,0x03,0x10,0x0b,
            0x07,0x05,0x22,0x24,
            0x09,0x0a,0x23,0x25,
            0x27,0x28,0x2a,0x2b,
            0x0c,0x26,0x29,0x0d,
            0x0e,0x01,0x02,0x0f
            ]
        let map_str_data=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        let map_hex_data=[-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
        for(let i=0;i<18;i++)
            map_hex_data[i]=goal_data[i+1]
        for(let i=19;i<29;i++)
            map_hex_data[i+15]=goal_data[i]
        for(let i=1;i<29;i++){
            map_str_data[i]=FN.pxn_map_to_str(map_hex_data[pc_map_firm[i]])
        }
        // 获取led数据
        let led_data=[];
        let r,g,b;
        for(let i=0;i<10;i++){
            r=goal_data[i*3+29]
            if(r<16){
                r="0"+goal_data[i*3+29].toString(16)
            }else{
                r=goal_data[i*3+29].toString(16)
            }

            g=goal_data[i*3+29+1]
            if(g<16){
                g="0"+goal_data[i*3+29+1].toString(16)
            }else{
                g=goal_data[i*3+29+1].toString(16)
            }
            b=goal_data[i*3+29+2]
            if(b<16){
                b="0"+goal_data[i*3+29+2].toString(16)
            }else{
                b=goal_data[i*3+29+2].toString(16)
            }
            // console.log(r)
            // console.log(g)
            // console.log(b)
            led_data.push("#"+r+g+b)
        }
        // console.log(str)
        // console.log(map_str_data)
        // console.log(led_data)
        that.setState({
            preview_wheel_map_hex_pre:map_hex_data,
            preview_wheel_map_key:map_str_data,
            preview_led_color_data:led_data
        })

        

        if(mode==2){
            // 触发应用到设备
            PY.pxn_wheel_set(led_data,map_hex_data)
            that.setState({
                wheel_map_hex_pre:map_hex_data,
                wheel_map_key:map_str_data,
                led_color_data:led_data
            })
            message.success("操作成功!")
            
        }

    }

    //设备数据同步上位机
    pc_data_sync_from_device(p12_lite_data){
        let that=this;

        if(!that.state.wheel_connect_status&&p12_lite_data.wheel_connect_status){
            // 获取缓存数据  断开后首次连接成功
            let handle=PY.pxn_user_config_get("wheel")
            if(handle){
                handle.then((rst_data)=>{
                    that.setState({
                        map_setting_custom_data:rst_data.data
                    })
        
                })
            }
            that.setState({
                wheel_connect_status:p12_lite_data.wheel_connect_status,
                led_color_data:p12_lite_data.p12_lite_led,
                wheel_map_key:p12_lite_data.p12_lite_map,
                wheel_map_hex_pre:p12_lite_data.p12_lite_map_hex,   //键盘映射16进制
                jizuo_heat_status:p12_lite_data.pxn_jizuo_heat_status,
                wheel_two_pick_status:p12_lite_data.wheel_two_pick_status,
                wind_speed_switch_status:p12_lite_data.wind_speed_switch_status
            })
        }else if(p12_lite_data.wheel_connect_status==1&&p12_lite_data.pxn_update_status==1){
            // 进行值的校验
            that.setState({
                wheel_connect_status:p12_lite_data.wheel_connect_status,
                led_color_data:p12_lite_data.p12_lite_led,
                wheel_map_key:p12_lite_data.p12_lite_map,
                wheel_map_hex_pre:p12_lite_data.p12_lite_map_hex,   //键盘映射16进制
                jizuo_heat_status:p12_lite_data.pxn_jizuo_heat_status,
                wheel_two_pick_status:p12_lite_data.wheel_two_pick_status,
                wind_speed_switch_status:p12_lite_data.wind_speed_switch_status
            })
        }
        if(that.state.wheel_connect_status!=p12_lite_data.wheel_connect_status){
            that.setState({
                wheel_connect_status:p12_lite_data.wheel_connect_status
            })
        }
        
    }


    // 接受path变化
    componentWillReceiveProps(nextprops,nextcontent){
        let that=this;
        // 防止数据刷新过快
        if(window.JK_ANTI_SHAKE_TIMER_3000){
            return;
        }
        let p12_lite_data=nextprops.p12_lite_data
        that.pc_data_sync_from_device(p12_lite_data)
                
      }


    // 组件安装
    componentDidMount() {
        let that=this
        that.pc_data_sync_from_device(that.props.p12_lite_data)
        // console.log("wheel")
        that.init_data();

     



    }

// 初始化数据
    init_data(){
        let that=this;
        FN.ajax({
            url: 'https://api.e-pxn.com.cn/mobile/v1/keyboard/getKeyboard?pageNum=1&pageSize=200&status=1&type=1&driveType=16',
            data: {
                pageNum: 1,
                pageSize: 200,
                status: 1,
                type: 1,
                driveType: 16
            }
        }).
            then((res) => {
                let rst_data=res.content.list
                let goal_data=[]
                for(let i=0;i<rst_data.length;i++){
                    if(rst_data[i].keboardValue.length==118){
                        goal_data.push(rst_data[i])
                    }
                    
                }
                // goal_data
                that.setState({
                    map_setting_gov_data:goal_data
                })
  
            })
    }
    
    change_color_value(goal){
        let that=this;
        

    //    判断当前是预览还是真实更改
    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){
        let preview_led_color_data=that.state.preview_led_color_data;
        preview_led_color_data[that.state.current_color_cur]=goal
        that.setState({
            current_color_value:goal,
            preview_led_color_data:preview_led_color_data
        })
  
    
    }else{
        let led_color_data=that.state.led_color_data;
        led_color_data[that.state.current_color_cur]=goal
        that.setState({
            current_color_value:goal,
            led_color_data:led_color_data
        })
         // 防抖处理
         FN.anti_shake().then(()=>{
            PY.pxn_led_set(led_color_data)
        })

    }
    

        
        
       
    }

    //监听图标5鼠标进入
    tool_icon_mouse_in=(index)=>{
        let that=this
        let data=that.state.tool_icon_tool_status;
        
        if(data[index]==false){
            data[index]=true;
            that.setState({
                tool_icon_tool_status:data
            },()=>{
                  if(index==3&&that.state.tool_icon_tool_status[index]){
                    setTimeout(() => {
                        // 更新基座温度
                        that.update_jizuo_heat();
                    }, 300);
                    
                  }
            }) 
        }
        


    }

    //监听图标5鼠标进入
    tool_icon_mouse_out=(index)=>{
        let that=this
        let data=that.state.tool_icon_tool_status;
        data[index]=false;
        that.setState({
            tool_icon_tool_status:data
        })
        // console.log(index)
    }

    change_setting_map=(cur)=>{
        let that=this;
        that.setState({
            setting_mode:cur,
            map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1
        })
    }

//监听led按键切换
    change_led_bt=(cur)=>{
        let that=this
        that.setState({
            led_bt_status:cur
        })
    }
    // 开始监听
    keyboard_monitor_start(){
        let that=this
        that.keyboard_monitor_stop();
        // 初始化键盘值
 
        Window.KEYBOARD_TIMER=setInterval(()=>{
            let handle=PY.keyboard_get()
            if(handle==false) return;
            handle.then((rst)=>{
                if(rst.data!=-1){
                    if(Window.KEYBOARD_TIMER){
                        clearInterval(Window.KEYBOARD_TIMER)
                    }
                    
                    that.setState({
                        wheel_input_key:rst.data,
                        wheel_input_hex:rst.hex,
                        wheel_input_refresh_visible:1
                    })
                    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
                    {

                    }else{
                        // 设置键盘值
                        PY.pxn_map_set(that.state.wheel_active_status,rst.hex)
                    }
                    
                }
            })

        },25)

          
    }
    // 停止监听
    keyboard_monitor_stop(){
        let that=this;
        if(Window.KEYBOARD_TIMER){
            clearInterval(Window.KEYBOARD_TIMER)
        }
        PY.keyboard_init();
        
    }




//监听设置激活按钮的下标
    change_map_active_cur=(cur,status,e)=>{
        // 防止数据刷新过快


        let canvas = document.getElementById('pxn_map');
        let ctx = canvas.getContext('2d');
       
        // console.log("target;",e.target)
        // console.log("currentTarget:",e.currentTarget)
        let current_dom=e.currentTarget
        
        
       
        let that=this
        
        
        if(status==0){
            let wheel_map_key
            if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
            {
                wheel_map_key=that.state.preview_wheel_map_key
            }else{
                wheel_map_key=that.state.wheel_map_key
            }

            that.keyboard_monitor_stop();
            
            //清除画图
            FN.canvas_clear(ctx,canvas.width,canvas.height)
            canvas.width=0;
            canvas.height=0;
            if(that.state.wheel_input_key){
                wheel_map_key[that.state.wheel_active_status]=that.state.wheel_input_key
            }

            //鼠标离开
            
            if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
            {
                that.setState({
   
                    wheel_active_status:0,
                    wheel_input_refresh_visible:0,
                    preview_wheel_map_key:wheel_map_key,
                    wheel_input_key:''
                }) 
            }else{
                that.setState({
   
                    wheel_active_status:0,
                    wheel_input_refresh_visible:0,
                    wheel_map_key:wheel_map_key,
                    wheel_input_key:''
                }) 
     
            }
                       
        }else{
            // 更新canvas大小
        canvas.width=current_dom.offsetParent.clientWidth
        canvas.height=current_dom.offsetParent.clientHeight
            //计算连线相关参数
                // 获取当前按键位置
                let c_x=0
                let c_y=0

                if(cur>=9&&cur<=12){
                    c_x=current_dom.offsetLeft+current_dom.clientWidth
                    c_y=current_dom.offsetTop+current_dom.clientHeight/2
                }else if(cur>=13&&cur<=16){
                    c_x=current_dom.offsetLeft
                    c_y=current_dom.offsetTop+current_dom.clientHeight/2

                }else if(cur>=1&&cur<=8){
                    c_x=current_dom.offsetLeft+current_dom.clientWidth/2
                    c_y=current_dom.offsetTop+current_dom.clientHeight

                }else if(cur>=17&&cur<=28){
                    c_x=current_dom.offsetLeft+current_dom.clientWidth/2
                    c_y=current_dom.offsetTop
                }

                //获取设备图片对应位置
                let g_dom,g_x,g_y;
                let g_dom_p1,g_dom_p2,g_dom_p3;

            
                if(cur==1||cur==5||cur==9||cur==10||cur==6){
                    g_dom=document.getElementById('jkw-map-pos-6');
                    // g_x=g_dom.offsetLeft
                    // g_y=g_dom.offsetTop+g_dom.clientHeight/2
                }else if(cur==17||cur==18||cur==22){

                    g_dom=document.getElementById('jkw-map-pos-17');
                    // g_x=g_dom.offsetLeft+g_dom.clientWidth/2
                    // g_y=g_dom.offsetTop
                }else if(cur==19||cur==20||cur==23){
                    g_dom=document.getElementById('jkw-map-pos-19');
                    // g_x=g_dom.offsetLeft+g_dom.clientWidth/2
                    // g_y=g_dom.offsetTop
                }else{
                    g_dom=document.getElementById('jkw-map-pos-'+cur);

                }
                g_x=g_dom.offsetLeft+g_dom.clientWidth/2
                g_y=g_dom.offsetTop+g_dom.clientHeight/2
                
                g_dom_p1=g_dom.offsetParent;
                g_dom_p2=g_dom_p1.offsetParent;
                g_dom_p3=g_dom_p2.offsetParent;
                g_x=g_x+g_dom_p1.offsetLeft+g_dom_p2.offsetLeft
                g_y=g_y+g_dom_p1.offsetTop+g_dom_p2.offsetTop
                // console.log(g_x,g_y,c_x,c_y)
                FN.pxn_map_line(ctx,g_x,g_y,c_x,c_y)
            //鼠标进入
            that.setState({
  
                wheel_active_status:cur,
                wheel_input_refresh_visible:0
            },()=>{
                that.keyboard_monitor_start();
                
            })     
        }
    }
    //清除输入数据
    clear_map_input=()=>{
        let that=this;
        // 防止数据刷新过快

        if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
        {
            let wheel_map_key=that.state.preview_wheel_map_key
            let wheel_map_hex_tpl=that.state.wheel_map_hex_tpl
            that.keyboard_monitor_start(); 
            wheel_map_key[that.state.wheel_active_status]=that.state.wheel_map_key_tpl[that.state.wheel_active_status]
            
            that.setState({
                wheel_input_key:'',
                preview_wheel_map_key:wheel_map_key,
                wheel_input_refresh_visible:0
            })
        }else{
            let wheel_map_key=that.state.wheel_map_key
            let wheel_map_hex_tpl=that.state.wheel_map_hex_tpl
            that.keyboard_monitor_start(); 
            wheel_map_key[that.state.wheel_active_status]=that.state.wheel_map_key_tpl[that.state.wheel_active_status]
            
            that.setState({
                wheel_input_key:'',
                wheel_map_key:wheel_map_key,
                wheel_input_refresh_visible:0
            })
             // 设置键盘值
            PY.pxn_map_set(that.state.wheel_active_status,wheel_map_hex_tpl[that.state.wheel_active_status])
        }
        
        
       
    }
    // 显示颜色提取器
    show_color_picker(cur,e){
        let that=this;
        let current_color_value;
        if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){
            current_color_value= that.state.preview_led_color_data[cur]
        }else{
            current_color_value=that.state.led_color_data[cur]
        }

        that.setState({
            color_picker_visible:true,
            current_color_cur:cur,
            current_color_value:current_color_value
        })
        // 获取dom更改位置
        let dom_color_picker_obj = document.getElementById('jkw-hexcolorpicker-wrap-view');
        let dom_jkw_led_cal_obj= document.getElementById('jkw-led-cal');
        let dom_jkw_led_cal_obj2= document.getElementById('jkw-led-cal2');
        let led_dis=dom_jkw_led_cal_obj2.offsetLeft-dom_jkw_led_cal_obj.offsetLeft
        let new_pos=dom_jkw_led_cal_obj.offsetLeft+dom_jkw_led_cal_obj.clientWidth/2+led_dis*cur-dom_color_picker_obj.clientWidth/2
        dom_color_picker_obj.style.left=new_pos+"px"

      

    }
    //隐藏颜色提取器
    hide_color_picker=()=>{
        let that=this;
        that.setState({
            color_picker_visible:false
        })
    }
// 确认颜色选择
    confirm_color_picker=()=>{
       let that=this;
    //    console.log("confirm_color_picker")
       that.hide_color_picker()
    }

    // 键盘设置显示组件
    map_display_view=(cur)=>{
        let that=this
        let wheel_map_key
        
    //    判断当前状态是否为预览
        if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){   
            wheel_map_key=that.state.preview_wheel_map_key
        }else{
            wheel_map_key =that.state.wheel_map_key
        }

        if(wheel_map_key[cur]=="Up"){
            return <img src="/assets/wheel_map_icon/icon_1_active.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="Share"){
            return <img src="/assets/wheel_map_icon/menu_icon_2.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="View"){
            return <img src="/assets/wheel_map_icon/menu_icon_3.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="Down"){
            return <img src="/assets/wheel_map_icon/icon_2_active.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="Home"){
            return <img src="/assets/wheel_map_icon/menu_icon_1.png" className='jkw-icon-66-29'/>
        }else if(wheel_map_key[cur]=="Left"){
            return <img src="/assets/wheel_map_icon/icon_3_active.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="Right"){
            return <img src="/assets/wheel_map_icon/icon_4_active.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="Brake编码器左转"){
            return <img src="/assets/wheel_map_icon/icon_5_active.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="Brake编码器右转"){
            return <img src="/assets/wheel_map_icon/icon_7_active.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="TC编码器左转"){
            return <img src="/assets/wheel_map_icon/icon_5_active.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="TC编码器右转"){
            return <img src="/assets/wheel_map_icon/icon_7_active.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="Brake编码器按键"){
            return <img src="/assets/wheel_map_icon/icon_6_active.png" className='jkw-icon-24'/>
        }else if(wheel_map_key[cur]=="TC编码器按键"){
            return <img src="/assets/wheel_map_icon/icon_6_active.png" className='jkw-icon-24'/>
        }

        return <div className='jkw-mapping-font-2-view'>{wheel_map_key[cur]}</div>
    }
    
    
    // 键盘设置显示组件
    key_display_view=(key)=>{
        let that=this


        if(key=="Up"){
            return <img src="/assets/wheel_map_icon/icon_1_active.png" className='jkw-icon-24'/>
        }else if(key=="Share"){
            return <img src="/assets/wheel_map_icon/menu_icon_2.png" className='jkw-icon-24'/>
        }else if(key=="View"){
            return <img src="/assets/wheel_map_icon/menu_icon_3.png" className='jkw-icon-24'/>
        }else if(key="Down"){
            return <img src="/assets/wheel_map_icon/icon_2_active.png" className='jkw-icon-24'/>
        }else if(key=="Home"){
            return <img src="/assets/wheel_map_icon/menu_icon_1.png" className='jkw-icon-66-29'/>
        }else if(key=="Left"){
            return <img src="/assets/wheel_map_icon/icon_3_active.png" className='jkw-icon-24'/>
        }else if(key=="Right"){
            return <img src="/assets/wheel_map_icon/icon_4_active.png" className='jkw-icon-24'/>
        }else if(key=="Brake编码器左转"){
            return <img src="/assets/wheel_map_icon/icon_5_active.png" className='jkw-icon-24'/>
        }else if(key=="Brake编码器右转"){
            return <img src="/assets/wheel_map_icon/icon_7_active.png" className='jkw-icon-24'/>
        }else if(key=="TC编码器左转"){
            return <img src="/assets/wheel_map_icon/icon_5_active.png" className='jkw-icon-24'/>
        }else if(key=="TC编码器右转"){
            return <img src="/assets/wheel_map_icon/icon_7_active.png" className='jkw-icon-24'/>
        }else if(key=="Brake编码器按键"){
            return <img src="/assets/wheel_map_icon/icon_6_active.png" className='jkw-icon-24'/>
        }else if(key=="TC编码器按键"){
            return <img src="/assets/wheel_map_icon/icon_6_active.png" className='jkw-icon-24'/>
        }

        return <text className='jkw-mapping-font-1'>{key}</text> 
    }
    


    // 监听更新一键设置组件下标
    change_map_setting_index=(index,mode,status)=>{
        let that=this
        //已选择条件下,忽略监听
        if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_custom_select_index!=-1) return ;
        if(mode==1){
            // 官方
             if(status==1){
                
                
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,

                    map_setting_gov_index:index
                })
                that.pxn_hex_to_str(that.state.map_setting_gov_data[index].keboardValue)
             }else{
                that.setState({
                    map_setting_gov_index:-1
                })
             }
            
        }else if(mode==2){
            //自定义
            if(status==1){
                let map_setting_custom_data=that.state.map_setting_custom_data

                let preview_wheel_map_hex_pre=[].concat(map_setting_custom_data[index].hex)
                let preview_wheel_map_key=[].concat(map_setting_custom_data[index].str)
                let preview_led_color_data=[].concat(map_setting_custom_data[index].led)
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_custom_index:index,
                    preview_wheel_map_hex_pre:preview_wheel_map_hex_pre,
                    preview_wheel_map_key:preview_wheel_map_key,
                    preview_led_color_data:preview_led_color_data,
                })
               
             }else{
                that.setState({
                    map_setting_custom_index:-1
                })
             }
        }
    }

    //获取设置模式选中状态
    change_map_setting_select=(mode,index)=>{
         let that=this;
         if(mode==1){
            if(that.state.map_setting_gov_select_index!=index){

                that.setState({
                    map_setting_gov_select_index:index,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1,
                    map_setting_custom_select_index:-1,
                 })

                 that.pxn_hex_to_str(that.state.map_setting_gov_data[index].keboardValue)
             }else{
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1

                })
             }
         }else if(mode==2){
            if(that.state.map_setting_custom_select_index!=index){
                let map_setting_custom_data=that.state.map_setting_custom_data

                let preview_wheel_map_hex_pre=[].concat(map_setting_custom_data[index].hex)
                let preview_wheel_map_key=[].concat(map_setting_custom_data[index].str)
                let preview_led_color_data=[].concat(map_setting_custom_data[index].led)
                that.setState({
                    map_setting_custom_select_index:index,
                    map_setting_gov_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1,
                    preview_wheel_map_hex_pre:preview_wheel_map_hex_pre,
                    preview_wheel_map_key:preview_wheel_map_key,
                    preview_led_color_data:preview_led_color_data,
                 })


              
             }else{
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1
                })
             }
         }
             
    }

    // 应用模式 官方推荐
    map_setting_apply=()=>{
        let that=this
        let map_setting_gov_select_index=that.state.map_setting_gov_select_index
        let map_setting_gov_data=that.state.map_setting_gov_data
        let map_key_str=map_setting_gov_data[map_setting_gov_select_index].keboardValue
        confirm({
            title: '确定应用该模式?',
            icon: <ExclamationCircleFilled />,
            content: null,
            okText:'确认',
            cancelText:'取消',
            onOk() {
                that.pxn_hex_to_str(map_key_str,2)
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1
                })
              console.log('OK');
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
     
    // 应用自定义模式
     map_setting_custom_apply=()=>{
        let that=this
        let map_setting_custom_data=that.state.map_setting_custom_data
        let index=that.state.map_setting_custom_select_index
        
        confirm({
            title: '确定应用该模式?',
            icon: <ExclamationCircleFilled />,
            content: null,
            okText:'确认',
            cancelText:'取消',
            onOk() {
                that.setState({
                    wheel_map_hex_pre:map_setting_custom_data[index].hex,
                    wheel_map_key:map_setting_custom_data[index].str,
                    led_color_data:map_setting_custom_data[index].led,
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1
                })
                PY.pxn_wheel_set(map_setting_custom_data[index].led,map_setting_custom_data[index].hex)
                
                message.success("操作成功!")
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        
     }

     // 重命名自定义模式
     map_setting_custom_rename=()=>{
        let that=this
        if(!that.state.custom_rename_key){
            message.error("输入不能为空!")
            return;
        }
        let map_setting_custom_data=that.state.map_setting_custom_data
        let index=that.state.map_setting_custom_select_index
        map_setting_custom_data[index].name=that.state.custom_rename_key
        that.setState({
            map_setting_custom_data:map_setting_custom_data,
            map_setting_gov_select_index:-1,
            map_setting_custom_select_index:-1,
            map_setting_gov_index:-1,
            map_setting_custom_index:-1
        })
        // 更新数据到设备
        PY.pxn_user_config_set("wheel",map_setting_custom_data);
    
        // 关闭窗口
        that.custom_rename_modal_hidden();
        message.success("操作成功!")
     }

     // 保存自定义模式
     map_setting_custom_save=()=>{
        let that=this
        let map_setting_custom_data=that.state.map_setting_custom_data
        let index=that.state.map_setting_custom_select_index
        map_setting_custom_data[index].hex=that.state.preview_wheel_map_hex_pre
        map_setting_custom_data[index].str=that.state.preview_wheel_map_key
        map_setting_custom_data[index].led=that.state.preview_led_color_data


        confirm({
            title: '确定保存该模式?',
            icon: <ExclamationCircleFilled />,
            content: null,
            okText:'确认',
            cancelText:'取消',
            onOk() {
                that.setState({
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1
                })
                // 更新数据到设备
                PY.pxn_user_config_set("wheel",map_setting_custom_data);
                // 关闭窗口
                that.custom_rename_modal_hidden();
                message.success("操作成功!")
            },
            onCancel() {
              console.log('Cancel');
            },
          });
     }

    // 删除自定义模式
    map_setting_custom_del=(index,e)=>{
        let that=this
        // console.log(index)
        e.stopPropagation();
        that.setState({
            map_setting_gov_select_index:-1,
            map_setting_custom_select_index:index,
            map_setting_gov_index:-1,
            map_setting_custom_index:-1
        })
        let map_setting_custom_data=that.state.map_setting_custom_data
        let map_key_str=map_setting_custom_data[index]
        confirm({
            title: '确定删除该模式?',
            icon: <ExclamationCircleFilled />,
            content: null,
            okText:'确认',
            cancelText:'取消',
            onOk() {
                map_setting_custom_data.splice(index,1)
                console.log(map_setting_custom_data)
                that.setState({
                    map_setting_custom_data:map_setting_custom_data,
                    map_setting_gov_select_index:-1,
                    map_setting_custom_select_index:-1,
                    map_setting_gov_index:-1,
                    map_setting_custom_index:-1
                })
                // 更新数据
                PY.pxn_user_config_set("wheel",map_setting_custom_data)
                message.success("操作成功!")
            },
            onCancel() {
            },
          });
    }

    // 新增自定义模式
    map_setting_custom_add=()=>{
        let that=this;
        if(!that.state.custom_rename_key){
            message.error("输入不能为空!")
            return;
        }
        let data={}
        data.name=that.state.custom_rename_key
        // 获取当前led
        data.led=that.state.led_color_data
        // 获取当前str
        data.str=that.state.wheel_map_key
        // 获取当前hex
        data.hex=that.state.wheel_map_hex_pre
        let map_setting_custom_data=that.state.map_setting_custom_data
        map_setting_custom_data.unshift(data)
        that.setState({
            map_setting_custom_data:map_setting_custom_data
        })

        // 更新数据到设备
        let tmp_handle= PY.pxn_user_config_set("wheel",map_setting_custom_data);
        if(tmp_handle){
            tmp_handle.then((rst)=>{
                // message.success("操作成功!")
            })
        }
        // 关闭窗口
        that.custom_rename_modal_hidden();
        message.success("操作成功!")
        // console.log(data)
    }

    //隐藏修改名称窗口 
  custom_rename_modal_hidden=()=>{
    let that=this
    that.setState({
        custom_rename_modal_visible:false,
        custom_rename_key:undefined
    })
  }
  //显示修改名称窗口 
  custom_rename_modal_show=(flag)=>{
    let that=this
    if(flag==1){
        // 新增
        that.setState({
            custom_rename_modal_visible:true,
            custom_rename_modal_flag:flag,
            custom_rename_key:undefined
        })
    }else{
        let map_setting_custom_data=that.state.map_setting_custom_data
        let index=that.state.map_setting_custom_select_index
        let custom_rename_key=map_setting_custom_data[index].name
        // 更新
        that.setState({
            custom_rename_modal_visible:true,
            custom_rename_modal_flag:flag,
            custom_rename_key:custom_rename_key
        })
    }
    
  }
    //监听值变化 
  change_custom_rename_key(e){
    const { value } = e.target;
    this.setState({
        custom_rename_key: value
    })
  }
// 监听方向盘双离合开关变化
  change_wheel_two_pick_status=(status)=>{
    let that=this;
    that.setState({
        wheel_two_pick_status:status
    })
    PY.pxn_wheel_two_pick_set(status)
  }

  // 监听风速变化
  change_wind_speed_switch_status=(status)=>{
    let that=this;
    let wind_speed_switch_status_old=that.state.wind_speed_switch_status
    that.setState({
        wind_speed_switch_status_old:wind_speed_switch_status_old,
        wind_speed_switch_status:status
    })
    PY.pxn_wind_speed_switch_set(status)
  }
// 设备重置
  wheel_reset=()=>{
    let that=this;
    confirm({
        title: '确定进行设备重置?',
        icon: <ExclamationCircleFilled />,
        content: null,
        okText:'确认',
        cancelText:'取消',
        onOk() {
            // 设备重置相关操作
            message.success("操作成功!")
        },
        onCancel() {
            console.log('Cancel');
        },
      });
  }

//   监听校准状态
  change_wheel_jiaozhun_status=()=>{
     let that=this;
     let wheel_jiaozhun_status=1-that.state.wheel_jiaozhun_status
     that.setState({
        wheel_jiaozhun_status:wheel_jiaozhun_status
     })
     if(wheel_jiaozhun_status==0){
        PY.pxn_wheel_jiaozhun_stop();
     }else{
        PY.pxn_wheel_jiaozhun_start();
     }
  }

// 更新基座温度
  update_jizuo_heat(value=this.state.jizuo_heat_status){
    let that=this  
    let chart;

    if(that.state.jizuo_chart){
        that.state.jizuo_chart.clear()
        chart=that.state.jizuo_chart
    }else{
        chart = new Chart({
            container: 'jizuo-heat-canvas',
            autoFit: true,
          });
    }
     // 构造数据
     const data1 = [];
     for (let i = 0; i <=40; i++) {
       data1.push({
         type: i + '',
         value: 10,
       });
     }
     let goal_value=parseInt(value/2)
     
     const data2 = [];
     for (let i = 0; i <= 40; i++) {
       let item = {};
       item.type = i + '';
       item.value = 10;
       if (i ==goal_value) {
         item.value = 14;
       }
       if (i > goal_value) {
         item.value = 0;
       }
       data2.push(item);
     }
     
     
     chart.scale({
       type: {
         range: [0, 1],
       },
       value: {
         sync: true,
       },
     });
     chart.legend(false);
     chart.tooltip(false);
     
     const view1 = chart.createView();
     view1.data(data1);
     view1.axis(false);
     view1.coordinate('polar', {
       startAngle:(-10/8)* Math.PI,
       endAngle: 2/8 * Math.PI,
       innerRadius: 0.75,
       radius: 1,
     });
     view1
       .interval()
       .position('type*value')
       .color('#cccccc')
       .size(3);
     
     const view2 = chart.createView();
     view2.data(data1);
     view2.axis('value', false);
     view2.axis('type', {
       grid: null,
       line: null,
       tickLine: null,
       label: {
         offset: -12,
         style: {
           textAlign: 'center',
           fill: '#8F8F8F',
           fontSize: 8,
         },
         formatter: (val) => {
           if (val % 10 !== 0) {
             return '';
           }
     
           return val*2;
         },
       },
     });
     view2.coordinate('polar', {
       startAngle:(-10/8)* Math.PI,
       endAngle: 2/8 * Math.PI,
       innerRadius: 0.95,
       radius: 0.8,
     });
     view2
       .interval()
       .position('type*value')
       .color('#CBCBCB')
       .size(3);
     
     const view3 = chart.createView();
     view3.data(data2);
     view3.axis(false);
     view3.coordinate('polar', {
       startAngle:(-10/8)* Math.PI,
       endAngle: 2/8 * Math.PI,
       innerRadius: 0.75,
       radius: 1,
     });
     view3
       .interval()
       .position('type*value')
       .color('value', '#FFD056-#F65C00')
       .size(3);
       
     view3.annotation().text({
       position: ['50%', '58%'],
       content: value+'°',
       style: {
         fill: '#1A1A1A',
         fontSize: 28,
         textAlign: 'center',
         textBaseline: 'middle',
       },
     });
     
     chart.render();
     that.setState({
        jizuo_chart:chart
     })
 
 }
    render() {
        let that=this;
        let wheel_active_status=that.state.wheel_active_status
        let wheel_input_refresh_visible=that.state.wheel_input_refresh_visible
        let wheel_input_key=that.state.wheel_input_key;
        let led_color_data=that.state.led_color_data
        

           //    判断当前状态是否为预览
        if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){
            led_color_data=that.state.preview_led_color_data
        }


        // 右侧相关冒泡设置
        // 双离合开关
        let tool_popover_0 = (
            <div className='tool-popover-view-0'>
                <text className='tool-font-1'>方向盘双离合开关</text>
                <text className='tool-font-2 margin-top-5'>打开时下拨片映射功能将失效</text>
                <div className='tool-popover-switch-view'>
                    <div className={that.state.wheel_two_pick_status==1?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wheel_two_pick_status.bind(that,1)}>打开</div>
                    <div className={that.state.wheel_two_pick_status==0?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wheel_two_pick_status.bind(that,0)}>关闭</div>
                </div>
            </div>
          );
      
        // 盘面校准
        let tool_popover_2 = (
        <div className='tool-popover-view-2'>
            {/* <img  src/> */}
            <div className='tool-wheel-jiaozhun-pic-view'>
                <img src="/assets/wheel_jiaozhun_preview_bg.png" className='wheel-jiaozhun-bg-view'/>
                <img src="/assets/wheel_jiaozhun_preview.png" className= {that.state.wheel_jiaozhun_status==0?"wheel-jiaozhun-view":"wheel-jiaozhun-animation wheel-jiaozhun-view"}/>
            </div>
            <div className='tool-btn-1' onClick={that.change_wheel_jiaozhun_status.bind(that)}>{that.state.wheel_jiaozhun_status==0?"开始校准方向盘":"停止校准方向盘"}</div>
        </div>
        );
        // 温度监控
        let tool_popover_3 = (
        <div  className='tool-popover-view-3'>
            <text className='tool-font-3'>方向盘双离合开关</text>
            <div id="jizuo-heat-canvas"></div>
        </div>
        );
        // 调节风速
        let tool_popover_4 = (
        <div className='tool-popover-view-0'>
            <text className='tool-font-1'>请调节风速</text>
                <text className='tool-font-2 margin-top-5'>上一次风速是{that.state.wind_speed_switch_status_old+1}档</text>
                <div className='tool-popover-switch-view'>
                    <div className={that.state.wind_speed_switch_status==0?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wind_speed_switch_status.bind(that,0)}>1档</div>
                    <div className={that.state.wind_speed_switch_status==1?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wind_speed_switch_status.bind(that,1)}>2档</div>
                    <div className={that.state.wind_speed_switch_status==2?"tool-popover-switch-select-view":"tool-popover-switch-unselect-view"} onClick={that.change_wind_speed_switch_status.bind(that,2)}>3档</div>
                </div>
        </div>
        );

        // 右侧设置工具
        let tool_view=that.state.tool_icon_tool_status.map((item,index)=>{
            let cur=index+1;
            let content;
            let flag=false;
            if(index==0){

            }
            switch(index){
                case 0:content=tool_popover_0; break;
                case 2:content=tool_popover_2; break;
                case 3:content=tool_popover_3; break;
                case 4:content=tool_popover_4; break;
            }
            // 针对方向盘双离合开关进行状态处理
            if(index==0&&that.state.wheel_two_pick_status==1){
                flag=true;
            }

           if(item||flag){
               if(index==1){
                return <img src={"/assets/wheel_module/icon_"+cur+"_active.png"} onMouseEnter={that.tool_icon_mouse_in.bind(that,index)} onMouseLeave={that.tool_icon_mouse_out.bind(that,index)} onClick={that.wheel_reset.bind(that)}  className={index!=0?"jkw-icon-40 jkw-point-view margin-top-40":"jkw-icon-40 jkw-point-view"}/>
               }
               return <Popover content={content} placement="left" title={null}><img src={"/assets/wheel_module/icon_"+cur+"_active.png"} onMouseEnter={that.tool_icon_mouse_in.bind(that,index)} onMouseLeave={that.tool_icon_mouse_out.bind(that,index)} className={index!=0?"jkw-icon-40 jkw-point-view margin-top-40":"jkw-icon-40 jkw-point-view"}/></Popover>
           }else{
                if(index==1){
                    return <img src={"/assets/wheel_module/icon_"+cur+".png"} onClick={that.wheel_reset.bind(that)} onMouseEnter={that.tool_icon_mouse_in.bind(that,index)}  onMouseLeave={that.tool_icon_mouse_out.bind(that,index)} className={index!=0?"jkw-icon-40 jkw-point-view margin-top-40":"jkw-icon-40 jkw-point-view"}  />
               }else if(index==3){
                    return <Popover content={content} placement="left"  title={null}><div  onMouseEnter={that.tool_icon_mouse_in.bind(that,index)}  onMouseLeave={that.tool_icon_mouse_out.bind(that,index)} className='jizuo-heat-icon-view  margin-top-40 jkw-point-view'>{that.state.jizuo_heat_status}°</div></Popover>
                }
               return <Popover content={content} placement="left"  title={null}><img src={"/assets/wheel_module/icon_"+cur+".png"}  className={index!=0?"jkw-icon-40 jkw-point-view margin-top-40":"jkw-icon-40 jkw-point-view"}  /></Popover>
           }
         })




        //  获取官方推荐
        let map_setting_gov_view=that.state.map_setting_gov_data.map((item,index)=>{
            if(that.state.map_setting_gov_index==index||that.state.map_setting_gov_select_index==index){
                
                return <div className='jkw-quick_setting-row-active-view' onMouseEnter={that.change_map_setting_index.bind(that,index,1,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,1,0)} onClick={that.change_map_setting_select.bind(that,1,index)} >
                <div className='jkw-quick_setting-row-active-wrap-view'>{item.keyboardName}</div>
            </div>
            }
            return <div className='jkw-quick_setting-row-view jkw-font-2' onMouseEnter={that.change_map_setting_index.bind(that,index,1,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,1,0)} onClick={that.change_map_setting_select.bind(that,1,index)} >{item.keyboardName}</div>
        })

        // 获取一键设置模式
        let map_setting_custom_view=that.state.map_setting_custom_data.map((item,index)=>{
            if(that.state.map_setting_custom_index==index||that.state.map_setting_custom_select_index==index){
                return <div className='jkw-quick_setting-row-active-view' onMouseEnter={that.change_map_setting_index.bind(that,index,2,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,2,0)}  onClick={that.change_map_setting_select.bind(that,2,index)}>
                <div className='jkw-quick_setting-row-active-wrap-view'>{item.name}</div>
                <img src="/assets/map-setting-del-icon.png" className='jkw-map-del-icon-view'  onClick={that.map_setting_custom_del.bind(that,index)}/>
            </div>
            }
            
            return <div className='jkw-quick_setting-row-view jkw-font-2' onMouseEnter={that.change_map_setting_index.bind(that,index,2,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,2,0)}  onClick={that.change_map_setting_select.bind(that,2,index)}>{item.name}</div>

        })
        let map_setting_center_view=null;
        //  一键设置  缺省处理
        if((that.state.map_setting_gov_data.length==0&&that.state.setting_mode==1)||(that.state.map_setting_custom_data.length==0&&that.state.setting_mode==2)){
            map_setting_center_view=<div className='jkw-quick_setting-center-view flex-center'>
                <img src="/assets/display_none.png" className='jkw-display-none-icon-view' />
            </div>
        }else if(that.state.setting_mode==2){
            map_setting_center_view=<div className='jkw-quick_setting-center-view'>
            {map_setting_custom_view}
            </div>
        }else{
            map_setting_center_view=<div className='jkw-quick_setting-center-view'>
            {map_setting_gov_view}
            </div>
        }



        let map_setting_btn_view=null;
        if(that.state.setting_mode==1){
            if(that.state.map_setting_gov_select_index==-1&&that.state.map_setting_gov_index==-1){
                map_setting_btn_view=<div className="jkw-quick_setting-down-view opacity-5"   >
                <div className='jkw-quick_setting-down-mode2-view' >
                    应用模式
                </div>
            </div>
            }else{
                map_setting_btn_view=<div className="jkw-quick_setting-down-view click-style"   >
                <div className='jkw-quick_setting-down-mode2-view' onClick={that.map_setting_apply.bind(that)}>
                    应用模式
                </div>
            </div>
            }
            
        }else if(that.state.setting_mode==2){
            //自定义模式
            if(that.state.map_setting_custom_index==-1&&that.state.map_setting_custom_select_index==-1){
                map_setting_btn_view=<div className='jkw-quick_setting-down-view'>
                    <div className='jkw-quick_setting-down-mode2-view click-style' onClick={that.custom_rename_modal_show.bind(that,1)}>
                        新增一键设置
                    </div>
                </div> 
                
            }else{
                map_setting_btn_view=<div className='jkw-quick_setting-down-view'>
                <div className='jkw-quick_setting-down-mode3-view' onClick={that.map_setting_custom_apply.bind(that)}>应用模式</div>
                <div className='jkw-quick_setting-down-mode3-view' onClick={that.custom_rename_modal_show.bind(that,2)}>重新命名</div>
                <div className='jkw-quick_setting-down-mode4-view' onClick={that.map_setting_custom_save.bind(that)}>保存设置</div>
            </div> 
            }
            
        }
   
return (
    <div  className="jkw-wheel-view">
        {/* 设备未连接 */}
        <div className='jkw-wheel-none-view' hidden={that.state.wheel_connect_status}></div>
        <div className='jkw-wheel-none-info-view' hidden={that.state.wheel_connect_status}>
            <div className='jkw-wheel-none-hint-view'>
                <img src={"/assets/warn_icon.png"}   className="jkw-icon-16 margin-right-6"/>
                <text className='jkw-wheel-font-1'>方向盘未连接</text>
            </div>
        </div>
      
      <div  className='jkw-left-view'>
        {/* 一键设置模式 */}
        <div className='jkw-quick_setting-view'>
            
            <div className='jkw-quick_setting-top-view'>
                <div  className='jkw-quick_setting-top-left-view'>
                    <text className='jkw-font-1'>一键设置模式</text>
                    <img src="/assets/hint_icon.png" className="jkw-icon-13 jkw-point-view margin-left-6"/>
                </div>
                <div  className={that.state.setting_mode==1?'jkw-quick_setting-top-right-view':'jkw-quick_setting-top-right2-view'}>
                    <div className= {that.state.setting_mode==1?'jkw-quick_setting-top-row-view':'jkw-quick_setting-top-row-active-view'} onClick={that.change_setting_map.bind(that,2)}>自定义</div>
                    <div className={that.state.setting_mode==2?'jkw-quick_setting-top-row-view':'jkw-quick_setting-top-row-active-view'}   onClick={that.change_setting_map.bind(that,1)} >官方推荐</div>
                </div>
            </div>
           
            {map_setting_center_view}
          

            {map_setting_btn_view}
           
        </div>

        {/* 按键设置 */}
        <div  className='jkw-mapping-view'>

            <canvas id="pxn_map" hidden={that.state.wheel_active_status==0} />

             <div className='jkw-mapping-top-view'>
                {/* 第一层 */}
                <div className={wheel_active_status==1?'jkw-mapping-row-active-view margin-right-29':'jkw-mapping-row-view margin-right-29'}  onMouseEnter={that.change_map_active_cur.bind(that,1,1)} onMouseLeave={that.change_map_active_cur.bind(that,1,0)} >
                    <img src="/assets/wheel_map_icon/icon_1.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                    {wheel_active_status==1?
                    <div className='jkw-mapping-row-wrap-view'>
                         {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                {/* {that.key_display_view(wheel_input_key)} */}
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(1)}
                
                    </div>}
                </div>
                <div className={wheel_active_status==2?'jkw-mapping-row-active-view margin-right-29':'jkw-mapping-row-view margin-right-29'}  onMouseEnter={that.change_map_active_cur.bind(that,2,1)} onMouseLeave={that.change_map_active_cur.bind(that,2,0)} >
                    <div className='jkw-mapping-font-1-view'>Share</div>
                    {wheel_active_status==2?
                    <div className='jkw-mapping-row-wrap-view'>
                         {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(2)}
                    </div>}
                </div>
                <div className={wheel_active_status==3?'jkw-mapping-row-active-view margin-right-29':'jkw-mapping-row-view margin-right-29'}  onMouseEnter={that.change_map_active_cur.bind(that,3,1)} onMouseLeave={that.change_map_active_cur.bind(that,3,0)} >
                    <div className='jkw-mapping-font-1-view'>View</div>
                    {wheel_active_status==3?
                    <div className='jkw-mapping-row-wrap-view'>
                         {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(3)}
                    </div>}
                </div>
                <div className={wheel_active_status==4?'jkw-mapping-row-active-view':'jkw-mapping-row-view'}  onMouseEnter={that.change_map_active_cur.bind(that,4,1)} onMouseLeave={that.change_map_active_cur.bind(that,4,0)} >
                    <div className='jkw-mapping-font-1-view'>Y</div>
                    {wheel_active_status==4?
                    <div className='jkw-mapping-row-wrap-view'>
                         {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(4)}
                    </div>}
                </div>

                {/* 第二层 */}
                <div className={wheel_active_status==5?'jkw-mapping-row-active-view margin-right-29 margin-top-20':'jkw-mapping-row-view margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,5,1)} onMouseLeave={that.change_map_active_cur.bind(that,5,0)} >
        
                    <img src="/assets/wheel_map_icon/icon_2.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                    {wheel_active_status==5?
                    <div className='jkw-mapping-row-wrap-view'>
                         {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(5)}
                     
                    </div>}
                </div>
                <div className={wheel_active_status==6?'jkw-mapping-row-active-view margin-right-29 margin-top-20':'jkw-mapping-row-view margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,6,1)} onMouseLeave={that.change_map_active_cur.bind(that,6,0)} >
                    <div className='jkw-mapping-font-1-view'>Menu</div>
                    {wheel_active_status==6?
                    <div className='jkw-mapping-row-wrap-view'>
                         {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                         
                         {that.map_display_view(6)}
                    </div>}
                </div>
                <div className={wheel_active_status==7?'jkw-mapping-row-active-view margin-right-29 margin-top-20':'jkw-mapping-row-view margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,7,1)} onMouseLeave={that.change_map_active_cur.bind(that,7,0)} >
                    <div className='jkw-mapping-font-1-view'>Home</div>
                    {wheel_active_status==7?
                    <div className='jkw-mapping-row-wrap-view'>
                         {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(7)}
            
                        
                    </div>}
                </div>
                <div className={wheel_active_status==8?'jkw-mapping-row-active-view  margin-top-20':'jkw-mapping-row-view margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,8,1)} onMouseLeave={that.change_map_active_cur.bind(that,8,0)} >
                    <div className='jkw-mapping-font-1-view'>X</div>
                    {wheel_active_status==8?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(8)}
                    </div>}
                </div>

             </div>

             {/* 设备数据信息展示 */}
             <div className={wheel_active_status>=9&&wheel_active_status<=16?'jkw-mapping-center-view ':'jkw-mapping-center-view'}>
                    <div className={wheel_active_status>=9&&wheel_active_status<=12?"jkw-mapping-center-left-view z-index-99":"jkw-mapping-center-left-view"}>
                        <div className='jkw-mappint-center-wrap-top-view'>
                            <div className={wheel_active_status==9?'jkw-mapping-row-active-view ':'jkw-mapping-row-view '}  onMouseEnter={that.change_map_active_cur.bind(that,9,1)} onMouseLeave={that.change_map_active_cur.bind(that,9,0)} >
                                <img src="/assets/wheel_map_icon/icon_3.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                                {wheel_active_status==9?
                                <div className='jkw-mapping-row-wrap-view'>
                                     {wheel_input_refresh_visible==1?
                                        <div className='jkw-mapping-font-4-view'>
                                            <div className='jkw-mapping-font-wrap-view'>
                                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                                
                                            </div>
                                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                            </div>
                                            
                                        </div>:
                                        <div className='jkw-mapping-font-5-view'>
                                            <div className='jkw-mapping-font-wrap2-view'>
                                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                                
                                            </div>
                                        </div>
                                        }
                                </div>:
                                <div className='jkw-mapping-row-wrap-view'>
                                    
                                    {that.map_display_view(9)}
                                </div>}
                            </div>
                            <div className={wheel_active_status==10?'jkw-mapping-row-active-view margin-top-20':'jkw-mapping-row-view margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,10,1)} onMouseLeave={that.change_map_active_cur.bind(that,10,0)} >
                                <img src="/assets/wheel_map_icon/icon_4.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                                {wheel_active_status==10?
                                <div className='jkw-mapping-row-wrap-view'>
                                     {wheel_input_refresh_visible==1?
                                        <div className='jkw-mapping-font-4-view'>
                                            <div className='jkw-mapping-font-wrap-view'>
                                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                                
                                            </div>
                                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                            </div>
                                            
                                        </div>:
                                        <div className='jkw-mapping-font-5-view'>
                                            <div className='jkw-mapping-font-wrap2-view'>
                                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                                
                                            </div>
                                        </div>
                                        }
                                </div>:
                                <div className='jkw-mapping-row-wrap-view'>
                                    {that.map_display_view(10)}
                                </div>}
                            </div>
                        </div>
                        <div className='jkw-mappint-center-wrap-down-view'>
                            <div className={wheel_active_status==11?'jkw-mapping-row-active-view ':'jkw-mapping-row-view '}  onMouseEnter={that.change_map_active_cur.bind(that,11,1)} onMouseLeave={that.change_map_active_cur.bind(that,11,0)} >
                                <div className='jkw-mapping-font-3-view'><text>左上</text> <text>拨片</text></div>
                                {wheel_active_status==11?
                                <div className='jkw-mapping-row-wrap-view'>
                                    {wheel_input_refresh_visible==1?
                                        <div className='jkw-mapping-font-4-view'>
                                            <div className='jkw-mapping-font-wrap-view'>
                                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                                
                                            </div>
                                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                            </div>
                                            
                                        </div>:
                                        <div className='jkw-mapping-font-5-view'>
                                            <div className='jkw-mapping-font-wrap2-view'>
                                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                                
                                            </div>
                                        </div>
                                        }
                                </div>:
                                <div className='jkw-mapping-row-wrap-view'>
                                    {that.map_display_view(11)}
                                   
                                </div>}
                            </div>
                            <div className={wheel_active_status==12?'jkw-mapping-row-active-view margin-top-20':'jkw-mapping-row-view margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,12,1)} onMouseLeave={that.change_map_active_cur.bind(that,12,0)} >
                                <div className='jkw-mapping-font-3-view'><text>左下</text> <text>拨片</text></div>
                                {wheel_active_status==12?
                                <div className='jkw-mapping-row-wrap-view'>
                                    {wheel_input_refresh_visible==1?
                                        <div className='jkw-mapping-font-4-view'>
                                            <div className='jkw-mapping-font-wrap-view'>
                                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                                
                                            </div>
                                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                            </div>
                                            
                                        </div>:
                                        <div className='jkw-mapping-font-5-view'>
                                            <div className='jkw-mapping-font-wrap2-view'>
                                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                                
                                            </div>
                                        </div>
                                        }
                                </div>:
                                <div className='jkw-mapping-row-wrap-view'>
                                    {that.map_display_view(12)}
                                </div>}
                            </div>
                        </div>
                    </div>


                    {/* 方向盘核心数据 */}
                    <div className='jkw-mapping-center-main-view'>
                        <img src={that.state.wheel_active_status==0?"/assets/device_wheel_icon.png":"/assets/device_wheel_icon_active.png"} className='jkw-wheel-icon-view'/>  
                        <div className='jkw-wheel-map-list-window-view'>

                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-2' id="jkw-map-pos-2"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-3' id="jkw-map-pos-3"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-4' id="jkw-map-pos-4"></div>
                            
                            {/* 上下左右 menu*/}
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-6' id="jkw-map-pos-6"></div>
                            
                            <div className='jkw-wheel-map-icon-view-4 jkw-map-pos-7'  id="jkw-map-pos-7"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-8' id="jkw-map-pos-8"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-11' id="jkw-map-pos-11"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-12'  id="jkw-map-pos-12"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-13' id="jkw-map-pos-13"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-14'  id="jkw-map-pos-14"></div>

                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-15' id="jkw-map-pos-15"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-16' id="jkw-map-pos-16"></div>

                            {/* 17,18,22 */}
                            <div className='jkw-wheel-map-icon-view-3 jkw-map-pos-17' id="jkw-map-pos-17"></div>
                            {/* 19,20,23 */}
                            <div className='jkw-wheel-map-icon-view-3 jkw-map-pos-19' id="jkw-map-pos-19"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-21' id="jkw-map-pos-21"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-24' id="jkw-map-pos-24"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-25' id="jkw-map-pos-25"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-26' id="jkw-map-pos-26"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-27' id="jkw-map-pos-27"></div>
                            <div className='jkw-wheel-map-icon-view-1 jkw-map-pos-28' id="jkw-map-pos-28"></div>
                        </div>    
                    </div>

                    <div className={wheel_active_status>=13&&wheel_active_status<=16?"jkw-mapping-center-right-view  z-index-99":"jkw-mapping-center-right-view"}>
                        <div className='jkw-mappint-cente-wrap-top-view'>
                            <div className='jkw-mappint-center-wrap-top-view'>
                            <div className={wheel_active_status==13?'jkw-mapping-row-active-view ':'jkw-mapping-row-view '}  onMouseEnter={that.change_map_active_cur.bind(that,13,1)} onMouseLeave={that.change_map_active_cur.bind(that,13,0)} >
                                    <div className='jkw-mapping-font-1-view'>B</div>
                                    {wheel_active_status==13?
                                    <div className='jkw-mapping-row-wrap-view'>
                                        {wheel_input_refresh_visible==1?
                                        <div className='jkw-mapping-font-4-view'>
                                            <div className='jkw-mapping-font-wrap-view'>
                                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                                
                                            </div>
                                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                            </div>
                                            
                                        </div>:
                                        <div className='jkw-mapping-font-5-view'>
                                            <div className='jkw-mapping-font-wrap2-view'>
                                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                                
                                            </div>
                                        </div>
                                        }
                                    </div>:
                                    <div className='jkw-mapping-row-wrap-view'>
                                        {that.map_display_view(13)}
                                        
                                    </div>}
                                </div>
                                <div className={wheel_active_status==14?'jkw-mapping-row-active-view margin-top-20':'jkw-mapping-row-view margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,14,1)} onMouseLeave={that.change_map_active_cur.bind(that,14,0)} >
                                    <div className='jkw-mapping-font-1-view'>A</div>
                                    {wheel_active_status==14?
                                    <div className='jkw-mapping-row-wrap-view'>
                                       {wheel_input_refresh_visible==1?
                                        <div className='jkw-mapping-font-4-view'>
                                            <div className='jkw-mapping-font-wrap-view'>
                                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                                
                                            </div>
                                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                            </div>
                                            
                                        </div>:
                                        <div className='jkw-mapping-font-5-view'>
                                            <div className='jkw-mapping-font-wrap2-view'>
                                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                                
                                            </div>
                                        </div>
                                        }
                                    </div>:
                                    <div className='jkw-mapping-row-wrap-view'>
                                        
                                        {that.map_display_view(14)}
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className='jkw-mappint-center-wrap-down-view'>
                            <div className={wheel_active_status==15?'jkw-mapping-row-active-view ':'jkw-mapping-row-view '}  onMouseEnter={that.change_map_active_cur.bind(that,15,1)} onMouseLeave={that.change_map_active_cur.bind(that,15,0)} >
                                <div className='jkw-mapping-font-3-view'><text>右下</text> <text>拨片</text></div>
                                {wheel_active_status==15?
                                <div className='jkw-mapping-row-wrap-view'>
                                    {wheel_input_refresh_visible==1?
                                        <div className='jkw-mapping-font-4-view'>
                                            <div className='jkw-mapping-font-wrap-view'>
                                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                                
                                            </div>
                                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                            </div>
                                            
                                        </div>:
                                        <div className='jkw-mapping-font-5-view'>
                                            <div className='jkw-mapping-font-wrap2-view'>
                                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                                
                                            </div>
                                        </div>
                                        }
                                </div>:
                                <div className='jkw-mapping-row-wrap-view'>
                                    
                                    {that.map_display_view(15)}
                                </div>}
                            </div>
                            <div className={wheel_active_status==16?'jkw-mapping-row-active-view margin-top-20':'jkw-mapping-row-view margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,16,1)} onMouseLeave={that.change_map_active_cur.bind(that,16,0)} >
                                <div className='jkw-mapping-font-3-view'><text>右下</text> <text>拨片</text></div>
                                {wheel_active_status==16?
                                <div className='jkw-mapping-row-wrap-view'>
                                   {wheel_input_refresh_visible==1?
                                        <div className='jkw-mapping-font-4-view'>
                                            <div className='jkw-mapping-font-wrap-view'>
                                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                                
                                            </div>
                                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                            </div>
                                            
                                        </div>:
                                        <div className='jkw-mapping-font-5-view'>
                                            <div className='jkw-mapping-font-wrap2-view'>
                                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                                
                                            </div>
                                        </div>
                                        }
                                </div>:
                                <div className='jkw-mapping-row-wrap-view'>
                                    
                                    {that.map_display_view(16)}
                                </div>}
                            </div>
                        </div>
                    </div>
             </div>


             {/* 下部分按键 */}
             <div className='jkw-mapping-down-view'>
                
                <div className={wheel_active_status==17?'jkw-mapping-row-active-view margin-right-29':'jkw-mapping-row-view margin-right-29'}  onMouseEnter={that.change_map_active_cur.bind(that,17,1)} onMouseLeave={that.change_map_active_cur.bind(that,17,0)} >
                    <img src="/assets/wheel_map_icon/icon_5.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                    {wheel_active_status==17?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(17)}
                    </div>}
                    
                </div>
                <div className={wheel_active_status==18?'jkw-mapping-row-active-view margin-right-29':'jkw-mapping-row-view margin-right-29'}  onMouseEnter={that.change_map_active_cur.bind(that,18,1)} onMouseLeave={that.change_map_active_cur.bind(that,18,0)} >
                    <img src="/assets/wheel_map_icon/icon_7.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                    {wheel_active_status==18?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(18)}
                    </div>}
                </div>
                <div className={wheel_active_status==19?'jkw-mapping-row-active-view margin-right-29 ':'jkw-mapping-row-view margin-right-29'}  onMouseEnter={that.change_map_active_cur.bind(that,19,1)} onMouseLeave={that.change_map_active_cur.bind(that,19,0)} >
                    <img src="/assets/wheel_map_icon/icon_5.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                    {wheel_active_status==19?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(19)}
              
                    </div>}
                </div>
                <div className={wheel_active_status==20?'jkw-mapping-row-active-view ':'jkw-mapping-row-view '}  onMouseEnter={that.change_map_active_cur.bind(that,20,1)} onMouseLeave={that.change_map_active_cur.bind(that,20,0)} >
                    <img src="/assets/wheel_map_icon/icon_7.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                    {wheel_active_status==20?
                    <div className='jkw-mapping-row-wrap-view'>
                       {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(20)}
                    
                    </div>}
                </div>

                {/* 第二层 */}
                <div className={wheel_active_status==21?'jkw-mapping-row-active-view margin-right-29 margin-top-20':'jkw-mapping-row-view margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,21,1)} onMouseLeave={that.change_map_active_cur.bind(that,21,0)} >
        
                    <div className='jkw-mapping-font-1-view'>L1</div>
                    {wheel_active_status==21?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        

                        {that.map_display_view(21)}
                    </div>}
                </div>
                <div className={wheel_active_status==22?'jkw-mapping-row-active-view margin-right-29 margin-top-20':'jkw-mapping-row-view margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,22,1)} onMouseLeave={that.change_map_active_cur.bind(that,22,0)} >
                    <img src="/assets/wheel_map_icon/icon_6.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                    {wheel_active_status==22?
                    <div className='jkw-mapping-row-wrap-view'>
                       {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(22)}
                  
                    </div>}
                </div>
                <div className={wheel_active_status==23?'jkw-mapping-row-active-view margin-right-29 margin-top-20':'jkw-mapping-row-view margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,23,1)} onMouseLeave={that.change_map_active_cur.bind(that,23,0)} >
                    <img src="/assets/wheel_map_icon/icon_6.png" className='jkw-icon-24 margin-left-12  margin-right-12'/>
                    {wheel_active_status==23?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(23)}
                    </div>}
                </div>
                <div className={wheel_active_status==24?'jkw-mapping-row-active-view  margin-top-20':'jkw-mapping-row-view margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,24,1)} onMouseLeave={that.change_map_active_cur.bind(that,24,0)} >
                    <div className='jkw-mapping-font-1-view'>R1</div>
                    {wheel_active_status==24?
                    <div className='jkw-mapping-row-wrap-view'>
                       {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }

                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(24)}
                    </div>}
                </div>

                {/* 第三层 */}
                <div className={wheel_active_status==25?'jkw-mapping-row-active-view margin-right-29 margin-top-20':'jkw-mapping-row-view margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,25,1)} onMouseLeave={that.change_map_active_cur.bind(that,25,0)} >
                    <div className='jkw-mapping-font-1-view'>L2</div>
                    {wheel_active_status==25?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(25)}
                    </div>}
                </div>
                <div className={wheel_active_status==26?'jkw-mapping-row-active-view margin-right-29 margin-top-20':'jkw-mapping-row-view margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,26,1)} onMouseLeave={that.change_map_active_cur.bind(that,26,0)} >
                    <div className='jkw-mapping-font-1-view'>L3</div>
                    {wheel_active_status==26?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        
                        {that.map_display_view(26)}
                    </div>}
                </div>
                <div className={wheel_active_status==27?'jkw-mapping-row-active-view margin-right-29 margin-top-20':'jkw-mapping-row-view margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,27,1)} onMouseLeave={that.change_map_active_cur.bind(that,27,0)} >
                    <div className='jkw-mapping-font-1-view'>R3</div>
                    {wheel_active_status==27?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
        
                         {that.map_display_view(27)}
                    </div>}
                </div>
                <div className={wheel_active_status==28?'jkw-mapping-row-active-view  margin-top-20':'jkw-mapping-row-view margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,28,1)} onMouseLeave={that.change_map_active_cur.bind(that,28,0)} >
                    <div className='jkw-mapping-font-1-view'>R2</div>
                    {wheel_active_status==28?
                    <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                            
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                                
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                    
                        {that.map_display_view(28)}
                    </div>}
                </div>
             </div>

             {/* led 相关 */}
             <div className='jkw-led-view'  >
                    <div className="jkw-hexcolorpicker-wrap-view" id="jkw-hexcolorpicker-wrap-view">
                        <HexColorPicker onMouseLeave={that.confirm_color_picker.bind(that)}   color={that.state.current_color_value} onChange={that.change_color_value.bind(that)} hidden={!that.state.color_picker_visible}  className="jkw-hexcolorpicker-view" />  
                    </div>
             
                 <div className='jkw-led-list-view'>
                    <div className='jkw-led-out-view' id="jkw-led-cal" onClick={that.show_color_picker.bind(that,0)}  ><div className='jkw-led-inner-view' style={{backgroundColor:led_color_data[0]}}></div> </div>
                    <div className='jkw-led-out-view' id="jkw-led-cal2" onClick={that.show_color_picker.bind(that,1)}><div className='jkw-led-inner-view' style={{backgroundColor:led_color_data[1]}}></div> </div>
                    <div className='jkw-led-out-view'  onClick={that.show_color_picker.bind(that,2)}><div className='jkw-led-inner-view' style={{backgroundColor:led_color_data[2]}}></div> </div>
                    <div className='jkw-led-out-view' onClick={that.show_color_picker.bind(that,3)}><div className='jkw-led-inner-view' style={{backgroundColor:led_color_data[3]}}></div> </div>
                    <div className='jkw-led-out-view' onClick={that.show_color_picker.bind(that,4)}><div className='jkw-led-inner-view' style={{backgroundColor:led_color_data[4]}}></div> </div>
                    <div className='jkw-led-out-view' onClick={that.show_color_picker.bind(that,5)}><div className='jkw-led-inner-view' style={{backgroundColor:led_color_data[5]}}></div> </div>
                    <div className='jkw-led-out-view' onClick={that.show_color_picker.bind(that,6)}><div className='jkw-led-inner-view' style={{backgroundColor:led_color_data[6]}}></div> </div>
                    <div className='jkw-led-out-view' onClick={that.show_color_picker.bind(that,7)}><div className='jkw-led-inner-view' style={{backgroundColor:led_color_data[7]}}></div> </div>
                    <div className='jkw-led-out-view' onClick={that.show_color_picker.bind(that,8)}><div className='jkw-led-inner-view' style={{backgroundColor:led_color_data[8]}}></div> </div>
                    <div className='jkw-led-out-view' onClick={that.show_color_picker.bind(that,9)}><div className='jkw-led-inner-view margin-right-none' style={{backgroundColor:led_color_data[9]}}></div> </div>
                    
                 </div>
                 <div className='jkw-led-control-view'>
                     <div className={that.state.led_bt_status==1?"jkw-led-bt-active-view":"jkw-led-bt-view"} onClick={that.change_led_bt.bind(that,1)}>转速随动</div>
                    <div className={that.state.led_bt_status==2?"jkw-led-bt-active-view":"jkw-led-bt-view"}   onClick={that.change_led_bt.bind(that,2)}>灯光关闭</div>
                    <div className={that.state.led_bt_status==3?"jkw-led-bt-active-view":"jkw-led-bt-view"}   onClick={that.change_led_bt.bind(that,3)}>默认常亮</div>
                 </div>   
                            
             </div>


        </div>
        
      </div>



      
      {/* 工具栏 */}
      <div  className='jkw-right-view'>
          {tool_view}
      </div>
        

    
    {/*修改自定义模式名称  */}
      <Modal
          title={that.state.custom_rename_modal_flag==1?"新增自定义模式":"模式命名"}
          visible={that.state.custom_rename_modal_visible}
          onOk={that.state.custom_rename_modal_flag==1?that.map_setting_custom_add:that.map_setting_custom_rename}
          onCancel={that.custom_rename_modal_hidden}
          centered={true}
          okText="提交"
          cancelText="取消"
        //   maskClosable={false}
          width={400}
        >
            <Input placeholder="请输入" value={this.state.custom_rename_key} onChange={this.change_custom_rename_key.bind(that)}  style={{  width: '100%' }} />
        
       </Modal>

    </div>
);
  
    }
}


const mapStateToProps = state => {

    return {
        p12_lite_data:state.p12_lite_data,
    }
};
export default connect(mapStateToProps)(Wheel)




// 01000102030405060708090A0B0C0D0E0F10112425262728292A2B2C2D007800007800007800007800007800007800007800007800007800007800