
import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as CONFIG from '../../config/config';
import * as FN  from '../../utils/index'
import * as PY from '../../utils/pywebview'
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
    Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Popover,Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
  } from 'antd';
  import { Chart,registerInteraction, registerAction, Action } from '@antv/g2';
  import * as FX from '../../redux/action'
// *************************************图表拖拽类 begin*************************/
class DragPoint extends Action {
    // Action 开始，不等同于 拖拽开始，需要判定移动的范围
     starting = false;
    // 拖拽开始
     dragStart = false;
    // 开始的节点
     startPoint=null;
     target=null;
  
    start() {
      this.starting = true;
      this.startPoint = this.context.getCurrentPoint();
      const { target } = this.context.event;
      // 只对 point 起拖拽作用
      if (String(target.get('name')) === 'element,point') {
        this.target = target;
      }
    }
  
    drag() {
      if (!this.startPoint || !this.target) return;
      const current = this.context.getCurrentPoint();
      const { view } = this.context;
      const event = this.context.event;
      if (!this.dragStart) {
        // 只能上下移动
        if (Math.abs(current.y - this.startPoint.y) >=1||Math.abs(current.x - this.startPoint.x) >=1) {
          view.emit('dragstart', {
            target: event.target,
            x: event.x,
            y: event.y,
          });
          this.dragStart = true;
        }
      } else {
        view.emit('drag', {
          target: event.target,
          x: event.x,
          y: event.y,
        });
      }
      const { shape } = this.target.get('element');
      shape.attr('y', current.y);
      shape.attr('x', current.x);
    }
  
    end() {
      if (this.dragStart) {
        const view = this.context.view;
        const event = this.context.event;
  
        const { shape } = this.target.get('element');
        const scale = view.getScalesByDim('y')['y'];
        const coordinate = view.getCoordinate();
        const changedValue = scale.invert(coordinate.invertPoint({ x: 0, y: shape.attr('y') }).y);
        // const changedValue2 = scale.invert(coordinate.invertPoint({ x: shape.attr('x'), y: 0 }).x);
        const origin = this.target.get('origin');
  
        view.emit('dragend', {
          target: event.target,
          x: event.x,
          y: event.y,
          changedDatum: { ...origin.data, y: changedValue},
        });
      }
      this.target = null;
      this.starting = false;
      this.dragStart = false;
    }
  }
  
  registerAction('drag-point', DragPoint);
  registerInteraction('custom-interaction', {
    start: [{ trigger: 'element:mousedown', action: 'drag-point:start' }],
    processing: [{ trigger: 'plot:mousemove', action: 'drag-point:drag' }],
    end: [{ trigger: 'plot:mouseup', action: 'drag-point:end' }],
  });


// *************************************图表拖拽类 end*************************/




  const { confirm } = Modal;
class Footrest extends React.Component {
   
    state = {  
        char_1:undefined,
        char_2:undefined,
        char_3:undefined,
        foot_pedal_state:0,
        /********************一键设置相关  begin********************** */
        map_setting_gov_data:[],
        map_setting_custom_data:[],
        map_setting_custom_index:-1,
        map_setting_gov_index:-1,
        map_setting_gov_select_index:-1,
        map_setting_custom_select_index:-1,
        setting_mode:1,  //1-官方推荐 ,2-自定义
        custom_rename_modal_visible:false,
        custom_rename_key:undefined, //自定义名称输入值
        custom_rename_modal_flag:1, //1-新增,2-修改
        /********************一键设置相关  end********************** */

        youmen_value:80,
        youmen_value_old:-1,
        shache_value:80,
        shache_value_old:-1,
        lihe_value:80,
        lihe_value_old:-1,


        wheel_active_status:0,  //0-未选中,1-28选中
        wheel_input_refresh_visible:1,//0-隐藏,1-显示
        wheel_input_hex:0x00, //协议16进制值
        wheel_input_key:'', //当前输入值
        wheel_map_hex_pre:[],  //当前选中模式的hex值
        wheel_map_key:['-1','离合', '刹车', '油门'],
        wheel_map_key_tpl:['-1','离合', '刹车', '油门' ],
        wheel_map_hex_tpl:[0, 0x16, 0x15, 0x14],

        lihe_coordinate_hex: [51, 3, 51, 51, 102, 6, 102, 102, 153, 9, 153, 153, 204, 12, 204, 204],
        shache_coordinate_hex: [51, 3, 51, 51, 102, 6, 102, 102, 153, 9, 153, 153, 204, 12, 204, 204],
        youmen_coordinate_hex: [51, 3, 51, 51, 102, 6, 102, 102, 153, 9, 153, 153, 204, 12, 204, 204],
        lihe_coordinate_data:[{id:0,x:819,y:13107},{id:1,x:1638,y:26214},{id:2,x:2457,y:39321},{id:3,x:3276,y:52428}],
        shache_coordinate_data:[{id:0,x:819,y:13107},{id:1,x:1638,y:26214},{id:2,x:2457,y:39321},{id:3,x:3276,y:52428}],
        youmen_coordinate_data:[{id:0,x:819,y:13107},{id:1,x:1638,y:26214},{id:2,x:2457,y:39321},{id:3,x:3276,y:52428}],


        preview_lihe_coordinate_hex:[],
        preview_shache_coordinate_hex:[],
        preview_youmen_coordinate_hex:[],

        preview_lihe_coordinate_data:[],
        preview_shache_coordinate_data:[],
        preview_youmen_coordinate_data:[],

        default_coordinate_data:[{id:0,x:819,y:13107},{id:1,x:1638,y:26214},{id:2,x:2457,y:39321},{id:3,x:3276,y:52428}],
        default_coordinate_hex: [51, 3, 51, 51, 102, 6, 102, 102, 153, 9, 153, 153, 204, 12, 204, 204]

    }
 // 组件卸载
 componentWillUnmount(){
    let that=this;
    if(Window.KEYBOARD_TIMER){
        clearInterval(Window.KEYBOARD_TIMER)
    }
    // 清除记录
    window.footrest_char_1=undefined;
    window.footrest_char_2=undefined;
    window.footrest_char_3=undefined;
}
componentDidMount() {
    let that=this
    that.pc_data_sync_from_device(that.props.p12_lite_data)
    that.init_data();

    // 获取缓存数据  断开后首次连接成功
    let handle=PY.pxn_user_config_get("footrest")
    if(handle){
        handle.then((rst_data)=>{
            that.setState({
                map_setting_custom_data:rst_data.data
            })

        })
    }


}
// 接受path变化
componentWillReceiveProps(nextprops,nextcontent){
    let that=this;
    let p12_lite_data=nextprops.p12_lite_data
    that.pc_data_sync_from_device(p12_lite_data)


  }
//设备数据同步上位机
pc_data_sync_from_device(p12_lite_data){
    let that=this;

    if(!that.state.foot_pedal_state&&p12_lite_data.foot_pedal_state){

        that.setState({

            wheel_map_key:p12_lite_data.footrest_map_key,
            wheel_map_hex_pre:p12_lite_data.footrest_map_hex,   //键盘映射16进制
        })
    }else if(p12_lite_data.foot_pedal_state==1&&p12_lite_data.pxn_update_status==1){
        that.setState({

            wheel_map_key:p12_lite_data.footrest_map_key,
            wheel_map_hex_pre:p12_lite_data.footrest_map_hex,   //键盘映射16进制
            
        })
    }
    // 计算坐标值
    let lihe_coordinate_data=[]
    let youmen_coordinate_data=[]
    let shache_coordinate_data=[]
    for(let i=0;i<4;i++){
        let tmp={}
        tmp.id=i;
        tmp.x=p12_lite_data.lihe_coordinate_hex[i*4]+p12_lite_data.lihe_coordinate_hex[i*4+1]*256
        tmp.y=p12_lite_data.lihe_coordinate_hex[i*4+2]+p12_lite_data.lihe_coordinate_hex[i*4+3]*256
        lihe_coordinate_data.push(tmp)

        let tmp2={}
        tmp2.id=i;
        tmp2.x=p12_lite_data.shache_coordinate_hex[i*4]+p12_lite_data.shache_coordinate_hex[i*4+1]*256
        tmp2.y=p12_lite_data.shache_coordinate_hex[i*4+2]+p12_lite_data.shache_coordinate_hex[i*4+3]*256
        shache_coordinate_data.push(tmp2)

        let tmp3={}
        tmp3.id=i;
        tmp3.x=p12_lite_data.youmen_coordinate_hex[i*4]+p12_lite_data.youmen_coordinate_hex[i*4+1]*256
        tmp3.y=p12_lite_data.youmen_coordinate_hex[i*4+2]+p12_lite_data.youmen_coordinate_hex[i*4+3]*256
        youmen_coordinate_data.push(tmp3)
    }
    // console.log(lihe_coordinate_data)
    // console.log(shache_coordinate_data)
    // console.log(youmen_coordinate_data)
    that.setState({
        foot_pedal_state:p12_lite_data.foot_pedal_state,
        lihe_value:p12_lite_data.lihe_value,
        shache_value:p12_lite_data.shache_value,
        youmen_value:p12_lite_data.youmen_value,
        lihe_coordinate_hex:p12_lite_data.lihe_coordinate_hex,
        shache_coordinate_hex:p12_lite_data.shache_coordinate_hex,
        youmen_coordinate_hex:p12_lite_data.youmen_coordinate_hex,

        lihe_coordinate_data:lihe_coordinate_data,
        shache_coordinate_data:shache_coordinate_data,
        youmen_coordinate_data:youmen_coordinate_data
    },()=>{
        if(!window.footrest_char_1||p12_lite_data.pxn_update_status==1){
            that.show_footrest_char(1,lihe_coordinate_data)
        }
        if(!window.footrest_char_2||p12_lite_data.pxn_update_status==1){
            that.show_footrest_char(2,shache_coordinate_data)
        }
        if(!window.footrest_char_3||p12_lite_data.pxn_update_status==1){
            that.show_footrest_char(3,youmen_coordinate_data)
        }
    })

    
}
   //显示图表数据
   show_footrest_char(cur,data){

    let that=this;
    let chart;
      if(cur==1){
        if(window.footrest_char_1){
            window.footrest_char_1.clear()
            chart=window.footrest_char_1
        }else{
            chart = new Chart({
                container: 'jkw-footrest-char-'+cur,
                autoFit: true,
                padding: [10, 10, 10, 10],
              });
        }
      }else if(cur==2){
        if(window.footrest_char_2){
            window.footrest_char_2.clear()
            chart=window.footrest_char_2
        }else{
            chart = new Chart({
                container: 'jkw-footrest-char-'+cur,
                autoFit: true,
                padding: [10, 10, 10, 10],
              });
        }
      }else if(cur==3){
        if(window.footrest_char_3){
            window.footrest_char_3.clear()
            chart=window.footrest_char_3
        }else{
            chart = new Chart({
                container: 'jkw-footrest-char-'+cur,
                autoFit: true,
                padding: [10, 10, 10, 10],
              });
        }
      } 
      chart.data(data);
      chart.tooltip(false);
      chart.axis(false);
      chart.area().position('x*y').color('#FF721E');
      chart.line().position('x*y').color('#FF721E');
      chart.point().size(7).position('x*y').color('#FF721E').shape('circle')
      chart.interaction('custom-interaction');
      chart.render();

chart.on('dragend', ({ changedDatum }) => {
        
if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){
   

    let lihe_coordinate_data=that.state.preview_lihe_coordinate_data;
    let shache_coordinate_data=that.state.preview_shache_coordinate_data;
    let  youmen_coordinate_data=that.state.preview_youmen_coordinate_data
    let goal_data;
    if(cur==1){
        goal_data=lihe_coordinate_data
    }else if(cur==2){
        goal_data=shache_coordinate_data
    }else if(cur==3){
        goal_data=youmen_coordinate_data
    } 

    // console.log("触发dragend ", cur)
    // console.log(goal_data)
    // console.log(changedDatum)
    goal_data = goal_data.map((d) => {
        if (d.id == changedDatum.id) {
            
            return changedDatum;
        }
        return d;
    });


    chart.data(goal_data);
    chart.render(true);
    let coordinate_hex=[];
    for(let i=0;i<4;i++){
        let x_1=parseInt(goal_data[i].x%256)
        let x_2=parseInt(goal_data[i].x-x_1)/256
        let y_1=parseInt(goal_data[i].y%256)
        let y_2=parseInt(goal_data[i].y-y_1)/256
        coordinate_hex.push(x_1)
        coordinate_hex.push(x_2)
        coordinate_hex.push(y_1)
        coordinate_hex.push(y_2)
    }
    // 进行更新缓存
    if(cur==1){

        that.setState({
            preview_lihe_coordinate_data:goal_data,
            preview_lihe_coordinate_hex:coordinate_hex
        })

    }else if(cur==2){

        that.setState({
            preview_shache_coordinate_data:goal_data,
            preview_shache_coordinate_hex:coordinate_hex
        })

    }else if(cur==3){

        that.setState({
            preview_youmen_coordinate_data:goal_data,
            preview_youmen_coordinate_hex:coordinate_hex
        })

    } 


}else{
    let lihe_coordinate_data=that.state.lihe_coordinate_data;
    let shache_coordinate_data=that.state.shache_coordinate_data;
    let  youmen_coordinate_data=that.state.youmen_coordinate_data
    let goal_data;
    if(cur==1){
        goal_data=lihe_coordinate_data
    }else if(cur==2){
        goal_data=shache_coordinate_data
    }else if(cur==3){
        goal_data=youmen_coordinate_data
    } 

    // console.log("触发dragend ", cur)
    // console.log(goal_data)
    // console.log(changedDatum)
    goal_data = goal_data.map((d) => {
        if (d.id == changedDatum.id) {
            
            return changedDatum;
        }
        return d;
    });


    chart.data(goal_data);
    chart.render(true);
    let coordinate_hex=[];
    for(let i=0;i<4;i++){
        let x_1=parseInt(goal_data[i].x%256)
        let x_2=parseInt(goal_data[i].x-x_1)/256
        let y_1=parseInt(goal_data[i].y%256)
        let y_2=parseInt(goal_data[i].y-y_1)/256
        coordinate_hex.push(x_1)
        coordinate_hex.push(x_2)
        coordinate_hex.push(y_1)
        coordinate_hex.push(y_2)
    }
    // console.log(cur)
    // console.log(coordinate_hex)

    let p12_lite_data=that.props.p12_lite_data
    // 进行更新缓存
    if(cur==1){
        p12_lite_data.lihe_coordinate_hex=coordinate_hex
        that.setState({
            lihe_coordinate_data:goal_data,
            lihe_coordinate_hex:coordinate_hex
        })

    }else if(cur==2){
        p12_lite_data.shache_coordinate_hex=coordinate_hex
        that.setState({
            shache_coordinate_data:goal_data,
            shache_coordinate_hex:coordinate_hex
        })

    }else if(cur==3){
        p12_lite_data.youmen_coordinate_hex=coordinate_hex
        that.setState({
            youmen_coordinate_data:goal_data,
            youmen_coordinate_hex:coordinate_hex
        })

    } 

    PY.pxn_footrest_coordinate_set(p12_lite_data.lihe_coordinate_hex, p12_lite_data.shache_coordinate_hex, p12_lite_data.youmen_coordinate_hex)
    // // 更新本地数据
    const { dispatch } = that.props;
    dispatch(FX.set_p12_lite_data(p12_lite_data))

}

});
      
      if(cur==1){
        window.footrest_char_1=chart
      }else if(cur==2){
        window.footrest_char_2=chart
      }else if(cur==3){
        window.footrest_char_3=chart
      } 
  }

// 初始化数据
init_data(){
    let that=this;
    FN.ajax({
        url: 'https://api.e-pxn.com.cn/mobile/v1/keyboard/getKeyboard?pageNum=1&pageSize=200&status=1&type=1&driveType=17',
        data: {}
    }).
        then((res) => {
            let rst_data=res.content.list
            let goal_data=[]
            for(let i=0;i<rst_data.length;i++){
                if(rst_data[i].keboardValue.length==200){
                    goal_data.push(rst_data[i])
                }
                
            }
            // goal_data
            that.setState({
                map_setting_gov_data:goal_data
            })

        })
}


/********************一键设置相关  begin********************** */

// 16进制转字符串
pxn_hex_to_str(str,mode=1){
    let that=this
//
    let goal_data=[]
    for(let i=0;i<100;i++){
        goal_data.push(parseInt(str[i*2]+""+str[i*2+1],16))
    }
    
    // 获取键盘映射值 
    let  wheel_map_key=["-1","-1","-1","-1"]
    let  wheel_map_hex=[0,0,0,0];
    wheel_map_hex[3]=goal_data[1]
    wheel_map_hex[2]=goal_data[2]
    wheel_map_hex[1]=goal_data[3]
    wheel_map_key[3]=FN.pxn_map_to_str(goal_data[1])
    wheel_map_key[2]=FN.pxn_map_to_str(goal_data[2])
    wheel_map_key[1]=FN.pxn_map_to_str(goal_data[3])

    //获取坐标值
    let lihe_coordinate_hex=[];
    let shache_coordinate_hex=[];
    let youmen_coordinate_hex=[];

    for(let i=4;i<20;i++){
        lihe_coordinate_hex.push(goal_data[i])
    }
    for(let i=4+32;i<20+32;i++){
        shache_coordinate_hex.push(goal_data[i])
    }
    for(let i=4+64;i<20+64;i++){
        youmen_coordinate_hex.push(goal_data[i])
    }

    // 计算坐标值
    let lihe_coordinate_data=[]
    let youmen_coordinate_data=[]
    let shache_coordinate_data=[]
    for(let i=0;i<4;i++){
        let tmp={}
        tmp.id=i;
        tmp.x=lihe_coordinate_hex[i*4]+lihe_coordinate_hex[i*4+1]*256
        tmp.y=lihe_coordinate_hex[i*4+2]+lihe_coordinate_hex[i*4+3]*256
        lihe_coordinate_data.push(tmp)

        let tmp2={}
        tmp2.id=i;
        tmp2.x=shache_coordinate_hex[i*4]+shache_coordinate_hex[i*4+1]*256
        tmp2.y=shache_coordinate_hex[i*4+2]+shache_coordinate_hex[i*4+3]*256
        shache_coordinate_data.push(tmp2)

        let tmp3={}
        tmp3.id=i;
        tmp3.x=youmen_coordinate_hex[i*4]+youmen_coordinate_hex[i*4+1]*256
        tmp3.y=youmen_coordinate_hex[i*4+2]+youmen_coordinate_hex[i*4+3]*256
        youmen_coordinate_data.push(tmp3)
    }

    that.setState({
        preview_wheel_map_hex_pre:wheel_map_hex,
        preview_wheel_map_key:wheel_map_key,

        preview_lihe_coordinate_hex:lihe_coordinate_hex,
        preview_shache_coordinate_hex:shache_coordinate_hex,
        preview_youmen_coordinate_hex:youmen_coordinate_hex,

        preview_lihe_coordinate_data:lihe_coordinate_data,
        preview_shache_coordinate_data:shache_coordinate_data,
        preview_youmen_coordinate_data:youmen_coordinate_data
    },()=>{

            that.show_footrest_char(1,lihe_coordinate_data)
            that.show_footrest_char(2,shache_coordinate_data)
           that.show_footrest_char(3,youmen_coordinate_data)

    })
   
    if(mode==2){
        message.success("操作成功!")
        // 触发应用到设备
        that.setState({
            wheel_map_key:wheel_map_key,
            wheel_map_hex_pre:wheel_map_hex,
            lihe_coordinate_hex: lihe_coordinate_hex,
            shache_coordinate_hex: shache_coordinate_hex,
            youmen_coordinate_hex: youmen_coordinate_hex,

            lihe_coordinate_data: lihe_coordinate_data,
            shache_coordinate_data: shache_coordinate_data,
            youmen_coordinate_data: youmen_coordinate_data
        })
        
        // 设置键盘值
        PY.pxn_footrest_map_set_one(1,wheel_map_hex[1])
        PY.pxn_footrest_map_set_one(2,wheel_map_hex[2])
        PY.pxn_footrest_map_set_one(3,wheel_map_hex[3])
        // 设置坐标数据
        PY.pxn_footrest_coordinate_set(lihe_coordinate_hex, shache_coordinate_hex, youmen_coordinate_hex)


        // PY.pxn_shift_lever_map_set(goal_data)

        // 更新本地数据
        let p12_lite_data=that.props.p12_lite_data
        p12_lite_data.lihe_coordinate_hex=lihe_coordinate_hex
        p12_lite_data.shache_coordinate_hex=shache_coordinate_hex
        p12_lite_data.youmen_coordinate_hex=youmen_coordinate_hex
        p12_lite_data.footrest_map_key=wheel_map_key
        p12_lite_data.footrest_map_hex=wheel_map_hex
    

        const { dispatch } = that.props;
        dispatch(FX.set_p12_lite_data(p12_lite_data))
  
        
    }

    // goal_data[1]=goal_data[1]*256+goal_data[0]
    // goal_data.push(0)
    // that.setState({
    //     preview_jizuo_param_data:goal_data,
    // })
    // if(mode==2){
    //     message.success("操作成功!")
    //     // 触发应用到设备
    //     that.setState({
    //         jizuo_param_data:goal_data
    //     })
        
    //     PY.pxn_jizuo_param_set(goal_data)
    //     // 更新本地数据
    //     let p12_lite_data=that.props.p12_lite_data
    //     p12_lite_data.jizuo_param_data=goal_data
    //     const { dispatch } = that.props;
    //     dispatch(FX.set_p12_lite_data(p12_lite_data))
    // }

}
// 监听模式切换
change_setting_map=(cur)=>{
let that=this;
that.setState({
    setting_mode:cur,
    map_setting_gov_select_index:-1,
            map_setting_custom_select_index:-1,
            map_setting_gov_index:-1,
            map_setting_custom_index:-1
})
}
// 监听更新一键设置组件下标
change_map_setting_index=(index,mode,status)=>{
    let that=this
    //已选择条件下,忽略监听
    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_custom_select_index!=-1) return ;
    if(mode==1){
        // 官方
            if(status==1){
            
            
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,

                map_setting_gov_index:index
            })
            that.pxn_hex_to_str(that.state.map_setting_gov_data[index].keboardValue)
            }else{
                that.setState({
                    map_setting_gov_index:-1
                },()=>{
                    that.show_footrest_char(1,that.state.lihe_coordinate_data)
                    that.show_footrest_char(2,that.state.shache_coordinate_data)
                   that.show_footrest_char(3,that.state.youmen_coordinate_data)
                })
            }
        
    }else if(mode==2){
        //自定义
        if(status==1){
            let map_setting_custom_data=that.state.map_setting_custom_data



            let lihe_coordinate_data=[].concat(map_setting_custom_data[index].lihe_str)
            let lihe_coordinate_hex=[].concat(map_setting_custom_data[index].lihe_hex)
            let shache_coordinate_hex=[].concat(map_setting_custom_data[index].shache_hex)
            let shache_coordinate_data=[].concat(map_setting_custom_data[index].shache_str)
            let youmen_coordinate_data=[].concat(map_setting_custom_data[index].youmen_str)
            let youmen_coordinate_hex=[].concat(map_setting_custom_data[index].youmen_hex)
            that.setState({
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:index,
                preview_wheel_map_hex_pre:map_setting_custom_data[index].hex,
                preview_wheel_map_key:map_setting_custom_data[index].str,


                preview_lihe_coordinate_hex:lihe_coordinate_hex,
                    preview_shache_coordinate_hex:shache_coordinate_hex,
                    preview_youmen_coordinate_hex:youmen_coordinate_hex,
            
                    preview_lihe_coordinate_data:lihe_coordinate_data,
                    preview_shache_coordinate_data:shache_coordinate_data,
                    preview_youmen_coordinate_data:youmen_coordinate_data
     
                },()=>{
            
                    that.show_footrest_char(1,lihe_coordinate_data)
                    that.show_footrest_char(2,shache_coordinate_data)
                   that.show_footrest_char(3,youmen_coordinate_data)
        
            })
            
            }else{
            that.setState({
                map_setting_custom_index:-1
            },()=>{
                that.show_footrest_char(1,that.state.lihe_coordinate_data)
                that.show_footrest_char(2,that.state.shache_coordinate_data)
               that.show_footrest_char(3,that.state.youmen_coordinate_data)
            })
            }
    }
}

//获取设置模式选中状态
change_map_setting_select=(mode,index)=>{
        let that=this;
        if(mode==1){
        if(that.state.map_setting_gov_select_index!=index){

            that.setState({
                map_setting_gov_select_index:index,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1,
                map_setting_custom_select_index:-1,
                })

                that.pxn_hex_to_str(that.state.map_setting_gov_data[index].keboardValue)
            }else{
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1

            },()=>{
                that.show_footrest_char(1,that.state.lihe_coordinate_data)
                that.show_footrest_char(2,that.state.shache_coordinate_data)
               that.show_footrest_char(3,that.state.youmen_coordinate_data)
            })
            }
        }else if(mode==2){
        if(that.state.map_setting_custom_select_index!=index){
            let map_setting_custom_data=that.state.map_setting_custom_data
            
            let lihe_coordinate_data=[].concat(map_setting_custom_data[index].lihe_str)
            let lihe_coordinate_hex=[].concat(map_setting_custom_data[index].lihe_hex)
            let shache_coordinate_hex=[].concat(map_setting_custom_data[index].shache_hex)
            let shache_coordinate_data=[].concat(map_setting_custom_data[index].shache_str)
            let youmen_coordinate_data=[].concat(map_setting_custom_data[index].youmen_str)
            let youmen_coordinate_hex=[].concat(map_setting_custom_data[index].youmen_hex)
            that.setState({
                map_setting_custom_select_index:index,
                map_setting_gov_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1,
                preview_wheel_map_hex_pre:map_setting_custom_data[index].hex,
                preview_wheel_map_key:map_setting_custom_data[index].str,


                preview_lihe_coordinate_hex:lihe_coordinate_hex,
                    preview_shache_coordinate_hex:shache_coordinate_hex,
                    preview_youmen_coordinate_hex:youmen_coordinate_hex,
            
                    preview_lihe_coordinate_data:lihe_coordinate_data,
                    preview_shache_coordinate_data:shache_coordinate_data,
                    preview_youmen_coordinate_data:youmen_coordinate_data
     
                },()=>{
            
                    that.show_footrest_char(1,lihe_coordinate_data)
                    that.show_footrest_char(2,shache_coordinate_data)
                   that.show_footrest_char(3,youmen_coordinate_data)
        
            })
     

            
            }else{
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1
            },()=>{
                that.show_footrest_char(1,that.state.lihe_coordinate_data)
                that.show_footrest_char(2,that.state.shache_coordinate_data)
               that.show_footrest_char(3,that.state.youmen_coordinate_data)
            })
            }
        }
            
}

// 应用模式 官方推荐
map_setting_apply=()=>{
    let that=this
    let map_setting_gov_select_index=that.state.map_setting_gov_select_index
    let map_setting_gov_data=that.state.map_setting_gov_data
    let map_key_str=map_setting_gov_data[map_setting_gov_select_index].keboardValue
    confirm({
        title: '确定应用该模式?',
        icon: <ExclamationCircleFilled />,
        content: null,
        okText:'确认',
        cancelText:'取消',
        onOk() {
            that.pxn_hex_to_str(map_key_str,2)
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1
            })
            console.log('OK');
        },
        onCancel() {
            console.log('Cancel');
        },
        });
}
    
// 应用自定义模式
map_setting_custom_apply=()=>{
let that=this
let map_setting_custom_data=that.state.map_setting_custom_data
let index=that.state.map_setting_custom_select_index

confirm({
    title: '确定应用该模式?',
    icon: <ExclamationCircleFilled />,
    content: null,
    okText:'确认',
    cancelText:'取消',
    onOk() {
        that.setState({
 
            wheel_map_hex_pre:map_setting_custom_data[index].hex,
            wheel_map_key:map_setting_custom_data[index].str,
            lihe_coordinate_hex:map_setting_custom_data[index].lihe_hex,
            shache_coordinate_hex:map_setting_custom_data[index].shache_hex,
            youmen_coordinate_hex:map_setting_custom_data[index].youmen_hex,
            lihe_coordinate_data:map_setting_custom_data[index].lihe_str,
            shache_coordinate_data:map_setting_custom_data[index].shache_str,
            youmen_coordinate_data:map_setting_custom_data[index].youmen_str,

            map_setting_gov_select_index:-1,
            map_setting_custom_select_index:-1,
            map_setting_gov_index:-1,
            map_setting_custom_index:-1
        })
        // PY.pxn_wheel_set(map_setting_custom_data[index].led,map_setting_custom_data[index].hex)
        // 设置键盘值
        PY.pxn_footrest_map_set_one(1,map_setting_custom_data[index].hex[1])
        PY.pxn_footrest_map_set_one(2,map_setting_custom_data[index].hex[2])
        PY.pxn_footrest_map_set_one(3,map_setting_custom_data[index].hex[3])
        // 设置坐标数据
        PY.pxn_footrest_coordinate_set(map_setting_custom_data[index].lihe_hex, map_setting_custom_data[index].shache_hex, map_setting_custom_data[index].youmen_hex)
        message.success("操作成功!")
    },
    onCancel() {
        console.log('Cancel');
    },
    });

}

// 重命名自定义模式
map_setting_custom_rename=()=>{
let that=this
if(!that.state.custom_rename_key){
    message.error("输入不能为空!")
    return;
}
let map_setting_custom_data=that.state.map_setting_custom_data
let index=that.state.map_setting_custom_select_index
map_setting_custom_data[index].name=that.state.custom_rename_key
that.setState({
    map_setting_custom_data:map_setting_custom_data,
    map_setting_gov_select_index:-1,
    map_setting_custom_select_index:-1,
    map_setting_gov_index:-1,
    map_setting_custom_index:-1
})
// 更新数据到设备
PY.pxn_user_config_set("footrest",map_setting_custom_data);

// 关闭窗口
that.custom_rename_modal_hidden();
message.success("操作成功!")
}

// 保存自定义模式
map_setting_custom_save=()=>{
let that=this
let map_setting_custom_data=that.state.map_setting_custom_data
let index=that.state.map_setting_custom_select_index
map_setting_custom_data[index].hex=that.state.preview_wheel_map_hex_pre
map_setting_custom_data[index].str=that.state.preview_wheel_map_key

map_setting_custom_data[index].lihe_str=that.state.preview_lihe_coordinate_data
map_setting_custom_data[index].lihe_hex=that.state.preview_lihe_coordinate_hex
map_setting_custom_data[index].shache_str=that.state.preview_shache_coordinate_data
map_setting_custom_data[index].shache_hex=that.state.preview_shache_coordinate_hex
map_setting_custom_data[index].youmen_str=that.state.preview_youmen_coordinate_data
map_setting_custom_data[index].youmen_hex=that.state.preview_youmen_coordinate_hex


confirm({
    title: '确定保存该模式?',
    icon: <ExclamationCircleFilled />,
    content: null,
    okText:'确认',
    cancelText:'取消',
    onOk() {
        that.setState({
            map_setting_gov_select_index:-1,
            map_setting_custom_select_index:-1,
            map_setting_gov_index:-1,
            map_setting_custom_index:-1
        })
        // 更新数据到设备
        PY.pxn_user_config_set("footrest",map_setting_custom_data);
        // 关闭窗口
        that.custom_rename_modal_hidden();
        message.success("操作成功!")
    },
    onCancel() {
        console.log('Cancel');
    },
    });
}

// 删除自定义模式
map_setting_custom_del=(index,e)=>{
    let that=this
    // console.log(index)
    e.stopPropagation();
    that.setState({
        map_setting_gov_select_index:-1,
        map_setting_custom_select_index:index,
        map_setting_gov_index:-1,
        map_setting_custom_index:-1
    })
    let map_setting_custom_data=that.state.map_setting_custom_data
    let map_key_str=map_setting_custom_data[index]
    confirm({
        title: '确定删除该模式?',
        icon: <ExclamationCircleFilled />,
        content: null,
        okText:'确认',
        cancelText:'取消',
        onOk() {
            map_setting_custom_data.splice(index,1)
            console.log(map_setting_custom_data)
            that.setState({
                map_setting_custom_data:map_setting_custom_data,
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1
            })
            // 更新数据
            PY.pxn_user_config_set("footrest",map_setting_custom_data)
            message.success("操作成功!")
        },
        onCancel() {
        },
        });
}

// 新增自定义模式
map_setting_custom_add=()=>{
    let that=this;
    if(!that.state.custom_rename_key){
        message.error("输入不能为空!")
        return;
    }
    let data={}
    data.name=that.state.custom_rename_key

    data.lihe_str=[].concat(that.state.lihe_coordinate_data)
    data.lihe_hex=[].concat(that.state.lihe_coordinate_hex)
    data.shache_str=[].concat(that.state.shache_coordinate_data)
    data.shache_hex=[].concat(that.state.shache_coordinate_hex)
    data.youmen_str=[].concat(that.state.youmen_coordinate_data)
    data.youmen_hex=[].concat(that.state.youmen_coordinate_hex)
    // 获取当前str
    data.str=that.state.wheel_map_key
    // 获取当前hex
    data.hex=that.state.wheel_map_hex_pre
    let map_setting_custom_data=that.state.map_setting_custom_data
    map_setting_custom_data.unshift(data)
    that.setState({
        map_setting_custom_data:map_setting_custom_data
    })

    // 更新数据到设备
    let tmp_handle= PY.pxn_user_config_set("footrest",map_setting_custom_data);
    if(tmp_handle){
        tmp_handle.then((rst)=>{
            // message.success("操作成功!")
        })
    }
    // 关闭窗口
    that.custom_rename_modal_hidden();
    message.success("操作成功!")
    // console.log(data)
}

//隐藏修改名称窗口 
custom_rename_modal_hidden=()=>{
let that=this
that.setState({
    custom_rename_modal_visible:false,
    custom_rename_key:undefined
})
}
//显示修改名称窗口 
custom_rename_modal_show=(flag)=>{
let that=this
if(flag==1){
    // 新增
    that.setState({
        custom_rename_modal_visible:true,
        custom_rename_modal_flag:flag,
        custom_rename_key:undefined
    })
}else{
    let map_setting_custom_data=that.state.map_setting_custom_data
    let index=that.state.map_setting_custom_select_index
    let custom_rename_key=map_setting_custom_data[index].name
    // 更新
    that.setState({
        custom_rename_modal_visible:true,
        custom_rename_modal_flag:flag,
        custom_rename_key:custom_rename_key
    })
}

}
//监听值变化 
change_custom_rename_key(e){
const { value } = e.target;
this.setState({
    custom_rename_key: value
})
}
// 恢复默认设置
footrest_recover=(cur)=>{
    let that=this

if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){
    let wheel_map_key=that.state.preview_wheel_map_key
    let wheel_map_hex_pre=that.state.preview_wheel_map_hex_pre
    let wheel_map_key_tpl=that.state.wheel_map_key_tpl
    let wheel_map_hex_tpl=that.state.wheel_map_hex_tpl

    wheel_map_key[cur]=wheel_map_key_tpl[cur]
    wheel_map_hex_pre[cur]=wheel_map_hex_tpl[cur]
    let lihe_coordinate_hex=that.state.preview_lihe_coordinate_hex
    let shache_coordinate_hex=that.state.preview_shache_coordinate_hex
    let youmen_coordinate_hex=that.state.preview_youmen_coordinate_hex
    let lihe_coordinate_data=that.state.preview_lihe_coordinate_data
    let shache_coordinate_data=that.state.preview_shache_coordinate_data
    let youmen_coordinate_data=that.state.preview_youmen_coordinate_data
    let default_coordinate_data=that.state.default_coordinate_data
    let default_coordinate_hex=that.state.default_coordinate_hex
    
    if(cur==1){
        lihe_coordinate_hex=default_coordinate_hex
        lihe_coordinate_data=default_coordinate_data
    }else if(cur==2){
        shache_coordinate_hex=default_coordinate_hex
        shache_coordinate_data=default_coordinate_data
    }else if(cur==3){
        youmen_coordinate_hex=default_coordinate_hex
        youmen_coordinate_data=default_coordinate_data

    }
confirm({
    title: '确定恢复默认配置?',
    icon: <ExclamationCircleFilled />,
    content: null,
    okText:'确认',
    cancelText:'取消',
    onOk() {
        that.setState({
            preview_wheel_map_key:wheel_map_key,
            preview_wheel_map_hex_pre:wheel_map_hex_pre,
            preview_lihe_coordinate_hex:lihe_coordinate_hex,
            preview_lihe_coordinate_data:lihe_coordinate_data,
            preview_shache_coordinate_hex:shache_coordinate_hex,
            preview_shache_coordinate_data:shache_coordinate_data,
            preview_youmen_coordinate_hex:youmen_coordinate_hex,
            preview_youmen_coordinate_data:youmen_coordinate_data,
        })
     

        if(cur==1){
            that.show_footrest_char(1,lihe_coordinate_data)
        }
        if(cur==2){
            that.show_footrest_char(2,shache_coordinate_data)
        }
        if(cur==3){
            that.show_footrest_char(3,youmen_coordinate_data)
        }
        message.success("操作成功!")
    },
    onCancel() {
        console.log('Cancel');
    },
    });

}else{
    let wheel_map_key=that.state.wheel_map_key
    let wheel_map_hex_pre=that.state.wheel_map_hex_pre
    let wheel_map_key_tpl=that.state.wheel_map_key_tpl
    let wheel_map_hex_tpl=that.state.wheel_map_hex_tpl

    wheel_map_key[cur]=wheel_map_key_tpl[cur]
    wheel_map_hex_pre[cur]=wheel_map_hex_tpl[cur]
    let lihe_coordinate_hex=that.state.lihe_coordinate_hex
    let shache_coordinate_hex=that.state.shache_coordinate_hex
    let youmen_coordinate_hex=that.state.youmen_coordinate_hex
    let lihe_coordinate_data=that.state.lihe_coordinate_data
    let shache_coordinate_data=that.state.shache_coordinate_data
    let youmen_coordinate_data=that.state.youmen_coordinate_data
    let default_coordinate_data=that.state.default_coordinate_data
    let default_coordinate_hex=that.state.default_coordinate_hex
    
    if(cur==1){
        lihe_coordinate_hex=default_coordinate_hex
        lihe_coordinate_data=default_coordinate_data
    }else if(cur==2){
        shache_coordinate_hex=default_coordinate_hex
        shache_coordinate_data=default_coordinate_data
    }else if(cur==3){
        youmen_coordinate_hex=default_coordinate_hex
        youmen_coordinate_data=default_coordinate_data

    }
confirm({
    title: '确定恢复默认配置?',
    icon: <ExclamationCircleFilled />,
    content: null,
    okText:'确认',
    cancelText:'取消',
    onOk() {
        that.setState({
            wheel_map_key:wheel_map_key,
            wheel_map_hex_pre:wheel_map_hex_pre,
            lihe_coordinate_hex:lihe_coordinate_hex,
            lihe_coordinate_data:lihe_coordinate_data,
            shache_coordinate_hex:shache_coordinate_hex,
            shache_coordinate_data:shache_coordinate_data,
            youmen_coordinate_hex:youmen_coordinate_hex,
            youmen_coordinate_data:youmen_coordinate_data,
        })
        let p12_lite_data=that.props.p12_lite_data
        // 更新本地缓存 以及下发给设备
        p12_lite_data.footrest_map_key=wheel_map_key;
        p12_lite_data.footrest_map_hex= wheel_map_hex_pre
        p12_lite_data.lihe_coordinate_hex=lihe_coordinate_hex
        p12_lite_data.shache_coordinate_hex=shache_coordinate_hex
        p12_lite_data.youmen_coordinate_hex=youmen_coordinate_hex
     
        // 设置键盘值
        PY.pxn_footrest_map_set_one(cur,wheel_map_hex_pre[cur])
        // 设置坐标数据
        PY.pxn_footrest_coordinate_set(lihe_coordinate_hex, shache_coordinate_hex, youmen_coordinate_hex)
        // // 更新本地数据
        const { dispatch } = that.props;
        dispatch(FX.set_p12_lite_data(p12_lite_data))

        if(cur==1){
            that.show_footrest_char(1,lihe_coordinate_data)
        }
        if(cur==2){
            that.show_footrest_char(2,shache_coordinate_data)
        }
        if(cur==3){
            that.show_footrest_char(3,youmen_coordinate_data)
        }
        message.success("操作成功!")
    },
    onCancel() {
        console.log('Cancel');
    },
    });
}
  

}
/********************一键设置相关  end********************** */

    // 开始监听
    keyboard_monitor_start(cur){
        let that=this
        that.keyboard_monitor_stop();
        // 初始化键盘值

        Window.KEYBOARD_TIMER=setInterval(()=>{

            let handle=PY.keyboard_get()
            if(handle==false) return;
            handle.then((rst)=>{
                if(rst.data!=-1){
                    if(Window.KEYBOARD_TIMER){
                        clearInterval(Window.KEYBOARD_TIMER)
                    }
                    

                    
                        

                    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
                    {
                        let wheel_map_key=that.state.preview_wheel_map_key
                        let wheel_map_hex_pre=that.state.preview_wheel_map_hex_pre
                        wheel_map_key[cur]=rst.data
                        wheel_map_hex_pre[cur]=rst.hex
                        
                        that.setState({
                            wheel_input_key:rst.data,
                            wheel_input_hex:rst.hex,
                            preview_wheel_map_key:wheel_map_key,
                            preview_wheel_map_hex_pre:wheel_map_hex_pre,
                            wheel_input_refresh_visible:1
                        })
                    }else{
                        let wheel_map_key=that.state.wheel_map_key
                        let wheel_map_hex_pre=that.state.wheel_map_hex_pre
                        wheel_map_key[cur]=rst.data
                        wheel_map_hex_pre[cur]=rst.hex
                        
                        that.setState({
                            wheel_input_key:rst.data,
                            wheel_input_hex:rst.hex,
                            wheel_map_key:wheel_map_key,
                            wheel_map_hex_pre:wheel_map_hex_pre,
                            wheel_input_refresh_visible:1
                        })
                        if(rst.hex!=that.state.wheel_map_hex_pre[cur]){
                             // 设置键盘值
                            PY.pxn_footrest_map_set_one(that.state.wheel_active_status,rst.hex)
                            // 更新本地数据
                            let p12_lite_data=that.props.p12_lite_data
                            p12_lite_data.footrest_map_key=wheel_map_key
                            p12_lite_data.footrest_map_hex=wheel_map_hex_pre
                            const { dispatch } = that.props;
                            dispatch(FX.set_p12_lite_data(p12_lite_data))

                        }
                       
                    }

                        
                         
   
                }
            }).catch(()=>{})

        },25)

          
    }
    // 停止监听
    keyboard_monitor_stop(){
        let that=this;
        if(Window.KEYBOARD_TIMER){
            clearInterval(Window.KEYBOARD_TIMER)
        }
        PY.keyboard_init();
        
    }




//监听设置激活按钮的下标
    change_map_active_cur=(cur,status,e)=>{
        let that=this
    
        if(status==0){
  
            
            
            let wheel_map_key
            if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
            {
                wheel_map_key=that.state.preview_wheel_map_key
            }else{
                wheel_map_key=that.state.wheel_map_key
            }

            that.keyboard_monitor_stop();
            
         
            if(that.state.wheel_input_key){
                wheel_map_key[that.state.wheel_active_status]=that.state.wheel_input_key
            }

            //鼠标离开
            
            if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
            {
                that.setState({
   
                    wheel_active_status:0,
                    wheel_input_refresh_visible:0,
                    preview_wheel_map_key:wheel_map_key,
                    wheel_input_key:''
                }) 
            }else{
                that.setState({
   
                    wheel_active_status:0,
                    wheel_input_refresh_visible:0,
                    wheel_map_key:wheel_map_key,
                    wheel_input_key:''
                }) 
     
            }
                   
        }else{

            //鼠标进入
            that.setState({
  
                wheel_active_status:cur,
                wheel_input_refresh_visible:0
            },()=>{

                that.keyboard_monitor_start(cur);
                
            })     
        }
    }
    //清除输入数据
    clear_map_input=()=>{
        let that=this;
 // 防止数据刷新过快

        if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
        {
            let wheel_map_key=that.state.preview_wheel_map_key
            let wheel_map_hex_tpl=that.state.wheel_map_hex_tpl
            that.keyboard_monitor_start(); 
            wheel_map_key[that.state.wheel_active_status]=that.state.wheel_map_key_tpl[that.state.wheel_active_status]
            
            that.setState({
                wheel_input_key:'',
                preview_wheel_map_key:wheel_map_key,
                wheel_input_refresh_visible:0
            })
        }else{
            let wheel_map_key=that.state.wheel_map_key
            let wheel_map_hex_tpl=that.state.wheel_map_hex_tpl
            that.keyboard_monitor_start(); 
            wheel_map_key[that.state.wheel_active_status]=that.state.wheel_map_key_tpl[that.state.wheel_active_status]
            
            that.setState({
                wheel_input_key:'',
                wheel_map_key:wheel_map_key,
                wheel_input_refresh_visible:0
            })
            // 设置键盘值
            PY.pxn_footrest_map_set_one(that.state.wheel_active_status,wheel_map_hex_tpl[that.state.wheel_active_status])
        }


  
    }

// 键盘设置显示组件
map_display_view=(cur)=>{


    // wheel_map_key_tpl:['-1','Gear 1', 'Gear 2', 'Gear 3', 'Gear 4', 'Gear 5', 'Gear 6', 'Gear R', 'Hand Brake', 'Gear Switch'],



    let that=this
    let wheel_map_key

//    判断当前状态是否为预览
    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){
        wheel_map_key=that.state.preview_wheel_map_key

    }else{
        wheel_map_key =that.state.wheel_map_key
    }

    if(wheel_map_key[cur]=="Accelerator"){
        return <div className='jkw-mapping-font-2-view'>油门</div>
    }else if(wheel_map_key[cur]=="Brake"){
        return <div className='jkw-mapping-font-2-view'>刹车</div>
    }else if(wheel_map_key[cur]=="Clutch"){
        return <div className='jkw-mapping-font-2-view'>离合</div>
    }

    return <div className='jkw-mapping-font-2-view'>{wheel_map_key[cur]}</div>

}



    render() {
        let that=this;
        let wheel_active_status=that.state.wheel_active_status
        let wheel_input_refresh_visible=that.state.wheel_input_refresh_visible
        let wheel_input_key=that.state.wheel_input_key;
        //  获取官方推荐
        let map_setting_gov_view=that.state.map_setting_gov_data.map((item,index)=>{
            if(that.state.map_setting_gov_index==index||that.state.map_setting_gov_select_index==index){
                
                return <div className='jkw-quick_setting-row-active-view' onMouseEnter={that.change_map_setting_index.bind(that,index,1,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,1,0)} onClick={that.change_map_setting_select.bind(that,1,index)} >
                <div className='jkw-quick_setting-row-active-wrap-view'>{item.keyboardName}</div>
            </div>
            }
            return <div className='jkw-quick_setting-row-view jkw-font-2' onMouseEnter={that.change_map_setting_index.bind(that,index,1,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,1,0)} onClick={that.change_map_setting_select.bind(that,1,index)} >{item.keyboardName}</div>
        })

        // 获取一键设置模式
        let map_setting_custom_view=that.state.map_setting_custom_data.map((item,index)=>{
            if(that.state.map_setting_custom_index==index||that.state.map_setting_custom_select_index==index){
                return <div className='jkw-quick_setting-row-active-view' onMouseEnter={that.change_map_setting_index.bind(that,index,2,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,2,0)}  onClick={that.change_map_setting_select.bind(that,2,index)}>
                <div className='jkw-quick_setting-row-active-wrap-view'>{item.name}</div>
                <img src="/assets/map-setting-del-icon.png" className='jkw-map-del-icon-view'  onClick={that.map_setting_custom_del.bind(that,index)}/>
            </div>
            }
            
            return <div className='jkw-quick_setting-row-view jkw-font-2' onMouseEnter={that.change_map_setting_index.bind(that,index,2,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,2,0)}  onClick={that.change_map_setting_select.bind(that,2,index)}>{item.name}</div>

        })
        let map_setting_center_view=null;
        //  一键设置  缺省处理
        if((that.state.map_setting_gov_data.length==0&&that.state.setting_mode==1)||(that.state.map_setting_custom_data.length==0&&that.state.setting_mode==2)){
            map_setting_center_view=<div className='jkw-quick_setting-center-view flex-center'>
                <img src="/assets/display_none.png" className='jkw-display-none-icon-view' />
            </div>
        }else if(that.state.setting_mode==2){
            map_setting_center_view=<div className='jkw-quick_setting-center-view'>
            {map_setting_custom_view}
            </div>
        }else{
            map_setting_center_view=<div className='jkw-quick_setting-center-view'>
            {map_setting_gov_view}
            </div>
        }



        let map_setting_btn_view=null;
        if(that.state.setting_mode==1){
            if(that.state.map_setting_gov_select_index==-1&&that.state.map_setting_gov_index==-1){
                map_setting_btn_view=<div className="jkw-quick_setting-down-view opacity-5"   >
                <div className='jkw-quick_setting-down-mode2-view' >
                    应用模式
                </div>
            </div>
            }else{
                map_setting_btn_view=<div className="jkw-quick_setting-down-view click-style"   >
                <div className='jkw-quick_setting-down-mode2-view' onClick={that.map_setting_apply.bind(that)}>
                    应用模式
                </div>
            </div>
            }
            
        }else if(that.state.setting_mode==2){
            //自定义模式
            if(that.state.map_setting_custom_index==-1&&that.state.map_setting_custom_select_index==-1){
                map_setting_btn_view=<div className='jkw-quick_setting-down-view'>
                    <div className='jkw-quick_setting-down-mode2-view click-style' onClick={that.custom_rename_modal_show.bind(that,1)}>
                        新增一键设置
                    </div>
                </div> 
                
            }else{
                map_setting_btn_view=<div className='jkw-quick_setting-down-view'>
                <div className='jkw-quick_setting-down-mode3-view' onClick={that.map_setting_custom_apply.bind(that)}>应用模式</div>
                <div className='jkw-quick_setting-down-mode3-view' onClick={that.custom_rename_modal_show.bind(that,2)}>重新命名</div>
                <div className='jkw-quick_setting-down-mode4-view' onClick={that.map_setting_custom_save.bind(that)}>保存设置</div>
            </div> 
            }
            
        }

return (
    <div  className="jkw-wheel-view  ">
   
    {/* 设备未连接 */}
    <div className='jkw-wheel-none-view' hidden={that.state.foot_pedal_state}></div>
    <div className='jkw-wheel-none-info-view' hidden={that.state.foot_pedal_state}>
        <div className='jkw-wheel-none-hint-view'>
            <img src={"/assets/warn_icon.png"}   className="jkw-icon-16 margin-right-6"/>
            <text className='jkw-wheel-font-1'>脚踏板未连接</text>
        </div>
    </div>
    <div  className='jkw-left-view '>
        {/* 一键设置模式 */}
        <div className='jkw-quick_setting-view jkw-quick_setting-view-footrest'>
            <div className='jkw-quick_setting-top-view'>
                <div  className='jkw-quick_setting-top-left-view'>
                    <text className='jkw-font-1'>一键设置模式</text>
                    <img src="/assets/hint_icon.png" className="jkw-icon-13 jkw-point-view margin-left-6"/>
                </div>
                <div  className={that.state.setting_mode==1?'jkw-quick_setting-top-right-view':'jkw-quick_setting-top-right2-view'}>
                    <div className= {that.state.setting_mode==1?'jkw-quick_setting-top-row-view':'jkw-quick_setting-top-row-active-view'} onClick={that.change_setting_map.bind(that,2)}>自定义</div>
                    <div className={that.state.setting_mode==2?'jkw-quick_setting-top-row-view':'jkw-quick_setting-top-row-active-view'}   onClick={that.change_setting_map.bind(that,1)} >官方推荐</div>
                </div>
            </div>
            {map_setting_center_view}
            {map_setting_btn_view}
        </div>



        {/* 按键设置 */}
        <div  className='jkw-mapping-view jkw-mapping-view-footrest'>
                <div className='jkw-footrest-row-view jkw-footrest-border-bottom-05'>
                    <div className='jkw-footrest-row-left-view'>
                        <text className='jkw-footrest-font-1'>离合设置</text>
                        <img src="/assets/footrest/foot_rest_device_1.png" className="foot-rest-device-icon"/>
                    </div>
                    <div className='jkw-footrest-row-center-view'>
                        <div className='jkw-footrest-char-hint-view'>
                            <text className='jkw-footrest-font-5 margin-right-5'>曲线调整</text>
                            <img src={"/assets/hint-icon-13-13.png"} className='jkw-icon-13'/>
                        </div>
                        <div id="jkw-footrest-char-1" className='jkw-footrest-char-view'></div>
                    </div>
                    <div className='jkw-footrest-row-right-view'>
                        <div className='jkw-footrest-row-right-view-12'>
                            <div className='jkw-footrest-row-right-view-1'>
                                <div className='jkw-footrest-row-right-view-1-wrap'>
                                    <div className='jkw-footrest-slider-view'>
                                        <div className='jkw-footrest-slider-view-1'></div>
                                        <div className='jkw-footrest-slider-view-2' style={{width:that.state.lihe_value+"%"}}></div>
                                    </div>
                                    {that.state.lihe_value_old>0?<div className='jkw-footrest-slider-view-old'>
                                       
                                       <div className='jkw-footrest-slider-view-old-slider' style={{width:that.state.lihe_value_old+"%"}}></div>
                                       <text className='jkw-footrest-font-2'>{that.state.lihe_value_old}</text>

                                 </div>:''}
                                </div>
                                <div className='pxn-footrest-num-wrap-view-2 margin-left-4'>{that.state.lihe_value} </div>
                            </div>
                            <div className='jkw-footrest-row-right-view-2'>
                                <div className='pxn-footrest-num-wrap-view'>0</div>
                                <div className='pxn-footrest-num-wrap-view'>100</div>
                            </div>

                        </div>
                        <div className='jkw-footrest-row-right-view-3'>
                            <div className={wheel_active_status==1?'jkw-mapping-row-active-view ':'jkw-mapping-row-view'}  onMouseEnter={that.change_map_active_cur.bind(that,1,1)} onMouseLeave={that.change_map_active_cur.bind(that,1,0)} >
                                <div className='jkw-mapping-font-1-view'>离合</div>
                                {wheel_active_status==1?
                                <div className='jkw-mapping-row-wrap-view'>
                                    {wheel_input_refresh_visible==1?
                                    <div className='jkw-mapping-font-4-view'>
                                        <div className='jkw-mapping-font-wrap-view'>
                                            <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                            
                                        </div>
                                        <div className='jkw-mapping-input-icon-wrap-view'  onClick={that.clear_map_input.bind(that)}>
                                            <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                        </div>
                                        
                                    </div>:
                                    <div className='jkw-mapping-font-5-view'>
                                        <div className='jkw-mapping-font-wrap2-view'>
                                            <text className='jkw-mapping-font-1'>输入代替键</text>
                                            
                                        </div>
                                    </div>
                                    }
                                </div>:
                                <div className='jkw-mapping-row-wrap-view'>
                                    
                                    {that.map_display_view(1)}
                                </div>}
                            </div>
                        </div>
                        <div className='jkw-footrest-row-right-view-4' onClick={that.footrest_recover.bind(that,1)} >恢复默认</div>
                    </div>
                
                </div>


                <div className='jkw-footrest-row-view jkw-footrest-border-bottom-05'>
                    <div className='jkw-footrest-row-left-view'>
                        <text className='jkw-footrest-font-1'>刹车设置</text>
                        <img src="/assets/footrest/foot_rest_device_2.png" className="foot-rest-device-icon"/>
                    </div>
                    <div className='jkw-footrest-row-center-view'>
                        <div className='jkw-footrest-char-hint-view'>
                            <text className='jkw-footrest-font-5 margin-right-5'>曲线调整</text>
                            <img src={"/assets/hint-icon-13-13.png"} className='jkw-icon-13'/>
                        </div>
                        <div id="jkw-footrest-char-2" className='jkw-footrest-char-view'></div>
                    </div>
                    <div className='jkw-footrest-row-right-view'>
                        <div className='jkw-footrest-row-right-view-12'>
                            <div className='jkw-footrest-row-right-view-1'>
                                <div className='jkw-footrest-row-right-view-1-wrap'>
                                    <div className='jkw-footrest-slider-view'>
                                        <div className='jkw-footrest-slider-view-1'></div>
                                        <div className='jkw-footrest-slider-view-2' style={{width:that.state.shache_value+"%"}}></div>
                                    </div>
                                    {that.state.shache_value_old>0?<div className='jkw-footrest-slider-view-old'>
                                       
                                              <div className='jkw-footrest-slider-view-old-slider' style={{width:that.state.shache_value_old+"%"}}></div>
                                              <text className='jkw-footrest-font-2'>{that.state.shache_value_old}</text>

                                        </div>:''}
                                </div>
                                <div className='pxn-footrest-num-wrap-view-2 margin-left-4'>{that.state.shache_value}</div>
                            </div>
                            <div className='jkw-footrest-row-right-view-2'>
                                <div className='pxn-footrest-num-wrap-view'>0</div>
                                <div className='pxn-footrest-num-wrap-view'>100</div>
                            </div>

                        </div>
                        <div className='jkw-footrest-row-right-view-3'>
                            <div className={wheel_active_status==2?'jkw-mapping-row-active-view ':'jkw-mapping-row-view'}  onMouseEnter={that.change_map_active_cur.bind(that,2,1)} onMouseLeave={that.change_map_active_cur.bind(that,2,0)} >
                                <div className='jkw-mapping-font-1-view'>刹车</div>
                                {wheel_active_status==2?
                                <div className='jkw-mapping-row-wrap-view'>
                                    {wheel_input_refresh_visible==1?
                                    <div className='jkw-mapping-font-4-view'>
                                        <div className='jkw-mapping-font-wrap-view'>
                                            <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                            
                                        </div>
                                        <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                            <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                        </div>
                                        
                                    </div>:
                                    <div className='jkw-mapping-font-5-view'>
                                        <div className='jkw-mapping-font-wrap2-view'>
                                            <text className='jkw-mapping-font-1'>输入代替键</text>
                                            
                                        </div>
                                    </div>
                                    }
                                </div>:
                                <div className='jkw-mapping-row-wrap-view'>
                                    
                                    {that.map_display_view(2)}
                                </div>}
                            </div>
                        </div>
                        <div className='jkw-footrest-row-right-view-4' onClick={that.footrest_recover.bind(that,2)}>恢复默认</div>
                    </div>
                </div>
                <div className='jkw-footrest-row-view'>
                    <div className='jkw-footrest-row-left-view'>
                        <text className='jkw-footrest-font-1'>油门设置</text>
                        <img src="/assets/footrest/foot_rest_device_3.png" className="foot-rest-device-icon"/>
                    </div>
                    <div className='jkw-footrest-row-center-view'>
                        <div className='jkw-footrest-char-hint-view'>
                            <text className='jkw-footrest-font-5 margin-right-5'>曲线调整</text>
                            <img src={"/assets/hint-icon-13-13.png"} className='jkw-icon-13'/>
                        </div>
                        <div id="jkw-footrest-char-3" className='jkw-footrest-char-view'></div>
                    </div>
                    <div className='jkw-footrest-row-right-view'>
                        <div className='jkw-footrest-row-right-view-12'>
                            <div className='jkw-footrest-row-right-view-1'>
                                <div className='jkw-footrest-row-right-view-1-wrap'>
                                    <div className='jkw-footrest-slider-view'>
                                        <div className='jkw-footrest-slider-view-1'></div>
                                        <div className='jkw-footrest-slider-view-2' style={{width:that.state.youmen_value+"%"}}></div>
                                    </div>
                                    {that.state.youmen_value_old>0?<div className='jkw-footrest-slider-view-old'>
                                       
                                            <div className='jkw-footrest-slider-view-old-slider' style={{width:that.state.youmen_value_old+"%"}}></div>
                                            <text className='jkw-footrest-font-2'>{that.state.youmen_value_old}</text>

                                    </div>:''}
                                </div>
                                <div className='pxn-footrest-num-wrap-view-2 margin-left-4'>{that.state.youmen_value}</div>
                            </div>
                            <div className='jkw-footrest-row-right-view-2'>
                                <div className='pxn-footrest-num-wrap-view'>0</div>
                                <div className='pxn-footrest-num-wrap-view'>100</div>
                            </div>

                        </div>
                        <div className='jkw-footrest-row-right-view-3'>
                            <div className={wheel_active_status==3?'jkw-mapping-row-active-view ':'jkw-mapping-row-view'}  onMouseEnter={that.change_map_active_cur.bind(that,3,1)} onMouseLeave={that.change_map_active_cur.bind(that,3,0)} >
                                <div className='jkw-mapping-font-1-view'>油门</div>
                                {wheel_active_status==3?
                                <div className='jkw-mapping-row-wrap-view'>
                                    {wheel_input_refresh_visible==1?
                                    <div className='jkw-mapping-font-4-view'>
                                        <div className='jkw-mapping-font-wrap-view'>
                                            <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                            
                                        </div>
                                        <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                            <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                        </div>
                                        
                                    </div>:
                                    <div className='jkw-mapping-font-5-view'>
                                        <div className='jkw-mapping-font-wrap2-view'>
                                            <text className='jkw-mapping-font-1'>输入代替键</text>
                                            
                                        </div>
                                    </div>
                                    }
                                </div>:
                                <div className='jkw-mapping-row-wrap-view'>
                                    
                                    {that.map_display_view(3)}
                                </div>}
                            </div>
                        </div>
                        <div className='jkw-footrest-row-right-view-4' onClick={that.footrest_recover.bind(that,3)}>恢复默认</div>
                    </div>
                </div>
            

            
        </div>



    </div>
    


  


       {/*修改自定义模式名称  */}
  <Modal
      title={that.state.custom_rename_modal_flag==1?"新增自定义模式":"模式命名"}
      visible={that.state.custom_rename_modal_visible}
      onOk={that.state.custom_rename_modal_flag==1?that.map_setting_custom_add:that.map_setting_custom_rename}
      onCancel={that.custom_rename_modal_hidden}
      centered={true}
      okText="提交"
      cancelText="取消"
    //   maskClosable={false}
      width={400}
    >
        <Input placeholder="请输入" value={this.state.custom_rename_key} onChange={this.change_custom_rename_key.bind(that)}  style={{  width: '100%' }} />
    
   </Modal>
</div>
);
  
    }
}


const mapStateToProps = state => {

    return {
        p12_lite_data:state.p12_lite_data,
    }
};
export default connect(mapStateToProps)(Footrest)



