
import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as CONFIG from '../../config/config';
import * as PY from '../../utils/pywebview'
import * as FX from '../../redux/action'
import {
    Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
  } from 'antd';
  import * as FN  from '../../utils/index'
  import * as LANGUAGE from "../../utils/language"
  
class Upgrade extends React.Component {
   
    state = {  
        wheel_connect_status:0,
        footrest_connect_status:0,
        shift_connect_status:0,
        jizuo_connect_status:0,

        jizuo_upgrade_status:0,
        footrest_update_status:0,
        wheel_update_status:0,
        shift_update_status:0,
        jizuo_upgrade_rate:0, //升级比例
        jizuo_update_version:"0.14",
        jizuo_upgrade_url:'',
        jizuo_version:"1.0.1", //基座版本号
        wheel_version:"1.0.1",  //方向盘版本号
        footrest_version:"1.0.1", //脚踏板版本号
        gear_lever_version:"1.0.1",  //排档板版本号
    };
    componentDidMount() {
        let that=this;
        that.pc_data_sync_from_device(that.props.p12_lite_data)
        that.init_data()

    }
    // 接受path变化
    componentWillReceiveProps(nextprops,nextcontent){
      let that=this;
      let p12_lite_data=nextprops.p12_lite_data
      that.pc_data_sync_from_device(p12_lite_data)
      
    }
    
    //设备数据同步上位机
    pc_data_sync_from_device(p12_lite_data){
      let that=this;
      if(that.state.jizuo_upgrade_rate!=p12_lite_data.jizuo_upgrade_rate||that.state.jizuo_upgrade_status!=p12_lite_data.jizuo_upgrade_status){
        that.setState({
            jizuo_version:p12_lite_data.jizuo_version,
            wheel_version:p12_lite_data.wheel_version,
            footrest_version:p12_lite_data.footrest_version,
            gear_lever_version:p12_lite_data.gear_lever_version,
            wheel_connect_status:p12_lite_data.wheel_connect_status,
            jizuo_connect_status:p12_lite_data.jizuo_connect_status||p12_lite_data.jizuo_upgrade_connect_status||p12_lite_data.jizuo_upgrade_status==2,
            shift_connect_status:p12_lite_data.gear_lever_state,
            footrest_connect_status:p12_lite_data.foot_pedal_state,
            jizuo_upgrade_rate:p12_lite_data.jizuo_upgrade_rate,
            jizuo_upgrade_status:p12_lite_data.jizuo_upgrade_status,
          
          })
      }else{
        that.setState({
            jizuo_version:p12_lite_data.jizuo_version,
            wheel_version:p12_lite_data.wheel_version,
            footrest_version:p12_lite_data.footrest_version,
            gear_lever_version:p12_lite_data.gear_lever_version,
            wheel_connect_status:p12_lite_data.wheel_connect_status,
            jizuo_connect_status:p12_lite_data.jizuo_connect_status||p12_lite_data.jizuo_upgrade_connect_status||p12_lite_data.jizuo_upgrade_status==2,
            shift_connect_status:p12_lite_data.gear_lever_state,
            footrest_connect_status:p12_lite_data.foot_pedal_state,
            jizuo_upgrade_rate:p12_lite_data.jizuo_upgrade_rate,
            jizuo_upgrade_status:p12_lite_data.jizuo_upgrade_status,
          
          })

      }
  
      
      
  }
  init_data(){
    let that=this;
    that.setState({
        jizuo_upgrade_status:1,
        jizuo_update_version:"0.14",
        jizuo_upgrade_url:"http://img.e-pxn.com.cn/FptbxiD-pmeW3fWYS2zr8Kb4WBfG"
    })
    let p12_lite_data=that.props.p12_lite_data
    p12_lite_data.jizuo_upgrade_status=1
    const { dispatch } = that.props;
    dispatch(FX.set_p12_lite_data(p12_lite_data))
    // FN.ajax({
    //     url: 'http://192.168.2.2:5555/content/v1/versionFirmware/getLastVersion?type=1',
    //     data: {}
    // }).
    //     then((res) => {

    //         let jizuo_upgrade_status=0;

    //         // that.props.p12_lite_data.jizuo_version
    //         if(res.versionName!=that.props.p12_lite_data.jizuo_version){
    //             jizuo_upgrade_status=1;
    //         }
    //         that.setState({
    //             jizuo_upgrade_status:jizuo_upgrade_status,
    //             jizuo_update_version:res.versionName,
    //             jizuo_upgrade_url:res.downloadUrl
    //         })
    //         let p12_lite_data=that.props.p12_lite_data
    //         p12_lite_data.jizuo_upgrade_status=jizuo_upgrade_status
    //         const { dispatch } = that.props;
    //         dispatch(FX.set_p12_lite_data(p12_lite_data))

    //     })

  }
//   进行设备更新
    device_upgrade=(cur)=>{
        let that=this;
        let url=that.state.jizuo_upgrade_url
        PY.pxn_jizuo_upgrade(url)
        
        that.setState({
            jizuo_upgrade_status:2,
            jizuo_upgrade_rate:0
        })

        let p12_lite_data=that.props.p12_lite_data
        p12_lite_data.jizuo_upgrade_status=2
        p12_lite_data.jizuo_upgrade_rate=0;
        const { dispatch } = that.props;
        dispatch(FX.set_p12_lite_data(p12_lite_data))

    }

// 对所有设备进行升级
    device_upgrade_all=()=>{
        let that=this;
        if(that.state.jizuo_upgrade_status==1){
            that.device_upgrade(4)
        }
    }

    render() {
        let that=this;
        const lgg_data=LANGUAGE.LGG_DATA[that.props.language_status];
        let wheel_connect_status=that.state.wheel_connect_status
        let footrest_connect_status=that.state.footrest_connect_status;
        let shift_connect_status=that.state.shift_connect_status;
        let jizuo_connect_status=that.state.jizuo_connect_status
        let  jizuo_version=that.state.jizuo_version
        let  wheel_version=that.state.wheel_version
        let  footrest_version=that.state.footrest_version
        let  gear_lever_version=that.state.gear_lever_version

        let jizuo_upgrade_status=that.state.jizuo_upgrade_status
        let wheel_update_status=that.state.wheel_update_status
        let footrest_update_status=that.state.footrest_update_status
        let shift_update_status=that.state.shift_update_status

        // 设备未连接状态
        if(wheel_connect_status==0){
            wheel_update_status=0;
        }
        if(footrest_connect_status==0){
            footrest_update_status=0
        }
        if(shift_connect_status==0){
            shift_update_status=0;
        }
        if(jizuo_connect_status==0){
            jizuo_upgrade_status=0
        }
    //    console.log(jizuo_upgrade_status)
       if(jizuo_upgrade_status!=2){
          if(that.state.jizuo_update_version!=jizuo_version){
            jizuo_upgrade_status=1;
          }else{
            jizuo_upgrade_status=0;
          }
       }
return (
    <div  className="jkw-upgrade-view">
        <div className='jkw-upgrade-main-view'>

            <div className='ugd-column-view-1'>
                <div className='ugd-cl-1-row-view-1 margin-top-55'>
                    <text className='font-ugd-font-1'>{lgg_data.upgrade_label_1}</text>
                    {wheel_connect_status==0?<text className='font-ugd-font-3'>{lgg_data.upgrade_label_2}</text>:<text className='font-ugd-font-2'>{lgg_data.upgrade_label_3}</text> }    
                </div>
                <img src={"/assets/device_wheel_icon.png"} className='jkw-ugd-device-1'/>
                <div className='margin-bottom-55'>
                    <div className='ugd-cl-1-row-view-2'>
                        <text className='font-ugd-font-4 '>{wheel_update_status==0?lgg_data.upgrade_label_4:lgg_data.upgrade_label_5}</text>
                        {wheel_connect_status!=0?<text className='font-ugd-font-5'>V{wheel_version}</text>:''}
                    </div>
                    {wheel_update_status==0?<div className='jkw-ugd-btn-view-2'>{lgg_data.upgrade_label_6}</div>:<div className='jkw-ugd-btn-view-1' onClick={that.device_upgrade.bind(that,1)}>{lgg_data.upgrade_label_7}</div>}
                    
                </div>
                {wheel_connect_status==0?<div className='udg-disconnect-view'></div>:""}
            </div>

            <div className='ugd-column-view-2'>
                <div className='ugd-column-view-2-1'>
                    <div className='ugd-cl-1-row-view-1 ugd-margin-bottom-1 ugd-margin-top-35'>
                        <text className='font-ugd-font-1'>{lgg_data.upgrade_label_8}</text>
                        {shift_connect_status==0?<text className='font-ugd-font-3'>{lgg_data.upgrade_label_2}</text>:<text className='font-ugd-font-2'>{lgg_data.upgrade_label_3}</text> }    
                    </div>
                    <img src={"/assets/setting/setting-device-icon-1.png"} className='jkw-ugd-device-2'/>
                    <div className='ugd-cl-1-row-view-3'>
                        <text className='font-ugd-font-4 ugd-margin-right-10'>{shift_update_status==0?lgg_data.upgrade_label_4:lgg_data.upgrade_label_5}</text>
                        {shift_connect_status!=0?<text className='font-ugd-font-5'>V{gear_lever_version}</text>:''}
                    </div>

                    {shift_update_status==0?<div className='jkw-ugd-btn-view-2'>{lgg_data.upgrade_label_6}</div>:<div className='jkw-ugd-btn-view-1' onClick={that.device_upgrade.bind(that,2)}>{lgg_data.upgrade_label_7}</div>}

                    {shift_connect_status==0?<div className='udg-disconnect-view'></div>:""}
                </div>
                
                <div className='ugd-column-view-2-2'>
                    <div className='ugd-cl-1-row-view-1 ugd-margin-bottom-1 ugd-margin-top-35'>
                        <text className='font-ugd-font-1'>{lgg_data.upgrade_label_9}</text>
                        {footrest_connect_status==0?<text className='font-ugd-font-3'>{lgg_data.upgrade_label_2}</text>:<text className='font-ugd-font-2'>{lgg_data.upgrade_label_3}</text> }    
                    </div>
                    <img src={"/assets/setting/setting-device-icon-2.png"} className='jkw-ugd-device-3'/>
                    <div className='ugd-cl-1-row-view-3 '>
                        <text className='font-ugd-font-4 ugd-margin-right-10' >{footrest_update_status==0?lgg_data.upgrade_label_4:lgg_data.upgrade_label_5}</text>
                        {footrest_connect_status!=0?<text className='font-ugd-font-5'>V{footrest_version}</text>:''}
                    </div>
                    {footrest_update_status==0?<div className='jkw-ugd-btn-view-2'>{lgg_data.upgrade_label_6}</div>:<div className='jkw-ugd-btn-view-1' onClick={that.device_upgrade.bind(that,3)}>{lgg_data.upgrade_label_7}</div>}
                    {footrest_connect_status==0?<div className='udg-disconnect-view'></div>:""}
                </div>
                
            </div>

            {/* 基座 */}
            <div className='ugd-column-view-3'>
                <div className='ugd-cl-1-row-view-1 margin-top-55'>
                    <text className='font-ugd-font-1'>{lgg_data.upgrade_label_10}</text>
                    {jizuo_connect_status==0?<text className='font-ugd-font-3'>{lgg_data.upgrade_label_2}</text>:<text className='font-ugd-font-2'>{lgg_data.upgrade_label_3}</text> }    
                </div>
                <img src={"/assets/setting/setting-device-icon-3.png"} className='jkw-ugd-device-4'/>
                <div className='margin-bottom-55'>
                    <div className='ugd-cl-1-row-view-2'>
                        <text className='font-ugd-font-4'>{jizuo_upgrade_status==0?lgg_data.upgrade_label_4:lgg_data.upgrade_label_5}</text>
                        {jizuo_connect_status!=0?<text className='font-ugd-font-5'>V{jizuo_upgrade_status==0?jizuo_version:that.state.jizuo_update_version}</text>:''}
                    </div>
                    {jizuo_upgrade_status==0?<div className='jkw-ugd-btn-view-2'>{lgg_data.upgrade_label_6}</div>:""}
                    {jizuo_upgrade_status==1?<div className='jkw-ugd-btn-view-1' onClick={that.device_upgrade.bind(that,4)}>{lgg_data.upgrade_label_7}</div>:""}
                    {jizuo_upgrade_status==2?<div className='jkw-ugd-btn-view-3' >
                        <div className='jkw-ugd-btn-view-3-1'></div>
                        <div className='jkw-ugd-btn-view-3-2' style={{width:that.state.jizuo_upgrade_rate+"%"}}></div>
                        <div className='jkw-ugd-btn-view-3-3'>{lgg_data.upgrade_label_11}（{that.state.jizuo_upgrade_rate}%）</div>
                    </div>:""}
                </div> 
                {jizuo_connect_status==0?<div className='udg-disconnect-view'></div>:""}
            </div>
        </div>
        {(jizuo_upgrade_status!=0||footrest_update_status!=0||shift_update_status!=0||wheel_update_status!=0)&&jizuo_upgrade_status!=2?<div className='jkw-upgrade-bottom-view ' onClick={that.device_upgrade_all.bind(that)}> {lgg_data.upgrade_label_12}</div>:''}
    </div>
);
  
    }
}


const mapStateToProps = state => {

    return {
        p12_lite_data:state.p12_lite_data,
        language_status:state.language_status
    }
};
export default connect(mapStateToProps)(Upgrade)



