
import axios from 'axios'
import { Modal, message } from 'antd'
import { SERVER_PATH,ROOT_PATH ,OSS_CLIENT,COS_CLIENT,OSS_UPLOAD_PATH, OSS_UPLOAD_ROOT } from '../config/config'
import cookie from 'react-cookies'
import emoji from 'emoji-regex';
import  { Quill } from 'react-quill';
export function ajax(options) {
    options.data.isShowLoading=false;
    let loading;
    if (options.data && options.data.isShowLoading !== false) {
        loading = document.getElementById('ajaxLoading');
        loading.style.display = 'block';
    }
    return new Promise((resolve, reject) => {
        axios({
            url: options.url,
            method: 'get',
            baseURL: ROOT_PATH,
            timeout: 10000,
            data: (options.data ? options.data : {})
        }).then((response) => {
            if (options.data && options.data.isShowLoading !== false) {
                loading = document.getElementById('ajaxLoading');
                loading.style.display = 'none';
            }
            if (response.status == '200') {
                let res = response.data;
                if(res.downloadUrl){
                    resolve(res);
                }
                else if (res.status == 200) {
                    resolve(res);
                } else {
                    Modal.info({
                        title: "提示",
                        content: res.message
                    })
                }


                
                // if (res.errno == '0') {
                //     resolve(res);
                // } else if(res.errno == '1999'){
                  
                //   cookie.remove("userinfo");
                //   Modal.info({
                //     title: "提示",
                //     content: "登录状态已失效，请重新登录!"
                // })
                // window.location.reload()

                // }else {
                //     Modal.info({
                //         title: "提示",
                //         content: res.errmsg
                //     })
                // }
            } else {
                reject(response.data);
                loading = document.getElementById('ajaxLoading');
                loading.style.display = 'none';
            }
        }).catch((response) => {
            message.error("网络请求失败");
            loading = document.getElementById('ajaxLoading');
            loading.style.display = 'none';
        })
    });
}

// 显示loading
export function show_my_loading(){
    let loading;
    loading = document.getElementById('ajaxLoading');
    loading.style.display = 'block';
}

// 隐藏loading
export function hiddin_my_loading(){
    let loading = document.getElementById('ajaxLoading');
    loading.style.display = 'none';
}


export function getBase64fromurl(imgUrl) {
    window.URL = window.URL || window.webkitURL;
    var xhr = new XMLHttpRequest();
    xhr.open("get", imgUrl, true);
    // 至关重要
    xhr.responseType = "blob";
    return new Promise((resolve, reject) => {
        xhr.onload = function () {
            if (this.status == 200) {
              //得到一个blob对象
              var blob = this.response;
              console.log("blob", blob)
              // 至关重要
              let oFileReader = new FileReader();
              oFileReader.onloadend = function (e) {
                // 此处拿到的已经是 base64的图片了
                let base64 = e.target.result;
                resolve(base64)
              };
              oFileReader.readAsDataURL(blob);
              //====为了在页面显示图片，可以删除====
              var img = document.createElement("img");
              img.onload = function (e) {
                window.URL.revokeObjectURL(img.src); // 清除释放
              };
              let src = window.URL.createObjectURL(blob);
              img.src = src
              //document.getElementById("container1").appendChild(img);
              //====为了在页面显示图片，可以删除====
        
            }
          }
          xhr.send();   
    })
    
  }
// 上传
export function upload(options) {

    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    let loading;
    if (options.data && options.data.isShowLoading !== false) {
        loading = document.getElementById('ajaxLoading');
        loading.style.display = 'block';
    }
    return new Promise((resolve, reject) => {
        axios({
            url: options.url,
            method: 'post',
            baseURL: SERVER_PATH,
            timeout: 30000,
            data: (options.data ? options.data : {})
        }).then((response) => {
            if (options.data && options.data.isShowLoading !== false) {
                loading = document.getElementById('ajaxLoading');
                loading.style.display = 'none';
            }
            if (response.status == '200') {
                let res = response.data;
                if (res.errno == '0') {
                    resolve(res);
                } else if(res.errno == '1999'){
                  
                  cookie.remove("userinfo");
                  Modal.info({
                    title: "提示",
                    content: "登录状态已失效，请重新登录!"
                })
                window.location.reload()

                }else {
                    Modal.info({
                        title: "提示",
                        content: res.errmsg
                    })
                }
            } else {
                reject(response.data);
                loading = document.getElementById('ajaxLoading');
                loading.style.display = 'none';
            }
        }).catch((response) => {
            message.error("网络请求失败");
            loading = document.getElementById('ajaxLoading');
            loading.style.display = 'none';
        })
    });
}

// base64转码
export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

//获取参数
export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]); return null;
    return null;
}

//生成唯一订单号
export function produce_order() {
    let mydate = new Date();
    return "jk" + mydate.getHours() + mydate.getMinutes() + mydate.getSeconds() + mydate.getMilliseconds() + Math.round(1000 + Math.random() * 8000);
}

// 获取文件后缀名
export function get_suffix(filename) {
    let index1 = filename.lastIndexOf(".");
    let index2 = filename.length;
    let suffix = filename.substring(index1 + 1, index2);
    return suffix;
}
// 获取上传文件名
export function get_new_filename(filename) {

    let suffix = get_suffix(filename);
    let new_filename = produce_order();
    let mydate = new Date();
    let month=mydate.getMonth() + 1;
    let day=mydate.getDate()

    if(month<10){
        month="0"+month
    }
    if(day<10){
        day="0"+day
    }
    return mydate.getFullYear()  + month  + day + "/" + new_filename + "." + suffix;
}
// 获取最新时间
export function getDay(day) {
    var today = new Date();
    var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(targetday_milliseconds); //注意，这行是关键代码

    var tDate = today.getDate();
    var tMonth = today.getMonth();

    tMonth = tMonth + 1;
    return tMonth + "/" + tDate;
}

// 判断是否为数字
export function isNumber(val) {
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if (regPos.test(val) || regNeg.test(val)) {
        return true;
    } else {
        return false;
    }
}

// 返回16位字母数字组合
export function filter_string(str,nun=16){
    return str.replace(/[^\w\.\/]/ig,'').substr(0,nun);
}

// 校验手机号
export function check_mobile(phone){
    if(!(/^1[3456789]\d{9}$/.test(phone))){ 
        return false; 
    } 
    return true;
}

// 获取url参数
export function get_url_params(url,par){
    var urlsearch = url.split('?');
   
    if(urlsearch.length<=1) return false;
    let pstr = urlsearch[1].split('&');
    for (var i = pstr.length - 1; i >= 0; i--) {
        var tep = pstr[i].split("=");
        if(tep[0] == par){
            return tep[1];
        }
    }
    return(false);
}	

// 获取url参数
export function get_url_params_from_xhs(url){
    var urlsearch = url.split('/');
    
   
    if(urlsearch.length<=1) return false;
    urlsearch = urlsearch[urlsearch.length-1].split('?');
    return urlsearch[0]
}	


export function get_time(){
    var date = new Date(); 
    var year = date.getFullYear();

    var month = date.getMonth()+1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();

    if (hour < 10) {
        hour = "0" + hour;
    }
    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    if (minute < 10) {
        minute = "0" + minute;
    }
    if (second < 10) {
        second = "0" + second;
    }
    return year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second
   
}

export function get_time_str(){
    return '[ '+get_time()+' ]'
}


// 获取汉字、英文字符总和
export function get_str_total_len(str){
    var len = 0;
    for (var i=0; i<str.length; i++) { 
     var c = str.charCodeAt(i); 
    //单字节加1 
     if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) { 
       len++; 
     } 
     else { 
      len+=2; 
     } 
    } 
    
    return parseInt(len/2);
}

// 校验非法字符与emoji
export function check_emoji(str){
    // if(str.find())
    if(str.indexOf("#") != -1||str.indexOf("@") != -1){
        return true;
    }
    let match = emoji().exec(str);
    if(match!=null) return true;
    return false;

}

// 获取返回数据
export function page_back(){
    window.history.go(-1);
}

// 老版本编辑器图片上传oss  
export function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {

            let new_filename = OSS_UPLOAD_PATH + get_new_filename(file.name);

            COS_CLIENT.putObject({
                Bucket: 'deyu-1306633787', 
                Region: 'ap-beijing',  
                Key:  new_filename,
                StorageClass: 'STANDARD',
                Body: file
            }, function (err, data) {
                if(err){
                    console.log(err, data);  
                }else{
                    // 成功
                    resolve({
                        data: {
                            link: OSS_UPLOAD_ROOT + new_filename
                        }
                    })
                }
            });
        });

}

// 阿里云上传图片
export function upload_img(file){
    return new Promise((resolve, reject) => {
        if(file.type!="image/jpeg"&&file.type!="image/png"){
            message.error("图片仅支持jpg、png格式")
            reject()
        }
        if (file.size/1024/1024>2) {
            message.error("图片大小仅支持2MB以内！");
            reject()
        }
        let new_filename = get_new_filename(file.name);
        OSS_CLIENT.multipartUpload(OSS_UPLOAD_PATH + new_filename, file, {
            progress: function (percentage, checkpoint, res) {
            }
        }).then(function (result) {
            let image_url=OSS_UPLOAD_ROOT + OSS_UPLOAD_PATH + new_filename
            resolve(image_url);
        }).catch(function (err) {
            Modal.info({
                title: "提示",
                content: err.message
            })
        });

    })
      
}

// 腾讯云上传图片
export function tx_upload_img(file){
    return new Promise((resolve, reject) => {
        console.log(file)
        if(file.type!="image/jpeg"&&file.type!="image/png"){
            message.error("图片仅支持jpg、png格式")
            reject()
        }
        if (file.size/1024/1024>2) {
            message.error("图片大小仅支持2MB以内！");
            reject()
        }
        let new_filename = get_new_filename(file.name);
        COS_CLIENT.putObject({
            Bucket: 'deyu-1306633787', 
            Region: 'ap-beijing',  
            Key: OSS_UPLOAD_PATH + new_filename,
            StorageClass: 'STANDARD',
            Body: file
        }, function (err, data) {
            if(err){
                console.log(err, data);  
            }else{
                let image_url=OSS_UPLOAD_ROOT + OSS_UPLOAD_PATH + new_filename
                resolve(image_url);
            }
        });
    })
      
}

// react-quill 图片插入
export function reactquill_image(quill,file){
    // console.log(quill)
    tx_upload_img(file).then((url)=>{
        const cursorPosition =quill.getSelection().index;//获取当前光标位置
        quill.insertEmbed(cursorPosition, "image",url, Quill.sources.USER);//插入图片
        quill.setSelection(cursorPosition + 1);//光标位置加1
    })
}


// 封装数字去0
export function clear_zero(num){
    return parseFloat(Number(num).toFixed(2))
}


//操作防抖
export function  anti_shake(){
   
    return new Promise((resolve,reject)=>{
        if( window.JK_ANTI_SHAKE_TIMER){
            console.log("触发防抖")
            clearTimeout(window.JK_ANTI_SHAKE_TIMER);
   
          }
          window.JK_ANTI_SHAKE_TIMER=setTimeout(()=>{
                resolve();
            },200);
    })

}

//节流s
export function  reduce(time){
   
    return new Promise((resolve,reject)=>{
        if( window.JK_REDUCE_TIMER){
            console.log("触发节流")
            clearTimeout(window.JK_REDUCE_TIMER);
            window.JK_REDUCE_TIMER=undefined
            reject()
          }
          window.JK_REDUCE_TIMER=setTimeout(()=>{
            if( window.JK_REDUCE_TIMER){
                clearTimeout(window.JK_REDUCE_TIMER);
              }
              window.JK_REDUCE_TIMER=undefined
            resolve();
      
            },time);
    })

}

//操作防抖
export function  anti_shake_3000(){
   
    return new Promise((resolve,reject)=>{
        if( window.JK_ANTI_SHAKE_TIMER_3000){
            console.log("触发防抖3000")
            clearTimeout(window.JK_ANTI_SHAKE_TIMER_3000);

          }
          window.JK_ANTI_SHAKE_TIMER_3000=setTimeout(()=>{
                
                if( window.JK_ANTI_SHAKE_TIMER_3000){
                    clearTimeout(window.JK_ANTI_SHAKE_TIMER_3000);
                  }
                  window.JK_ANTI_SHAKE_TIMER_3000=undefined
                 resolve();
            },3000);
    })

}
// 封装键盘连线映射
export function pxn_map_line(ctx, fromX, fromY, toX, toY) {

    let tmp_del=8
    if(fromY<toY){
        toY=toY-tmp_del
    }else{
        toY=toY+tmp_del
    }

    if(fromX<toX){
        toX=toX-tmp_del
    }else{
        toX=toX+tmp_del
    }
    // toX=toX-5
    // toY=toY-5
    let color="#ED7020" 
   var headlen = 10; //自定义箭头线的长度
   var theta = 30; //自定义箭头线与直线的夹角，个人觉得45°刚刚好
   var arrowX, arrowY; //箭头线终点坐标
   // 计算各角度和对应的箭头终点坐标
   var angle = Math.atan2(fromY - toY, fromX - toX) * 180 / Math.PI;
   var angle1 = (angle + theta) * Math.PI / 180;
   var angle2 = (angle - theta) * Math.PI / 180;
   var topX = headlen * Math.cos(angle1);
   var topY = headlen * Math.sin(angle1);
   var botX = headlen * Math.cos(angle2);
   var botY = headlen * Math.sin(angle2);
   ctx.beginPath();
   ctx.lineWidth=3
   //画直线
   ctx.moveTo(fromX, fromY);
   ctx.lineTo(toX, toY);

   arrowX = toX + topX;
   arrowY = toY + topY;
   //画上边箭头线
   ctx.moveTo(arrowX, arrowY);
   ctx.lineTo(toX, toY);

   arrowX = toX + botX;
   arrowY = toY + botY;
   //画下边箭头线
   ctx.lineTo(arrowX, arrowY);

   ctx.strokeStyle = color;
   ctx.stroke();
}

// 封装键盘连线映射
export function canvas_clear(ctx, width, height) {
    ctx.clearRect(0,0,width,height)

}
// 键盘映射值
export function pxn_map_to_str(hex){
    let that=this
if (hex==0x40)
    return "a and A"
else if( hex==0x41)
    return "b and B"
else if( hex==0x42)
    return "c and C"
else if( hex==0x43)
    return "d and D"
else if( hex==0x44)
    return "e and E"
else if( hex==0x45)
    return "f and F"
else if( hex==0x46)
    return "g and G"
else if( hex==0x47)
    return "h and H"
else if( hex==0x48)
    return "i and I"
else if( hex==0x49)
    return "j and J"
else if( hex==0x4A)
    return "k and K"
else if( hex==0x4B)
    return "l and L"
else if( hex==0x4C)
    return "m and M"
else if( hex==0x4D)
    return "n and N"
else if( hex==0x4E)
    return "o and O"
else if( hex==0x4F)
    return "p and P"
else if( hex==0x50)
    return "q and Q"
else if( hex==0x51)
    return "r and R"
else if( hex==0x52)
    return "s and S"
else if( hex==0x53)
    return "t and T"
else if( hex==0x54)
    return "u and U"
else if( hex==0x55)
    return "v and V"
else if( hex==0x56)
    return "w and W"
else if( hex==0x57)
    return "x and X"
else if( hex==0x58)
    return "y and Y"
else if( hex==0x59)
    return "z and Z"
else if( hex==0x5A)
    return "1 and !"
else if( hex==0x5B)
    return "2 and @"
else if( hex==0x5C)
    return "3 and #"
else if( hex==0x5D)
    return "4 and $"
else if( hex==0x5E)
    return "5 and %"
else if( hex==0x5F)
    return "6 and ^"
else if( hex==0x60)
    return "7 and &"
else if( hex==0x61)
    return "8 and *"
else if( hex==0x62)
    return "9 and ("
else if( hex==0x63)
    return "0 and )"
else if( hex==0x64)
    return "F1"
else if( hex==0x65)
    return "F2"
else if( hex==0x66)
    return "F3"
else if( hex==0x67)
    return "F4"
else if( hex==0x68)
    return "F5"
else if( hex==0x69)
    return "F6"
else if( hex==0x6A)
    return "F7"
else if( hex==0x6B)
    return "F8"
else if( hex==0x6C)
    return "F9"
else if( hex==0x6D)
    return "F10"
else if( hex==0x6E)
    return "F11"
else if( hex==0x6F)
    return "F12"
else if( hex==0x70)
    return "ESC"
else if( hex==0x71)
    return "~"
else if( hex==0x72)
    return "-"
else if( hex==0x73)
    return "="
else if( hex==0x74)
    return "TAB"
else if( hex==0x75)
    return "Caps Lock"
else if( hex==0x76)
    return "Left Control"
else if( hex==0x77)
    return "Left Shift"
else if( hex==0x78)
    return "Left Alt"
else if( hex==0x79)
    return "Left Win"
else if( hex==0x7A)
    return "Right Control"
else if( hex==0x7B)
    return "Right Shift"
else if( hex==0x7C)
    return "Right Alt"
else if( hex==0x7D)
    return "Right Win"
else if( hex==0x7E)
    return "Enter"
else if( hex==0x7F)
    return "Backspace"
else if( hex==0x80)
    return "[ and {"
else if( hex==0x81)
    return "] and }"
else if( hex==0x82)
    return "\ and |"
else if( hex==0x83)
    return "; and :"
else if( hex==0x84)
    return "‘ and “"
else if( hex==0x85)
    return ", and <"
else if( hex==0x86)
    return ". and >"
else if( hex==0x87)
    return "/ and ?"
else if( hex==0x88)
    return "Space"
else if( hex==0x89)
    return "↑"
else if( hex==0x8A)
    return "↓"
else if( hex==0x8B)
    return "←"
else if( hex==0x8C)
    return "→"
else if( hex==0x8D)
    return "/"
else if( hex==0x8E)
    return "*"
else if( hex==0x8F)
    return "-"
else if( hex==0x90)
    return "+"
else if( hex==0x91)
    return "Enter"
else if( hex==0x92)
    return "."
else if( hex==0x93)
    return "0"
else if( hex==0x94)
    return "1"
else if( hex==0x95)
    return "2"
else if( hex==0x96)
    return "3"
else if( hex==0x97)
    return "4"
else if( hex==0x98)
    return "5"
else if( hex==0x99)
    return "6"
else if( hex==0x9A)
    return "7"
else if( hex==0x9B)
    return "8"
else if( hex==0x9C)
    return "9"
else if( hex==0x00)
    return "View"
else if( hex==0x01)
    return "L3"
else if( hex==0x02)
    return "R3"
else if( hex==0x03)
    return "Menu"
else if( hex==0x04)
    return "Up"
else if( hex==0x05)
    return "Right"
else if( hex==0x06)
    return "Down"
else if( hex==0x07)
    return "Left"
else if( hex==0x08)
    return "Y"
else if( hex==0x09)
    return "B"
else if( hex==0x0A)
    return "A"
else if( hex==0x0B)
    return "X"
else if( hex==0x0C)
    return "L1"
else if( hex==0x0D)
    return "R1"
else if( hex==0x0E)
    return "L2"
else if( hex==0x0F)
    return "R2"
else if( hex==0x10)
    return "Home"
else if( hex==0x11)
    return "Share"
else if( hex==0x12)
    return "左encoder按键"
else if( hex==0x13)
    return "右encoder按键"
else if( hex==0x14)
    return "Accelerator"
else if( hex==0x15)
    return "Brake"
else if( hex==0x16)
    return "Clutch"
else if( hex==0x17)
    return "GU"
else if( hex==0x18)
    return "GD"
else if( hex==0x19)
    return "Hand Brake"
else if( hex==0x1A)
    return "Gear Switch"
else if( hex==0x1B)
    return "Gear 1"
else if( hex==0x1C)
    return "Gear 2"
else if( hex==0x1D)
    return "Gear 3"
else if( hex==0x1E)
    return "Gear 4"
else if( hex==0x1F)
    return "Gear 5"
else if( hex==0x20)
    return "Gear 6"
else if( hex==0x21)
    return "Gear R"
else if( hex==0x22)
    return "Lx-"
else if( hex==0x23)
    return "Lx+"
else if( hex==0x24)
    return "左上拨片"
else if( hex==0x25)
    return "右上拨片"
else if( hex==0x26)
    return "左下拨片"
else if( hex==0x27)
    return "右下拨片"
else if( hex==0x28)
    return "Brake编码器按键"
else if( hex==0x29)
    return "Brake编码器左转"
else if( hex==0x2A)
    return "Brake编码器右转"
else if( hex==0x2B)
    return "TC编码器按键"
else if( hex==0x2C)
    return "TC编码器左转"
else if( hex==0x2D)
    return "TC编码器右转"
}