// 全局配置初始化函数,将加载到window下
export function sys_cfg_init(){
    //***************************基本配置 begin***************************
    window.MODE_THEME="light";//应用默认主题  light  dark
    window.LGG_STATUS="jp";//应用默认语言  en_us zh_cn  jp
    window.PHONE_TYPE="android";//设备机型
    window.IS_FOLD=false;//是否为折叠屏
    window.IS_PAD=false;//是否为平板
    window.IS_PORTRAIT=false;//是否为竖屏
    // 防抖
    window.JK_ANTI_SHAKE_TIMER=undefined;
    window.JK_ANTI_SHAKE_TIMER_3000=undefined
    window.JK_REDUCE_TIMER=undefined
    // 窗口移动
    window.JKW_MOVE_STATU=0;  
    // p12-lite 数据同步专用定时器
    window.PXN_P12_LITE_DEVICE_INFO_TIMER=undefined
    // 键盘监听 定时器
    window.KEYBOARD_TIMER=undefined
    // PC更新参数函数
    window.PC_UPDATE_FN=undefined
    // slider动态特效
    window.FOOTREST_EFFECT_UPDATE_TIMER=undefined

    window.footrest_char_1=undefined;
    window.footrest_char_2=undefined;
    window.footrest_char_3=undefined;

    //***************************基本配置 end***************************

    

    
}