import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as FX from '../../redux/action'
import * as CONFIG from '../../config/config';
import * as FN  from '../../utils/index'
import * as PY from '../../utils/pywebview'
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
    Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Popover,Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
  } from 'antd';

  const { confirm } = Modal;
class Shift_lever extends React.Component {
   
    state = {  
        gear_lever_state:0,
        /********************一键设置相关  begin********************** */
        map_setting_gov_data:[],
        map_setting_custom_data:[],
        map_setting_custom_index:-1,
        map_setting_gov_index:-1,
        map_setting_gov_select_index:-1,
        map_setting_custom_select_index:-1,
        setting_mode:1,  //1-官方推荐 ,2-自定义
        custom_rename_modal_visible:false,
        custom_rename_key:undefined, //自定义名称输入值
        custom_rename_modal_flag:1, //1-新增,2-修改
        /********************一键设置相关  end********************** */

        wheel_active_status:0,  //0-未选中,1-28选中
        wheel_input_refresh_visible:1,//0-隐藏,1-显示
        wheel_input_hex:0x00, //协议16进制值
        wheel_map_hex_pre:[],  //当前选中模式的hex值
        wheel_input_key:'', //当前输入值
        preview_wheel_map_key:[],
        preview_wheel_map_hex_pre:[],
        wheel_map_key:['-1','Gear 1', 'Gear 2', 'Gear 3', 'Gear 4', 'Gear 5', 'Gear 6', 'Gear R', 'Gear Switch', 'Hand Brake'],
        wheel_map_key_tpl:['-1','Gear 1', 'Gear 2', 'Gear 3', 'Gear 4', 'Gear 5', 'Gear 6', 'Gear R', 'Gear Switch' ,'Hand Brake', ],
        
        wheel_map_hex_tpl:[0, 27, 28, 29, 30, 31, 32, 33, 26, 25],
        
    };
 // 组件卸载
 componentWillUnmount(){
    let that=this;
    if(Window.KEYBOARD_TIMER){
        clearInterval(Window.KEYBOARD_TIMER)
    }
}

//设备数据同步上位机
pc_data_sync_from_device(p12_lite_data){
    let that=this;

    if(!that.state.gear_lever_state&&p12_lite_data.gear_lever_state){

        that.setState({
            gear_lever_state:p12_lite_data.gear_lever_state,
            wheel_map_key:p12_lite_data.p12_shift_lever_str,
            wheel_map_hex_pre:p12_lite_data.p12_shift_lever_hex  //键盘映射16进制
        })
    }else if(p12_lite_data.gear_lever_state==1&&p12_lite_data.pxn_update_status==1){
        that.setState({
            gear_lever_state:p12_lite_data.gear_lever_state,
            wheel_map_key:p12_lite_data.p12_shift_lever_str,
            wheel_map_hex_pre:p12_lite_data.p12_shift_lever_hex  //键盘映射16进制
        })
    }
    that.setState({
        gear_lever_state:p12_lite_data.gear_lever_state
    })
}


// 接受path变化
componentWillReceiveProps(nextprops,nextcontent){
    let that=this;
    let p12_lite_data=nextprops.p12_lite_data

    // 防止数据刷新过快
    if(window.JK_ANTI_SHAKE_TIMER_3000){
        return;
    }
    that.pc_data_sync_from_device(p12_lite_data)

  }
componentDidMount() {
    let that=this
    that.pc_data_sync_from_device(that.props.p12_lite_data)
    that.init_data();
  // 获取缓存数据  断开后首次连接成功
    let handle=PY.pxn_user_config_get("shift_lever")
    if(handle){
        handle.then((rst_data)=>{
            that.setState({
                map_setting_custom_data:rst_data.data
            })

        })
    }
}

// 初始化数据
init_data(){
    let that=this;
    FN.ajax({
        url: 'https://api.e-pxn.com.cn/mobile/v1/keyboard/getKeyboard?pageNum=1&pageSize=200&status=1&type=1&driveType=18',
        data: {}
    }).
        then((res) => {
            let rst_data=res.content.list
            let goal_data=[]
            for(let i=0;i<rst_data.length;i++){
                if(rst_data[i].keboardValue.length==20){
                    goal_data.push(rst_data[i])
                }
                
            }
            // goal_data
            that.setState({
                map_setting_gov_data:goal_data
            })

        })
}



/********************一键设置相关  begin********************** */

// 16进制转字符串
pxn_hex_to_str(str,mode=1){
    let that=this
    let goal_data=[0]
    for(let i=3;i<10;i++){
        goal_data.push(parseInt(str[i*2]+""+str[i*2+1],16))
    }
    goal_data.push(parseInt(str[2*2]+""+str[2*2+1],16))
    goal_data.push(parseInt(str[1*2]+""+str[1*2+1],16))

    let map_str_data=["-1","-1","-1","-1","-1","-1","-1","-1","-1","-1"]

    for(let i=1;i<10;i++){
        map_str_data[i]=FN.pxn_map_to_str(goal_data[i])
    }
    that.setState({
        preview_wheel_map_key:map_str_data,
        preview_wheel_map_hex_pre:goal_data
    })

    if(mode==2){
        message.success("操作成功!")
        // 触发应用到设备
        that.setState({
            wheel_map_key:map_str_data,
            wheel_map_hex_pre:goal_data
        })

        PY.pxn_shift_lever_map_set(goal_data)

        // 更新本地数据
        let p12_lite_data=that.props.p12_lite_data
        p12_lite_data.p12_shift_lever_str=map_str_data
        p12_lite_data.p12_shift_lever_hex=goal_data
        const { dispatch } = that.props;
        dispatch(FX.set_p12_lite_data(p12_lite_data))
        // 
        
    }

}
// 监听模式切换
change_setting_map=(cur)=>{
let that=this;
that.setState({
    setting_mode:cur,
    map_setting_gov_select_index:-1,
            map_setting_custom_select_index:-1,
            map_setting_gov_index:-1,
            map_setting_custom_index:-1
})
}
// 监听更新一键设置组件下标
change_map_setting_index=(index,mode,status)=>{
    let that=this
    //已选择条件下,忽略监听
    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_custom_select_index!=-1) return ;
    if(mode==1){
        // 官方
            if(status==1){
            
            
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,

                map_setting_gov_index:index
            })
            that.pxn_hex_to_str(that.state.map_setting_gov_data[index].keboardValue)
            }else{
            that.setState({
                map_setting_gov_index:-1
            })
            }
        
    }else if(mode==2){
        //自定义
        if(status==1){
            let map_setting_custom_data=that.state.map_setting_custom_data
            let preview_wheel_map_hex_pre=[].concat(map_setting_custom_data[index].hex)
            let preview_wheel_map_key=[].concat(map_setting_custom_data[index].str)
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_custom_index:index,
                preview_wheel_map_hex_pre:preview_wheel_map_hex_pre,
                preview_wheel_map_key:preview_wheel_map_key,
             
            })
            
            }else{
            that.setState({
                map_setting_custom_index:-1
            })
            }
    }
}

//获取设置模式选中状态
change_map_setting_select=(mode,index)=>{
        let that=this;
        if(mode==1){
        if(that.state.map_setting_gov_select_index!=index){

            that.setState({
                map_setting_gov_select_index:index,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1,
                map_setting_custom_select_index:-1,
                })

                that.pxn_hex_to_str(that.state.map_setting_gov_data[index].keboardValue)
            }else{
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1

            })
            }
        }else if(mode==2){
        if(that.state.map_setting_custom_select_index!=index){
            let map_setting_custom_data=that.state.map_setting_custom_data
            let preview_wheel_map_hex_pre=[].concat(map_setting_custom_data[index].hex)
            let preview_wheel_map_key=[].concat(map_setting_custom_data[index].str)
            that.setState({
                map_setting_custom_select_index:index,
                map_setting_gov_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1,
                preview_wheel_map_hex_pre:preview_wheel_map_hex_pre,
                preview_wheel_map_key:preview_wheel_map_key,
   
                })


            
            }else{
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1
            })
            }
        }
            
}

// 应用模式 官方推荐
map_setting_apply=()=>{
    let that=this
    let map_setting_gov_select_index=that.state.map_setting_gov_select_index
    let map_setting_gov_data=that.state.map_setting_gov_data
    let map_key_str=map_setting_gov_data[map_setting_gov_select_index].keboardValue
    confirm({
        title: '确定应用该模式?',
        icon: <ExclamationCircleFilled />,
        content: null,
        okText:'确认',
        cancelText:'取消',
        onOk() {
            that.pxn_hex_to_str(map_key_str,2)
            that.setState({
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1
            })
            console.log('OK');
        },
        onCancel() {
            console.log('Cancel');
        },
        });
}
    
// 应用自定义模式
map_setting_custom_apply=()=>{
let that=this
let map_setting_custom_data=that.state.map_setting_custom_data
let index=that.state.map_setting_custom_select_index

confirm({
    title: '确定应用该模式?',
    icon: <ExclamationCircleFilled />,
    content: null,
    okText:'确认',
    cancelText:'取消',
    onOk() {
        that.setState({
            wheel_map_hex_pre:map_setting_custom_data[index].hex,
            wheel_map_key:map_setting_custom_data[index].str,

            map_setting_gov_select_index:-1,
            map_setting_custom_select_index:-1,
            map_setting_gov_index:-1,
            map_setting_custom_index:-1
        })
        PY.pxn_shift_lever_map_set(map_setting_custom_data[index].hex)
          // 更新本地数据
          let p12_lite_data=that.props.p12_lite_data
          p12_lite_data.p12_shift_lever_str=map_setting_custom_data[index].str
          p12_lite_data.p12_shift_lever_hex=map_setting_custom_data[index].hex
          const { dispatch } = that.props;
          dispatch(FX.set_p12_lite_data(p12_lite_data))
        message.success("操作成功!")
    },
    onCancel() {
        console.log('Cancel');
    },
    });

}

// 重命名自定义模式
map_setting_custom_rename=()=>{
let that=this
if(!that.state.custom_rename_key){
    message.error("输入不能为空!")
    return;
}
let map_setting_custom_data=that.state.map_setting_custom_data
let index=that.state.map_setting_custom_select_index
map_setting_custom_data[index].name=that.state.custom_rename_key
that.setState({
    map_setting_custom_data:map_setting_custom_data,
    map_setting_gov_select_index:-1,
    map_setting_custom_select_index:-1,
    map_setting_gov_index:-1,
    map_setting_custom_index:-1
})
// 更新数据到设备
PY.pxn_user_config_set("shift_lever",map_setting_custom_data);

// 关闭窗口
that.custom_rename_modal_hidden();
message.success("操作成功!")
}

// 保存自定义模式
map_setting_custom_save=()=>{
let that=this
let map_setting_custom_data=that.state.map_setting_custom_data
let index=that.state.map_setting_custom_select_index
map_setting_custom_data[index].hex=that.state.preview_wheel_map_hex_pre
map_setting_custom_data[index].str=that.state.preview_wheel_map_key



confirm({
    title: '确定保存该模式?',
    icon: <ExclamationCircleFilled />,
    content: null,
    okText:'确认',
    cancelText:'取消',
    onOk() {
        that.setState({
            map_setting_gov_select_index:-1,
            map_setting_custom_select_index:-1,
            map_setting_gov_index:-1,
            map_setting_custom_index:-1
        })
        // 更新数据到设备
        PY.pxn_user_config_set("shift_lever",map_setting_custom_data);
        // 关闭窗口
        that.custom_rename_modal_hidden();
        message.success("操作成功!")
    },
    onCancel() {
        console.log('Cancel');
    },
    });
}

// 删除自定义模式
map_setting_custom_del=(index,e)=>{
    let that=this
    // console.log(index)
    e.stopPropagation();
    that.setState({
        map_setting_gov_select_index:-1,
        map_setting_custom_select_index:index,
        map_setting_gov_index:-1,
        map_setting_custom_index:-1
    })
    let map_setting_custom_data=that.state.map_setting_custom_data
    let map_key_str=map_setting_custom_data[index]
    confirm({
        title: '确定删除该模式?',
        icon: <ExclamationCircleFilled />,
        content: null,
        okText:'确认',
        cancelText:'取消',
        onOk() {
            map_setting_custom_data.splice(index,1)
            console.log(map_setting_custom_data)
            that.setState({
                map_setting_custom_data:map_setting_custom_data,
                map_setting_gov_select_index:-1,
                map_setting_custom_select_index:-1,
                map_setting_gov_index:-1,
                map_setting_custom_index:-1
            })
            // 更新数据
            PY.pxn_user_config_set("shift_lever",map_setting_custom_data)
            message.success("操作成功!")
        },
        onCancel() {
        },
        });
}

// 新增自定义模式
map_setting_custom_add=()=>{
    let that=this;
    if(!that.state.custom_rename_key){
        message.error("输入不能为空!")
        return;
    }
    let data={}
    data.name=that.state.custom_rename_key

    // 获取当前str
    data.str=that.state.wheel_map_key
    // 获取当前hex
    data.hex=that.state.wheel_map_hex_pre
    let map_setting_custom_data=that.state.map_setting_custom_data
    map_setting_custom_data.unshift(data)
    that.setState({
        map_setting_custom_data:map_setting_custom_data
    })

    // 更新数据到设备
    let tmp_handle= PY.pxn_user_config_set("shift_lever",map_setting_custom_data);
    if(tmp_handle){
        tmp_handle.then((rst)=>{
            // message.success("操作成功!")
        })
    }
    // 关闭窗口
    that.custom_rename_modal_hidden();
    message.success("操作成功!")
    // console.log(data)
}

//隐藏修改名称窗口 
custom_rename_modal_hidden=()=>{
let that=this
that.setState({
    custom_rename_modal_visible:false,
    custom_rename_key:undefined
})
}
//显示修改名称窗口 
custom_rename_modal_show=(flag)=>{
let that=this
if(flag==1){
    // 新增
    that.setState({
        custom_rename_modal_visible:true,
        custom_rename_modal_flag:flag,
        custom_rename_key:undefined
    })
}else{
    let map_setting_custom_data=that.state.map_setting_custom_data
    let index=that.state.map_setting_custom_select_index
    let custom_rename_key=map_setting_custom_data[index].name
    // 更新
    that.setState({
        custom_rename_modal_visible:true,
        custom_rename_modal_flag:flag,
        custom_rename_key:custom_rename_key
    })
}

}
//监听值变化 
change_custom_rename_key(e){
const { value } = e.target;
this.setState({
    custom_rename_key: value
})
}

/********************一键设置相关  end********************** */
// 开始监听
keyboard_monitor_start(){
    let that=this
    that.keyboard_monitor_stop();
    // 初始化键盘值

    Window.KEYBOARD_TIMER=setInterval(()=>{
        let handle=PY.keyboard_get()
        if(handle==false) return;
        handle.then((rst)=>{
            if(rst.data!=-1){
                if(Window.KEYBOARD_TIMER){
                    clearInterval(Window.KEYBOARD_TIMER)
                }
                
                that.setState({
                    wheel_input_key:rst.data,
                    wheel_input_hex:rst.hex,
                    wheel_input_refresh_visible:1
                })
                if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
                {

                }else{
                        // 设置键盘值
                    PY.pxn_shift_lever_map_set_one(that.state.wheel_active_status,rst.hex)
                }
            
            }
        })

    },25)

        
}
// 停止监听
keyboard_monitor_stop(){
    let that=this;
    if(Window.KEYBOARD_TIMER){
        clearInterval(Window.KEYBOARD_TIMER)
    }
    PY.keyboard_init();
    
}
//清除输入数据
clear_map_input=()=>{
    let that=this;



    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
    {
        let wheel_map_key=that.state.preview_wheel_map_key
        let wheel_map_hex_tpl=that.state.wheel_map_hex_tpl
        that.keyboard_monitor_start(); 
        wheel_map_key[that.state.wheel_active_status]=that.state.wheel_map_key_tpl[that.state.wheel_active_status]
        
        that.setState({
            wheel_input_key:'',
            preview_wheel_map_key:wheel_map_key,
            wheel_input_refresh_visible:0
        })
    }else{
        let wheel_map_key=that.state.wheel_map_key
        let wheel_map_hex_tpl=that.state.wheel_map_hex_tpl
        that.keyboard_monitor_start(); 
        wheel_map_key[that.state.wheel_active_status]=that.state.wheel_map_key_tpl[that.state.wheel_active_status]
        
        that.setState({
            wheel_input_key:'',
            wheel_map_key:wheel_map_key,
            wheel_input_refresh_visible:0
        })
        // 设置键盘值
        PY.pxn_shift_lever_map_set_one(that.state.wheel_active_status,wheel_map_hex_tpl[that.state.wheel_active_status])
    }

}
//监听设置激活按钮的下标
change_map_active_cur=(cur,status,e)=>{

    let canvas = document.getElementById('shift-canvas-1');
  
    
    let ctx = canvas.getContext('2d');
   
    // console.log(status)
    let current_dom=e.currentTarget
    
    
   
    let that=this
    let wheel_map_key=that.state.wheel_map_key
    if(status==0){
        let wheel_map_key
        if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
        {
            wheel_map_key=that.state.preview_wheel_map_key
        }else{
            wheel_map_key=that.state.wheel_map_key
        }
        that.keyboard_monitor_stop();

        FN.canvas_clear(ctx,canvas.width,canvas.height)
        if(that.state.wheel_input_key){
            wheel_map_key[that.state.wheel_active_status]=that.state.wheel_input_key
        }

        if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1)
            {
                that.setState({
   
                    wheel_active_status:0,
                    wheel_input_refresh_visible:0,
                    preview_wheel_map_key:wheel_map_key,
                    wheel_input_key:''
                }) 
            }else{
                that.setState({
   
                    wheel_active_status:0,
                    wheel_input_refresh_visible:0,
                    wheel_map_key:wheel_map_key,
                    wheel_input_key:''
                }) 
     
            }
            
    }else{


        let root_dom=document.getElementsByClassName("jkw-wheel-view-shift-lever")[0]
            //     // 更新canvas大小
        canvas.width=root_dom.clientWidth
        canvas.height=root_dom.clientHeight
    //计算连线相关参数
        // 获取当前按键位置
        let c_x=0
        let c_y=0
        //获取设备图片对应位置
        let g_dom,g_x,g_y;
        let g_dom_p1,g_dom_p2,g_dom_p3;
        g_dom=document.getElementById('jkw-shift-pos-'+cur);
        g_x=g_dom.offsetLeft+g_dom.clientWidth/2
        g_y=g_dom.offsetTop+g_dom.clientHeight/2
        g_dom_p1=g_dom.offsetParent;
        g_dom_p2=g_dom_p1.offsetParent;
        g_dom_p3=g_dom_p2.offsetParent;

        if(cur>=8){
            let p_dom=current_dom.offsetParent

            c_x=current_dom.offsetLeft+p_dom.offsetLeft
            c_y=current_dom.offsetTop+current_dom.clientHeight/2+p_dom.offsetTop
            g_x=g_dom_p3.offsetLeft+g_x
            g_y=g_dom_p3.offsetTop+g_y
        }else if(cur==1||cur==3||cur==5){
            c_x=current_dom.offsetLeft+current_dom.clientWidth/2
            c_y=current_dom.offsetTop+current_dom.clientHeight

        }else if(cur==2||cur==4||cur==6||cur==7){
            c_x=current_dom.offsetLeft+current_dom.clientWidth/2
            c_y=current_dom.offsetTop
            
        }

 
        
        
        
        
        g_x=g_x+g_dom_p1.offsetLeft+g_dom_p2.offsetLeft
        g_y=g_y+g_dom_p1.offsetTop+g_dom_p2.offsetTop
        // console.log(g_x,g_y,c_x,c_y)
        FN.pxn_map_line(ctx,g_x,g_y,c_x,c_y)
        //鼠标进入
        that.setState({

            wheel_active_status:cur,
            wheel_input_refresh_visible:0
        },()=>{
            that.keyboard_monitor_start();
            
        })     
    }
}

// 键盘设置显示组件
map_display_view=(cur)=>{


    // wheel_map_key_tpl:['-1','Gear 1', 'Gear 2', 'Gear 3', 'Gear 4', 'Gear 5', 'Gear 6', 'Gear R', 'Hand Brake', 'Gear Switch'],



    let that=this
    let wheel_map_key=that.state.wheel_map_key

//    判断当前状态是否为预览
    if(that.state.map_setting_gov_select_index!=-1||that.state.map_setting_gov_index!=-1||that.state.map_setting_custom_index!=-1||that.state.map_setting_custom_select_index!=-1){
        wheel_map_key=that.state.preview_wheel_map_key
    }


    if(wheel_map_key[cur]=="Gear 1"){
        return <div className='jkw-mapping-font-2-view'>档 1</div>
    }else if(wheel_map_key[cur]=="Gear 2"){
        return <div className='jkw-mapping-font-2-view'>档 2</div>
    }else if(wheel_map_key[cur]=="Gear 3"){
        return <div className='jkw-mapping-font-2-view'>档 3</div>
    }else if(wheel_map_key[cur]=="Gear 4"){
        return <div className='jkw-mapping-font-2-view'>档 4</div>
    }else if(wheel_map_key[cur]=="Gear 5"){
        return <div className='jkw-mapping-font-2-view'>档 5</div>
    }else if(wheel_map_key[cur]=="Gear 6"){
        return <div className='jkw-mapping-font-2-view'>档 6</div>
    }else if(wheel_map_key[cur]=="Gear R"){
        return <div className='jkw-mapping-font-2-view'>倒挡</div>
    }else if(wheel_map_key[cur]=="Hand Brake"){
        return <img src="/assets/shift_lever/shift_lever_icon_2.png" className='jkw-icon-24'/>
    }else if(wheel_map_key[cur]=="Gear Switch"){
        return <img src="/assets/shift_lever/shift_lever_icon_1.png" className='jkw-icon-52-20'/>
    }
    return <div className='jkw-mapping-font-2-view'>{wheel_map_key[cur]}</div>
}


    render() {
        let that=this;

        //  获取官方推荐
        let map_setting_gov_view=that.state.map_setting_gov_data.map((item,index)=>{
            if(that.state.map_setting_gov_index==index||that.state.map_setting_gov_select_index==index){
                
                return <div className='jkw-quick_setting-row-active-view' onMouseEnter={that.change_map_setting_index.bind(that,index,1,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,1,0)} onClick={that.change_map_setting_select.bind(that,1,index)} >
                <div className='jkw-quick_setting-row-active-wrap-view'>{item.keyboardName}</div>
            </div>
            }
            return <div className='jkw-quick_setting-row-view jkw-font-2' onMouseEnter={that.change_map_setting_index.bind(that,index,1,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,1,0)} onClick={that.change_map_setting_select.bind(that,1,index)} >{item.keyboardName}</div>
        })

        // 获取一键设置模式
        let map_setting_custom_view=that.state.map_setting_custom_data.map((item,index)=>{
            if(that.state.map_setting_custom_index==index||that.state.map_setting_custom_select_index==index){
                return <div className='jkw-quick_setting-row-active-view' onMouseEnter={that.change_map_setting_index.bind(that,index,2,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,2,0)}  onClick={that.change_map_setting_select.bind(that,2,index)}>
                <div className='jkw-quick_setting-row-active-wrap-view'>{item.name}</div>
                <img src="/assets/map-setting-del-icon.png" className='jkw-map-del-icon-view'  onClick={that.map_setting_custom_del.bind(that,index)}/>
            </div>
            }
            
            return <div className='jkw-quick_setting-row-view jkw-font-2' onMouseEnter={that.change_map_setting_index.bind(that,index,2,1)} onMouseLeave={that.change_map_setting_index.bind(that,index,2,0)}  onClick={that.change_map_setting_select.bind(that,2,index)}>{item.name}</div>

        })
        let map_setting_center_view=null;
        //  一键设置  缺省处理
        if((that.state.map_setting_gov_data.length==0&&that.state.setting_mode==1)||(that.state.map_setting_custom_data.length==0&&that.state.setting_mode==2)){
            map_setting_center_view=<div className='jkw-quick_setting-center-view flex-center'>
                <img src="/assets/display_none.png" className='jkw-display-none-icon-view' />
            </div>
        }else if(that.state.setting_mode==2){
            map_setting_center_view=<div className='jkw-quick_setting-center-view'>
            {map_setting_custom_view}
            </div>
        }else{
            map_setting_center_view=<div className='jkw-quick_setting-center-view'>
            {map_setting_gov_view}
            </div>
        }



        let map_setting_btn_view=null;
        if(that.state.setting_mode==1){
            if(that.state.map_setting_gov_select_index==-1&&that.state.map_setting_gov_index==-1){
                map_setting_btn_view=<div className="jkw-quick_setting-down-view opacity-5"   >
                <div className='jkw-quick_setting-down-mode2-view' >
                    应用模式
                </div>
            </div>
            }else{
                map_setting_btn_view=<div className="jkw-quick_setting-down-view click-style"   >
                <div className='jkw-quick_setting-down-mode2-view' onClick={that.map_setting_apply.bind(that)}>
                    应用模式
                </div>
            </div>
            }
            
        }else if(that.state.setting_mode==2){
            //自定义模式
            if(that.state.map_setting_custom_index==-1&&that.state.map_setting_custom_select_index==-1){
                map_setting_btn_view=<div className='jkw-quick_setting-down-view'>
                    <div className='jkw-quick_setting-down-mode2-view click-style' onClick={that.custom_rename_modal_show.bind(that,1)}>
                        新增一键设置
                    </div>
                </div> 
                
            }else{
                map_setting_btn_view=<div className='jkw-quick_setting-down-view'>
                <div className='jkw-quick_setting-down-mode3-view' onClick={that.map_setting_custom_apply.bind(that)}>应用模式</div>
                <div className='jkw-quick_setting-down-mode3-view' onClick={that.custom_rename_modal_show.bind(that,2)}>重新命名</div>
                <div className='jkw-quick_setting-down-mode4-view' onClick={that.map_setting_custom_save.bind(that)}>保存设置</div>
            </div> 
            }
            
        }
        let wheel_active_status=that.state.wheel_active_status
        let wheel_input_refresh_visible=that.state.wheel_input_refresh_visible
        let wheel_input_key=that.state.wheel_input_key;
return (
    <div  className="jkw-wheel-view  ">
   
    {/* 设备未连接 */}
    <div className='jkw-wheel-none-view' hidden={that.state.gear_lever_state}></div>
    <div className='jkw-wheel-none-info-view' hidden={that.state.gear_lever_state}>
        <div className='jkw-wheel-none-hint-view'>
            <img src={"/assets/warn_icon.png"}   className="jkw-icon-16 margin-right-6"/>
            <text className='jkw-wheel-font-1'>排挡杆未连接</text>
        </div>
    </div>
    <div  className='jkw-left-view '>
        {/* 一键设置模式 */}
        <div className='jkw-quick_setting-view jkw-quick_setting-view-shift-lever'>
            <div className='jkw-quick_setting-top-view'>
                <div  className='jkw-quick_setting-top-left-view'>
                    <text className='jkw-font-1'>一键设置模式</text>
                    <img src="/assets/hint_icon.png" className="jkw-icon-13 jkw-point-view margin-left-6"/>
                </div>
                <div  className={that.state.setting_mode==1?'jkw-quick_setting-top-right-view':'jkw-quick_setting-top-right2-view'}>
                    <div className= {that.state.setting_mode==1?'jkw-quick_setting-top-row-view':'jkw-quick_setting-top-row-active-view'} onClick={that.change_setting_map.bind(that,2)}>自定义</div>
                    <div className={that.state.setting_mode==2?'jkw-quick_setting-top-row-view':'jkw-quick_setting-top-row-active-view'}   onClick={that.change_setting_map.bind(that,1)} >官方推荐</div>
                </div>
            </div>
            {map_setting_center_view}
            {map_setting_btn_view}
        </div>
        {/* 按键设置 */}
         <div  className='jkw-mapping-view jkw-wheel-view-shift-lever'>
            
            {/* 第一层 */}
            <div className='jkw-shift-lever-top-view'>
                <img src="/assets/shift_lever/shift_lever_device_1.png" className="shift-lever-device-icon-1"/>
                <div className='shift-lever-device-icon-2-view'>
                    <img src="/assets/shift_lever/shift_lever_device_2.png" className="shift-lever-device-icon-2"/>

                    <div className='jkw-wheel-map-list-window-view'>

                        <div className='jkw-shift-map-icon-view-1 jkw-shift-pos-8' id="jkw-shift-pos-8"></div>
                        <div className='jkw-shift-map-icon-view-1 jkw-shift-pos-9' id="jkw-shift-pos-9"></div>
                
                    </div>
                </div>      
            
                
                <div className='jkw-shift-lever-top-wrap-view'>


                    <div className={wheel_active_status==8?'jkw-mapping-row-active-view jkw-shift-mapping-row-view-2  margin-right-0 ':'jkw-mapping-row-view jkw-shift-mapping-row-view-2  margin-right-0'}  onMouseEnter={that.change_map_active_cur.bind(that,8,1)} onMouseLeave={that.change_map_active_cur.bind(that,8,0)} >
                    <div className='jkw-mapping-font-1-view'>换档</div>
                    {wheel_active_status==8?
                    <div className='jkw-mapping-row-wrap-view'>
                            {wheel_input_refresh_visible==1?
                            <div className='jkw-mapping-font-4-view'>
                                <div className='jkw-mapping-font-wrap-view'>
                                    <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                    
                                </div>
                                <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                    <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                </div>
                            </div>:
                            <div className='jkw-mapping-font-5-view'>
                                <div className='jkw-mapping-font-wrap2-view'>
                                    <text className='jkw-mapping-font-1'>输入代替键</text>
                                </div>
                            </div>
                            }
                        </div>:
                        <div className='jkw-mapping-row-wrap-view'>
                            {that.map_display_view(8)}
                        
                        </div>}
                    </div>

                    <div className={wheel_active_status==9?'jkw-mapping-row-active-view jkw-shift-mapping-row-view-2  margin-right-0':'jkw-mapping-row-view jkw-shift-mapping-row-view-2  margin-right-0'}  onMouseEnter={that.change_map_active_cur.bind(that,9,1)} onMouseLeave={that.change_map_active_cur.bind(that,9,0)} >
                    <div className='jkw-mapping-font-1-view'>手刹</div>
                    {wheel_active_status==9?
                    <div className='jkw-mapping-row-wrap-view'>
                            {wheel_input_refresh_visible==1?
                            <div className='jkw-mapping-font-4-view'>
                                <div className='jkw-mapping-font-wrap-view'>
                                    <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                    
                                </div>
                                <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                    <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                                </div>
                            </div>:
                            <div className='jkw-mapping-font-5-view'>
                                <div className='jkw-mapping-font-wrap2-view'>
                                    <text className='jkw-mapping-font-1'>输入代替键</text>
                                </div>
                            </div>
                            }
                        </div>:
                        <div className='jkw-mapping-row-wrap-view'>
                            {that.map_display_view(9)}
                        </div>}
                    </div>
                </div>
            </div>
            {/* 第二层 */}
            <div className='jkw-shift-lever-mapping-row-view'>
           
                <div className={wheel_active_status==1?'jkw-mapping-row-active-view jkw-shift-mapping-row-view-2  margin-right-29 margin-top-20':'jkw-mapping-row-view jkw-shift-mapping-row-view-2  margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,1,1)} onMouseLeave={that.change_map_active_cur.bind(that,1,0)} >
                <div className='jkw-mapping-font-1-view'>档 1</div>
                {wheel_active_status==1?
                <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(1)}
                    
                    </div>}
                </div>

                <div className={wheel_active_status==3?'jkw-mapping-row-active-view jkw-shift-mapping-row-view-2  margin-right-29 margin-top-20':'jkw-mapping-row-view jkw-shift-mapping-row-view-2  margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,3,1)} onMouseLeave={that.change_map_active_cur.bind(that,3,0)} >
                <div className='jkw-mapping-font-1-view'>档 3</div>
                {wheel_active_status==3?
                <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(3)}
                    
                    </div>}
                </div>

                <div className={wheel_active_status==5?'jkw-mapping-row-active-view jkw-shift-mapping-row-view-2  margin-right-29 margin-top-20':'jkw-mapping-row-view jkw-shift-mapping-row-view-2  margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,5,1)} onMouseLeave={that.change_map_active_cur.bind(that,5,0)} >
                <div className='jkw-mapping-font-1-view'>档 5</div>
                {wheel_active_status==5?
                <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(5)}
                    </div>}
                </div>
            </div>
            

                
            {/* 第三层 */}
            <div className='jkw-shift-lever-center-view margin-top-20'>
                <img src="/assets/shift_lever/shift_lever_device_3.png" className="shift-lever-device-icon-3"/>
                <div className='jkw-wheel-map-list-window-view'>
                    <div className='jkw-shift-map-icon-view-1 jkw-shift-pos-1' id="jkw-shift-pos-1"></div>
                    <div className='jkw-shift-map-icon-view-1 jkw-shift-pos-2' id="jkw-shift-pos-2"></div>
                    <div className='jkw-shift-map-icon-view-1 jkw-shift-pos-3' id="jkw-shift-pos-3"></div>
                    <div className='jkw-shift-map-icon-view-1 jkw-shift-pos-4' id="jkw-shift-pos-4"></div>
                    <div className='jkw-shift-map-icon-view-1 jkw-shift-pos-5' id="jkw-shift-pos-5"></div>
                    <div className='jkw-shift-map-icon-view-1 jkw-shift-pos-6' id="jkw-shift-pos-6"></div>
                    <div className='jkw-shift-map-icon-view-1 jkw-shift-pos-7' id="jkw-shift-pos-7"></div>
                </div>
                    
            </div>
            
            {/* 第四层 */}
            <div className='jkw-shift-lever-mapping-row-view'>

                <div className={wheel_active_status==2?'jkw-mapping-row-active-view jkw-shift-mapping-row-view  margin-right-29 margin-top-20':'jkw-mapping-row-view jkw-shift-mapping-row-view  margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,2,1)} onMouseLeave={that.change_map_active_cur.bind(that,2,0)} >
                <div className='jkw-mapping-font-1-view'>档 2</div>
                {wheel_active_status==2?
                <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(2)}
                    
                    </div>}
                </div>

                <div className={wheel_active_status==4?'jkw-mapping-row-active-view jkw-shift-mapping-row-view  margin-right-29 margin-top-20':'jkw-mapping-row-view jkw-shift-mapping-row-view  margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,4,1)} onMouseLeave={that.change_map_active_cur.bind(that,4,0)} >
                <div className='jkw-mapping-font-1-view'>档 4</div>
                {wheel_active_status==4?
                <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(4)}
                    
                    </div>}
                </div>

                <div className={wheel_active_status==6?'jkw-mapping-row-active-view jkw-shift-mapping-row-view  margin-right-29 margin-top-20':'jkw-mapping-row-view jkw-shift-mapping-row-view  margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,6,1)} onMouseLeave={that.change_map_active_cur.bind(that,6,0)} >
                <div className='jkw-mapping-font-1-view'>档 6</div>
                {wheel_active_status==6?
                <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(6)}
                    
                    </div>}
                </div>

                <div className={wheel_active_status==7?'jkw-mapping-row-active-view jkw-shift-mapping-row-view  margin-right-29 margin-top-20':'jkw-mapping-row-view jkw-shift-mapping-row-view  margin-right-29 margin-top-20'}  onMouseEnter={that.change_map_active_cur.bind(that,7,1)} onMouseLeave={that.change_map_active_cur.bind(that,7,0)} >
                <div className='jkw-mapping-font-1-view'>R</div>
                {wheel_active_status==7?
                <div className='jkw-mapping-row-wrap-view'>
                        {wheel_input_refresh_visible==1?
                        <div className='jkw-mapping-font-4-view'>
                            <div className='jkw-mapping-font-wrap-view'>
                                <text className='jkw-mapping-font-1'>{wheel_input_key}</text>
                                
                            </div>
                            <div className='jkw-mapping-input-icon-wrap-view' onClick={that.clear_map_input.bind(that)}>
                                <img src="/assets/refresh.png" className='jkw-mapping-input-icon'/>
                            </div>
                        </div>:
                        <div className='jkw-mapping-font-5-view'>
                            <div className='jkw-mapping-font-wrap2-view'>
                                <text className='jkw-mapping-font-1'>输入代替键</text>
                            </div>
                        </div>
                        }
                    </div>:
                    <div className='jkw-mapping-row-wrap-view'>
                        {that.map_display_view(7)}
                    </div>}
                </div>


            </div>
            <canvas id="shift-canvas-1" hidden={that.state.wheel_active_status==0} />  
         </div>

    </div>
    

  


       {/*修改自定义模式名称  */}
  <Modal
      title={that.state.custom_rename_modal_flag==1?"新增自定义模式":"模式命名"}
      visible={that.state.custom_rename_modal_visible}
      onOk={that.state.custom_rename_modal_flag==1?that.map_setting_custom_add:that.map_setting_custom_rename}
      onCancel={that.custom_rename_modal_hidden}
      centered={true}
      okText="提交"
      cancelText="取消"
    //   maskClosable={false}
      width={400}
    >
        <Input placeholder="请输入" value={this.state.custom_rename_key} onChange={this.change_custom_rename_key.bind(that)}  style={{  width: '100%' }} />
    
   </Modal>
</div>
);
  
    }
}


const mapStateToProps = state => {

    return {
        p12_lite_data:state.p12_lite_data,
    }
};
export default connect(mapStateToProps)(Shift_lever)



